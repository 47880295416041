<template>
  <div>
    <Modal
      v-model="showDialog"
      title="Link Process"
      :busy="saving"
      @confirm="saveLink"
      @cancel="showDialog = false"
    >
      <v-row>
        <v-col cols="12" md="12">
          <v-autocomplete
            v-model="selected['ProcessId']"
            :disabled="
              !(
                $can('update', issue, 'ProcessId') ||
                $can('create', 'external-issues')
              )
            "
            :items="processList"
            item-text="fullname"
            item-value="id"
            label="Process"
            outlined
            clearable
            dense
            hide-details="true"
            @change="
              selected.VariantId = null;
              selected.ProcessStepId = null;
            "
          ></v-autocomplete
        ></v-col>
        <v-col cols="12" md="12">
          <v-autocomplete
            v-model="selected['VariantId']"
            :disabled="
              !(
                $can('update', issue, 'VariantId') ||
                $can('create', 'external-issues')
              )
            "
            :items="variantList"
            item-text="fullname"
            item-value="id"
            label="Variant"
            outlined
            clearable
            dense
            hide-details="true"
            @change="selected.ProcessStepId = null"
          ></v-autocomplete></v-col
        ><v-col cols="12" md="12">
          <v-autocomplete
            v-model="selected['ProcessStepId']"
            :disabled="
              !(
                $can('update', issue, 'ProcessStepId') ||
                $can('create', 'external-issues')
              )
            "
            :items="processStepList"
            item-text="fullname"
            item-value="id"
            label="Process Step"
            outlined
            clearable
            dense
            hide-details="true"
          ></v-autocomplete
        ></v-col>
      </v-row>
    </Modal>
  </div>
</template>

<script>
import Modal from '../general/Modal.vue';
import { makeFindMixin } from 'feathers-vuex';
import { handleErrorResponse } from '@/utils/MessageHandler';
export default {
  components: {
    Modal,
  },
  mixins: [
    makeFindMixin({ service: 'process' }),
    makeFindMixin({ service: 'variant', watch: 'selected.ProcessId' }),
    makeFindMixin({ service: 'process-step', watch: 'selected.VariantId' }),
  ],
  data() {
    return {
      selected: { ProcessId: null, VariantId: null, ProcessStepId: null },
      saving: false,
    };
  },
  props: {
    value: { type: Boolean },

    issue: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    processParams() {
      return { query: { $sort: { Number: 1 } } };
    },
    variantParams() {
      return {
        query: { ProcessId: this.selected?.ProcessId, $sort: { Number: 1 } },
      };
    },
    processStepParams() {
      return {
        query: { VariantId: this.selected?.VariantId, $sort: { Number: 1 } },
      };
    },
    processList() {
      let list = [...this.process];
      list.map((item) => {
        item.fullname = `${item.Number} ${item.Name}`;
      });
      return list;
    },
    variantList() {
      let list = [...this.variant];
      list.map((item) => {
        item.fullname = `${item.Number} ${item.Name}`;
      });
      return list;
    },
    processStepList() {
      let list = [...this.processStep];
      list.map((item) => {
        item.fullname = `${item.Number} ${item.Name}`;
      });
      return list;
    },
  },
  methods: {
    async saveLink() {
      this.saving = true;
      const issuetoSave = this.issue.clone();
      issuetoSave.ProcessId = this.selected.ProcessId;
      issuetoSave.VariantId = this.selected.VariantId;
      issuetoSave.ProcessStepId = this.selected.ProcessStepId;
      try {
        await issuetoSave.save();
      } catch (error) {
        handleErrorResponse(error);
      }
      this.saving = false;
      this.showDialog = false;

      const { ExternalIssues } = this.$FeathersVuex.api;
      await ExternalIssues.find({ query: { id: this.issue.id } });
    },
  },
};
</script>

<style lang="scss" scoped></style>
