<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <FieldValueEditDialog
        :item="clone"
        @save="
          showDialog = false;
          handleSave(save, clone);
        "
        @reset="reset"
        @remove="
          remove();
          showDialog = false;
        "
        @cancel="showDialog = false"
      ></FieldValueEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';

import FieldValueEditDialog from '@/components/FieldValues/FieldValueEditDialog';
import {
  handleErrorResponse,
  handleSaveResponse,
} from '@/utils/MessageHandler';

export default {
  name: 'FieldValuesEdit',
  components: {
    FeathersVuexFormWrapper,
    FieldValueEditDialog,
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    fieldvaluelist: {
      type: Array,
      required: true,
    },
    fieldId: { type: Number },
  },
  computed: {
    item() {
      const { FieldValueList } = this.$FeathersVuex.api;
      if (this.currentItem) {
        return this.currentItem;
      } else {
        let newValue = new FieldValueList();
        newValue.FieldId = this.fieldId;
        // console.log(newValue);
        return newValue;
      }
    },

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSave(save, clone) {
      console.log(
        this.fieldvaluelist.filter((f) => f.Default == true)?.length,
        clone
      );
      if (
        this.fieldvaluelist.filter((f) => f.Default == true)?.length >= 1 &&
        clone.Default
      ) {
        handleErrorResponse({ message: 'Only one default value allowed' });
        clone.Default = false;
      }
      if (clone.Default) {
        console.log('here');
        clone.TestRelevant = true;
      }
      save()
        .then((saved) =>
          handleSaveResponse(saved.Name, 'Field Value', 'updated')
        )
        .catch((error) => handleErrorResponse(error));
    },
  },
};
</script>
