<template>
  <div>
    <ejs-richtexteditor
      v-model="richtext"
      ref="rteObj"
      :autoSaveOnIdle="false"
      saveInterval="0"
      :enableResize="true"
      :enableXhtml="true"
      :placeholder="placeholder"
      :disabled="disabled"
      :fontFamily="fontFamily"
      :emojiPickerSettings="emojiPickerSettings"
      :toolbarSettings="toolbarSettings"
      :quickToolbarSettings="quickToolbarSettings"
      :imageUploading="imageUploading"
      :imageUploadFailed="imageUploadFailed"
      :imageUploadSuccess="onImageUploadSuccess"
      :insertImageSettings="insertImageSettings"
      @change="$emit('change')"
      :fontColor="{
        modeSwitcher: true,
      }"
      :backgroundColor="{
        modeSwitcher: true,
      }"
    >
    </ejs-richtexteditor>
  </div>
</template>

<script>
import {
  RichTextEditorComponent,
  Toolbar,
  Link,
  Image,
  Count,
  HtmlEditor,
  QuickToolbar,
  EmojiPicker,
  PasteCleanup,
  Resize,
} from '@syncfusion/ej2-vue-richtexteditor';

import { handleErrorResponse } from '@/utils/MessageHandler';

export default {
  name: 'RichEditor',
  components: {
    'ejs-richtexteditor': RichTextEditorComponent,
  },
  provide: {
    richtexteditor: [
      Toolbar,
      Link,
      Image,
      Count,
      HtmlEditor,
      QuickToolbar,
      EmojiPicker,
      PasteCleanup,
      Resize,
    ],
  },
  props: {
    disabled: { type: Boolean },
    placeholder: { type: String },
    value: { type: String },
    height: { type: String, required: false, default: '500px' },
  },
  created() {
    this.richtext = this.value;
  },
  data() {
    return {
      richtext: null,
      insertImageSettings: {
        saveUrl: `${this.$appConfig.apiBaseUrl}/file-upload`,
        path: `${this.$appConfig.S3.endpoint.substring(0, 8)}${
          this.$appConfig.S3.bucket
        }.${this.$appConfig.S3.endpoint.substring(8)}`,
      },
      quickToolbarSettings: {
        image: [],
      },
      fontFamily: {
        default: 'Roboto',
        items: [
          {
            text: 'Segoe UI',
            value: 'Segoe UI',
            class: 'e-segoe-ui',
            command: 'Font',
            subCommand: 'FontName',
          },
          {
            text: 'Roboto',
            value: 'Roboto',
            command: 'Font',
            subCommand: 'FontName',
          }, // here font is added
          {
            text: 'Great vibes',
            value: 'Great Vibes,cursive',
            command: 'Font',
            subCommand: 'FontName',
          }, // here font is added
          {
            text: 'Noto Sans',
            value: 'Noto Sans',
            command: 'Font',
            subCommand: 'FontName',
          },
          {
            text: 'Impact',
            value: 'Impact,Charcoal,sans-serif',
            class: 'e-impact',
            command: 'Font',
            subCommand: 'FontName',
          },
          {
            text: 'Tahoma',
            value: 'Tahoma,Geneva,sans-serif',
            class: 'e-tahoma',
            command: 'Font',
            subCommand: 'FontName',
          },
        ],
      },
      toolbarSettings: {
        type: 'Expand',
        items: [
          'Bold',
          'Italic',
          'Underline',
          'StrikeThrough',
          'FontName',
          'FontSize',
          'FontColor',
          'BackgroundColor',
          'LowerCase',
          'UpperCase',
          '|',
          'Formats',
          'Alignments',
          'OrderedList',
          'UnorderedList',
          'Outdent',
          'Indent',
          '|',
          'CreateLink',
          'Image',
          'EmojiPicker',
          '|',
          'ClearFormat',
          'Print',
          'SourceCode',
          'FullScreen',
          '|',
          'Undo',
          'Redo',
        ],
      },
      emojiPickerSettings: {
        iconsSet: [
          {
            name: 'Smilies & People',
            code: '1F600',
            iconCss: 'e-emoji',
            icons: [
              { code: '1F600', desc: 'Grinning face' },
              { code: '1F603', desc: 'Grinning face with big eyes' },
              { code: '1F604', desc: 'Grinning face with smiling eyes' },
              { code: '1F606', desc: 'Grinning squinting face' },
              { code: '1F605', desc: 'Grinning face with sweat' },
              { code: '1F602', desc: 'Face with tears of joy' },
              { code: '1F923', desc: 'Rolling on the floor laughing' },
              { code: '1F60A', desc: 'Smiling face with smiling eyes' },
            ],
          },
          {
            name: 'Animals & Nature',
            code: '1F435',
            iconCss: 'e-animals',
            icons: [
              { code: '1F436', desc: 'Dog face' },
              { code: '1F431', desc: 'Cat face' },
              { code: '1F42D', desc: 'Mouse face' },
              { code: '1F439', desc: 'Hamster face' },
              { code: '1F430', desc: 'Rabbit face' },
              { code: '1F98A', desc: 'Fox face' },
            ],
          },
          {
            name: 'Food & Drink',
            code: '1F347',
            iconCss: 'e-food-and-drinks',
            icons: [
              { code: '1F34E', desc: 'Red apple' },
              { code: '1F34C', desc: 'Banana' },
              { code: '1F347', desc: 'Grapes' },
              { code: '1F353', desc: 'Strawberry' },
              { code: '1F35E', desc: 'Bread' },
              { code: '1F950', desc: 'Croissant' },
              { code: '1F955', desc: 'Carrot' },
              { code: '1F354', desc: 'Hamburger' },
            ],
          },
          {
            name: 'Activities',
            code: '1F383',
            iconCss: 'e-activities',
            icons: [
              { code: '26BD', desc: 'Soccer ball' },
              { code: '1F3C0', desc: 'Basketball' },
              { code: '1F3C8', desc: 'American football' },
              { code: '26BE', desc: 'Baseball' },
              { code: '1F3BE', desc: 'Tennis' },
              { code: '1F3D0', desc: 'Volleyball' },
              { code: '1F3C9', desc: 'Rugby football' },
            ],
          },
          {
            name: 'Travel & Places',
            code: '1F30D',
            iconCss: 'e-travel-and-places',
            icons: [
              { code: '2708', desc: 'Airplane' },
              { code: '1F697', desc: 'Automobile' },
              { code: '1F695', desc: 'Taxi' },
              { code: '1F6B2', desc: 'Bicycle' },
              { code: '1F68C', desc: 'Bus' },
            ],
          },
          {
            name: 'Objects',
            code: '1F507',
            iconCss: 'e-objects',
            icons: [
              { code: '1F4A1', desc: 'Light bulb' },
              { code: '1F526', desc: 'Flashlight' },
              { code: '1F4BB', desc: 'Laptop computer' },
              { code: '1F5A5', desc: 'Desktop computer' },
              { code: '1F5A8', desc: 'Printer' },
              { code: '1F4F7', desc: 'Camera' },
              { code: '1F4F8', desc: 'Camera with flash' },
              { code: '1F4FD', desc: 'Film projector' },
            ],
          },
          {
            name: 'Symbols',
            code: '1F3E7',
            iconCss: 'e-symbols',
            icons: [
              { code: '274C', desc: 'Cross mark' },
              { code: '2714', desc: 'Check mark' },
              { code: '26A0', desc: 'Warning sign' },
              { code: '1F6AB', desc: 'Prohibited' },
              { code: '2139', desc: 'Information' },
              { code: '267B', desc: 'Recycling symbol' },
              { code: '1F6AD', desc: 'No smoking' },
            ],
          },
        ],
        richtext: '',
        // customToolbar: [
        //   ['bold', 'italic', 'underline'],
        //   [{ list: 'ordered' }, { list: 'bullet' }],
        // ],
        editorSettings: {
          modules: {
            // toolbar: [
            //   ['bold', 'italic'],
            //   ['link', 'image'],
            // ],
            blotFormatter: {},

            imageDropAndPaste: {
              handler: this.imageHandler,
            },
          },
        },
      },
    };
  },
  watch: {
    richtext() {
      this.$emit('input', this.richtext);
    },
    value() {
      this.richtext = this.value;
    },
  },

  methods: {
    onImageUploadSuccess(args) {
      // Rename generic rte file to the filename received from the server.
      let response = JSON.parse(args.e.target.response);
      if (response.name != null) {
        args.file.name = response.name;
        let filename = document.querySelectorAll('.e-file-name')[0];
        filename.innerHTML = args.file.name.replace(
          document.querySelectorAll('.e-file-type')[0].innerHTML,
          ''
        );

        filename.title = args.file.name;
      }
      setTimeout(() => {
        this.$emit('busy', false);
      }, 1000);
    },
    imageUploading(args) {
      // Add Auth header
      this.$emit('busy', true);
      args.currentRequest.setRequestHeader(
        'Authorization',
        `Bearer ${localStorage.getItem('feathers-jwt')}`
      );
    },
    imageUploadFailed(args) {
      const error = new Error(args.statusText);
      handleErrorResponse(error);
      this.$emit('busy', false);
    },
  },
};
</script>

<style lang="scss">
.e-richtexteditor {
  min-width: 100%;
  max-width: 100%;
}
</style>
