<template>
  <div>
    <v-dialog :value="true" persistent max-width="50vw">
      <div v-if="item">
        <v-form ref="form" v-model="validInput">
          <v-card flat class="px-5">
            <v-card-title>
              <span class="headline">New/Edit Group</span>
            </v-card-title>

            <v-row>
              <v-col cols="12" md="10">
                <v-text-field
                  v-model="item.Name"
                  label="Group Name"
                  :rules="[rules.required]"
                  :disabled="!$can('update', item, 'Name')"
                  outlined
                  dense
                  hide-details="true"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="2" align="right"
                ><v-spacer />
                <v-checkbox
                  v-model="item.Loop"
                  v-if="item.id"
                  :disabled="!$can('update', item, 'Loop')"
                  :label="`Loop`"
                  dense
                  hide-details="true"
                ></v-checkbox></v-col></v-row
            ><v-row
              ><v-col cols="12" md="6">
                <v-select
                  v-model="item.ParentGroupId"
                  v-if="item.id"
                  :disabled="!$can('update', item, 'group')"
                  :items="filteredgroups"
                  item-text="Name"
                  item-value="id"
                  label="Parent Group"
                  outlined
                  dense
                  hide-details="true"
                >
                  <template v-slot:item="{ item }">
                    <v-icon :color="item.Color" small class="mr-2">
                      mdi-circle
                    </v-icon>
                    {{ item.Name }}
                  </template>
                  <template #selection="{ item }">
                    <v-icon :color="item.Color" small class="mr-2">
                      mdi-circle
                    </v-icon>
                    {{ item.Name }}
                  </template></v-select
                >
              </v-col></v-row
            ><v-row>
              <v-col cols="12" md="4">
                <v-color-picker
                  v-if="item.id"
                  dot-size="25"
                  swatches-max-height="200"
                  mode="hexa"
                  v-model="item.Color"
                  show-swatches
                  :swatches="swatches"
                  :rules="[rules.required]"
                ></v-color-picker>
              </v-col>
              <v-col cols="12" md="8">
                <GroupSteps
                  v-if="item.id && variant"
                  :variant="variant"
                  :group="item"
                />
                <GroupVariant
                  v-if="item.id && process"
                  :process="process"
                  :group="item"
                />
              </v-col>
            </v-row>

            <v-card-actions>
              <v-btn
                v-if="$can('delete', item) && item.id"
                outlined
                @click="showRemoveMessage = true"
                >Remove</v-btn
              >
              <div v-if="showRemoveMessage" class="ml-5">
                Are you sure?
                <v-btn class="ml-2" @click="$emit('remove')" color="error"
                  >Yes</v-btn
                >
              </div>
              <v-spacer></v-spacer>
              <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
              <v-btn
                v-if="item.id"
                :disabled="!validInput"
                color="primary"
                :loading="saving"
                @click="$emit('save')"
              >
                Save
              </v-btn>
              <v-btn
                v-if="!item.id"
                :disabled="!validInput"
                color="primary"
                :loading="saving"
                @click="initialSave(item)"
              >
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  handleErrorResponse,
  handleSaveResponse,
} from '@/utils/MessageHandler';
import GroupSteps from './GroupSteps';
import GroupVariant from './GroupVariant';
import { makeFindMixin } from 'feathers-vuex';
export default {
  name: 'GroupEditDialog',
  components: { GroupSteps, GroupVariant },
  mixins: [
    makeFindMixin({
      service: 'group',
      watch: 'item.id',
    }),
  ],
  data() {
    return {
      validInput: true,
      errorMessage: null,
      showRemoveMessage: false,
      swatches: [
        ['#FF0000', '#AA0000', '#550000'],
        ['#FFFF00', '#AAAA00', '#555500'],
        ['#00FF00', '#00AA00', '#005500'],
        ['#00FFFF', '#00AAAA', '#005555'],
        ['#0000FF', '#0000AA', '#000055'],
      ],

      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    variant: {},
    process: {},
    saving: { type: Boolean },
  },
  watch: {
    'item.ParentGroupId': {
      handler() {
        this.item.Order = this.maxOrder + 1;
      },
      immediate: true, // Trigger the watcher on initial load
    },
  },
  computed: {
    groupParams() {
      return {
        query: { VariantId: this.item.VariantId },
      };
    },
    filteredgroups() {
      return this.group.filter((f) => f.id != this.item.id);
    },
    maxOrder() {
      const groupsWithSameParentId = this.group.filter(
        (f) => f.ParentGroupId == this.item.ParentGroupId
      );
      const maxOrder = Math.max(
        ...groupsWithSameParentId.map((group) => group.Order)
      );
      return isFinite(maxOrder) ? maxOrder : 0;
    },
  },

  methods: {
    async initialSave(item) {
      if (item.Name?.length > 0) {
        try {
          await item.save();
          handleSaveResponse('hi', 'hi', 'hi');
        } catch (error) {
          handleErrorResponse(error);
        }
      }
    },
  },
  mounted() {},
};
</script>
