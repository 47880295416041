<template>
  <div class="mx-5" v-if="selectedPhase">
    <v-row class="py-3" align="center" justify="center">
      <v-col cols="12" md="8"> <h3>Progress</h3></v-col>
      <v-col cols="12" md="2">
        <v-switch
          dense
          label="To Do"
          v-model="selectedStatusCurrent"
          @change="changedStatusCurrent"
        ></v-switch
      ></v-col>
      <v-col cols="12" md="2">
        <v-select
          v-model="selectedPhase"
          :items="phasesDistinct"
          label="Select Phase"
          dense
          hide-details
          :loading="isFindPhasesPending"
          @change="changedPhase()"
        ></v-select
      ></v-col>
    </v-row>

    <ExportProgress class="mb-2" />
    <v-row>
      <v-col cols="12" md="4">
        <StatusPerType
          class="mb-2"
          :selectedPhase="selectedPhase"
          type="Process"
          :current="selectedStatusCurrent"
        />
      </v-col>
      <v-col cols="12" md="4">
        <StatusPerType
          class="mb-2"
          :selectedPhase="selectedPhase"
          type="Specification"
          :current="selectedStatusCurrent"
        />
      </v-col>
      <v-col cols="12" md="4">
        <StatusPerType
          class="mb-2"
          :selectedPhase="selectedPhase"
          type="Issue"
          :current="selectedStatusCurrent"
        />
      </v-col>
    </v-row>

    <ProgressCards :selectedPhase="selectedPhase" />
    <ProgressGraph />
  </div>
</template>

<script>
import ExportProgress from '@/components/ExportData/ExportProgress.vue';
import ProgressGraph from '@/components/Dashboard/Progress/ProgressGraph.vue';
import ProgressCards from './ProgressCards.vue';
import StatusPerType from './StatusPerType.vue';
import { makeFindMixin } from 'feathers-vuex';

export default {
  data() {
    return {
      selectedPhase: '',
      selectedStatusCurrent: false,
    };
  },
  mixins: [makeFindMixin({ service: 'process', name: 'phases' })],
  components: { ExportProgress, ProgressGraph, ProgressCards, StatusPerType },
  methods: {
    changedPhase() {
      window.localStorage.setItem('phase', this.selectedPhase);
    },
    changedStatusCurrent() {
      window.localStorage.setItem('statusCurrent', this.selectedStatusCurrent);
    },
  },
  computed: {
    phasesParams() {
      return {
        query: {
          $select: ['Phase'],
        },
      };
    },
    phasesDistinct() {
      if (this.phases && this.phases.length > 0) {
        let phases = [...this.phases];
        phases = phases.map((p) => p.Phase.toString());
        phases = [...new Set(phases)];
        phases = phases.sort();
        phases.unshift('All');

        let storedPhase = window.localStorage.getItem('phase');
        if (phases && storedPhase && !phases.includes(storedPhase)) {
          // check if an incorrect phase is stored in localstorage
          window.localStorage.removeItem('phase');
        }

        return phases;
      } else return [];
    },
  },
  mounted() {
    this.selectedPhase = window.localStorage.getItem('phase')
      ? window.localStorage.getItem('phase')
      : 'All';
    this.selectedStatusCurrent = window.localStorage.getItem('statusCurrent')
      ? window.localStorage.getItem('statusCurrent') === 'true'
      : false;
  },
};
</script>

<style lang="scss" scoped></style>
