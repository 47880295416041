<template>
  <div>
    <v-progress-linear
      indeterminate
      height="3"
      color="secondary"
      v-if="isGetRetrievedVariantPending"
    ></v-progress-linear>
    <v-card
      v-if="this.variant.id && retrievedVariant"
      :style="`
        border-radius: 4px;
        border-top-color: ${retrievedVariant?.group?.Color};
        border-top-style: solid;
        border-top-width: 3px;
      `"
    >
      <v-card-title class="overline" color="indigo darken-4">
        <span
          v-for="color in group.find((f) => f.id == retrievedVariant.GroupId)
            ?.allGroups"
          :key="color.id"
        >
          <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                class="circle pointer"
                :style="{
                  background: color.Color,
                  color: applyDark(color.Color) ? 'white' : 'black',
                }"
              >
                <v-icon
                  v-if="color.Loop"
                  :color="applyDark(color.Color) ? 'white' : 'black'"
                  x-small
                  >mdi-sync</v-icon
                ><span v-else>&nbsp;</span>
              </span></template
            >{{ color?.Name }}</v-tooltip
          > </span
        >{{ retrievedVariant.Number }}. {{ retrievedVariant.Name }}

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-if="retrievedVariant.application" v-bind="attrs" v-on="on"
              >({{ retrievedVariant.application.Name }})</span
            > </template
          ><span v-if="retrievedVariant.application">{{
            retrievedVariant.application.Implemented
          }}</span></v-tooltip
        >
        <v-icon
          v-if="
            $feature('drawIOChartIntegration') &&
            retrievedVariant.nrOfDrawings == 0 &&
            $can('create', 'variant')
          "
          small
          class="ml-2"
          color="red"
        >
          mdi-link
        </v-icon>

        <v-icon
          v-if="checkEditVariant(retrievedVariant)"
          small
          class="ml-2 mr-2"
          @click="clickEdit(retrievedVariant)"
          color="primary"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="checkEditVariant(retrievedVariant)"
          small
          class="mr-2"
          @click="updateStatus(retrievedVariant)"
          color="primary"
        >
          mdi-update
        </v-icon>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="
                retrievedVariant &&
                retrievedVariant.nbrOfResponses > 0 &&
                $can('read', 'response')
              "
              small
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="clickResponses(retrievedVariant)"
              color="primary"
            >
              mdi-format-list-checks
            </v-icon>
          </template>
          <span>Responses</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="clickCompanies(retrievedVariant)"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              mdi-factory
            </v-icon>
          </template>
          <span>Companies</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="clickRoles(retrievedVariant)"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              mdi-account-check
            </v-icon>
          </template>
          <span>Roles</span>
        </v-tooltip>
        <v-tooltip bottom v-if="$feature('budget')">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="clickBudget(retrievedVariant)"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              mdi-currency-eur
            </v-icon>
          </template>
          <span>Budget</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-list dense>
          <VariantItemSteps
            :variant="retrievedVariant"
            :statusList="retrievedVariant.StatusSummarySteps"
            :issueStatus="retrievedVariant.StatusSummaryIssues"
            :bugStatusList="retrievedVariant.bugStatus"
            @clicked-steps="$emit('show', 'steps')"
          />

          <v-divider></v-divider>
          <VariantItemSpecs
            v-if="$feature('specification')"
            :variant="retrievedVariant"
            :statusList="retrievedVariant.StatusSummarySpecs"
            @clicked-specs="$emit('show', 'specs', $event)"
          />
          <VariantItemReqs
            v-else
            :variant="retrievedVariant"
            @clicked-reqs="$emit('show', 'reqs', $event)"
          />

          <div v-if="$feature('testScenarios')">
            <v-divider></v-divider>
            <v-list-item @click="$emit('show', 'test', variant.id)">
              <v-list-item-icon>
                <v-icon class="mr-2"> mdi-test-tube </v-icon>
              </v-list-item-icon>

              <span>Test Scenarios</span> <v-spacer></v-spacer>
              <v-chip class="ma-2" x-small outlined>
                {{ testScenarioLine ? testScenarioLine.length : 0 }}
              </v-chip>
            </v-list-item>
          </div>
          <div v-if="$feature('erpApplication')">
            <v-divider></v-divider>
            <v-card-subtitle class="pb-0"
              >Application Forms
              <span v-if="retrievedVariant.erp_applications"
                >({{ retrievedVariant.erp_applications.length }})</span
              ><v-btn icon @click="show = !show">
                <v-icon>{{
                  show ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon>
              </v-btn>
            </v-card-subtitle>
            <v-expand-transition v-if="true">
              <div v-show="show && retrievedVariant.erp_applications">
                <v-card-text class="pa-0">
                  <v-list dense class="pt-0"
                    ><v-list-item
                      v-for="app in retrievedVariant.erp_applications"
                      :key="app.id"
                      ><v-list-item-content
                        ><v-tooltip top open-delay="1000">
                          <template v-slot:activator="{ on, attrs }"
                            ><v-list-item-title v-bind="attrs" v-on="on">
                              <v-icon
                                v-if="$can('delete', 'erpapplication-variant')"
                                color="primary"
                                small
                                class="mr-2"
                                @click="removeApp(retrievedVariant, app)"
                              >
                                mdi-link-off
                              </v-icon>
                              <a
                                v-if="app.Link"
                                :href="app.Link"
                                target="_blank"
                              >
                                <span v-if="app.Customized">*</span>
                                {{ app.Name }}
                              </a>
                              <span v-else>{{ app.Name }}</span>
                            </v-list-item-title></template
                          >{{ app.Name }}</v-tooltip
                        ><v-tooltip top open-delay="1000">
                          <template v-slot:activator="{ on, attrs }"
                            ><v-list-item-subtitle v-bind="attrs" v-on="on"
                              >{{ app.Filter }}
                            </v-list-item-subtitle></template
                          >{{ app.Filter }}</v-tooltip
                        ></v-list-item-content
                      ></v-list-item
                    >
                  </v-list>

                  <v-btn
                    v-if="$can('create', 'erpapplication-variant')"
                    class="mx-2"
                    fab
                    elevation="0"
                    x-small
                    color="primary"
                    @click="clickAddApp()"
                  >
                    <v-icon dark> mdi-playlist-plus </v-icon>
                  </v-btn>
                </v-card-text>
              </div>
            </v-expand-transition>
            <v-icon x-small @click="StartCopySubProcess(retrievedVariant)"
              >mdi-content-copy</v-icon
            >
          </div>
          <!-- 
          <v-divider></v-divider>

          <v-list-item @click="$emit('show', 'training')">
            <v-list-item-icon>
              <v-icon class="mr-2">
                mdi-school
              </v-icon>
            </v-list-item-icon>
            <span>Training</span><v-spacer></v-spacer>
            <v-chip class="ma-2" x-small outlined>
              <FeathersVuexCount
                v-slot="{ total }"
                service="training"
                :params="{ query: { VariantId: item.id } }"
              >
                <span v-if="total"> {{ total }} </span>
                <span v-else>0</span>
              </FeathersVuexCount>
            </v-chip>
          </v-list-item> -->
        </v-list>
      </v-card-text>
      <VariantEdit
        v-if="detailDialog"
        v-model="detailDialog"
        :currentItem="currentItem"
        :process="currentItem.processId"
      />
    </v-card>
    <Response v-if="showResponse" v-model="showResponse" :item="currentItem" />
    <Companies
      v-if="showCompanies"
      v-model="showCompanies"
      :item="currentItem"
    />
    <Roles v-if="showRoles" v-model="showRoles" :item="currentItem" />
    <ShowBudget
      v-if="showBudget"
      v-model="showBudget"
      :variantId="retrievedVariant.id"
    />
    <VariantAddApp
      v-if="retrievedVariant"
      v-model="addAppDialog"
      :variantId="retrievedVariant.id"
    />
    <UpdateStatus
      v-if="currentItem && updateDialog"
      v-model="updateDialog"
      :id="currentItem.id"
      :type="'variant'"
    />
    <v-dialog v-model="copyDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">Copy Sub Process</v-card-title>
        <v-card-text
          >Sub process ' {{ copyItem.Name }} ' wil be copied.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="copyDialog = false"> Cancel </v-btn>
          <v-btn color="primary" :loading="loading" @click="executeCopy()">
            Copy
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CopySubProcess } from '@/utils/CopySubProcess';
import VariantItemSteps from '@/components/Variant/VariantItemSteps';
import VariantItemReqs from '@/components/Variant/VariantItemReqs';
import VariantItemSpecs from '@/components/Variant/VariantItemSpecs';
import VariantEdit from '@/components/Variant/VariantEdit';
import VariantAddApp from '@/components/Variant/VariantAddApp';
import UpdateStatus from '@/components/Variant/UpdateStatus';
import Response from '@/components/Variant/VariantResponses';
import Companies from '@/components/Variant/VariantCompanies';
import Roles from '@/components/Variant/VariantRoles';
import ShowBudget from '@/components/Budget/ShowBudget';
import { makeFindMixin, makeGetMixin } from 'feathers-vuex';
import { subject } from '@casl/ability';
import { applyDark } from '@/utils/Utilities';
export default {
  name: 'VariantCard',
  props: ['variant', 'process'],
  components: {
    VariantItemSteps,
    VariantItemReqs,
    VariantItemSpecs,
    VariantEdit,
    VariantAddApp,
    Response,
    Companies,
    Roles,
    ShowBudget,
    UpdateStatus,
  },
  mixins: [
    makeGetMixin({
      service: 'variant',
      id: 'id',
      name: 'retrievedVariant',
    }),
    makeFindMixin({ service: 'test-scenario-line' }),
    makeFindMixin({ service: 'group', watch: 'variant.ProcessId' }),
  ],
  data() {
    return {
      detailDialog: false,
      updateDialog: false,
      showResponse: false,
      showCompanies: false,
      showRoles: false,
      showBudget: false,
      currentItem: null,
      validInput: true,
      addAppDialog: false,
      show: false,
      copyDialog: false,
      copyItem: { Name: null },
      loading: false,
    };
  },

  methods: {
    applyDark,
    StartCopySubProcess(item) {
      this.copyDialog = true;
      this.copyItem = item;

      console.log(item);
    },
    async executeCopy() {
      this.loading = true;
      await CopySubProcess(this.copyItem.id, {
        includeSpecifications: true,
        includeFields: true,
      });
      this.loading = false;
      this.copyDialog = false;
      const { Variant, Process } = this.$FeathersVuex.api;
      Variant.find({ query: { ProcessId: this.process.id } });
      Process.find({ query: { id: this.process.id } });
    },
    checkEditVariant(item) {
      return this.$can('update', subject('variant', item));
    },
    clickEdit(item) {
      this.currentItem = item;
      this.detailDialog = true;
    },
    clickResponses(item) {
      this.currentItem = item;
      this.showResponse = true;
    },
    clickBudget(item) {
      this.currentItem = item;
      this.showBudget = true;
    },
    clickCompanies(item) {
      this.currentItem = item;
      this.showCompanies = true;
    },
    clickRoles(item) {
      this.currentItem = item;
      this.showRoles = true;
    },
    updateStatus(item) {
      this.currentItem = item;
      this.updateDialog = true;
    },
    clickAddApp() {
      this.addAppDialog = true;
    },

    async removeApp(variant, app) {
      // console.log(variant.id, app.id);
      const { erpapplication_variant, Variant } = this.$FeathersVuex.api;
      let result = await erpapplication_variant.find({
        query: {
          VariantId: variant.id,
          ErpApplicationId: app.id,
        },
      });
      if (result.data) {
        await this.$store.dispatch(
          'erpapplication-variant/remove',
          result.data[0].id
        );
        Variant.get(variant.id);
      }
    },
  },

  computed: {
    id() {
      return this.variant.id;
    },
    testScenarioLineParams() {
      return { query: { VariantId: this.variant.id } };
    },
    groupParams() {
      return { query: { ProcessId: this.variant.ProcessId } };
    },
  },
};
</script>

<style lang="scss" scoped></style>
