<template>
  <div>
    <div v-if="loading" class="d-flex justify-center align-center">
      <v-progress-circular
        indeterminate
        :size="15"
        :width="1"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-text-field
        v-model="search"
        label="Search Process"
        outline
        flat
        hide-details
        clearable
        @input="handleSearch"
      ></v-text-field>
      <v-switch
        v-model="showByE2E"
        label="by End 2 End"
        @change="updateTree"
      ></v-switch>
      <v-treeview
        ref="tree"
        v-if="TreeData"
        activatable
        :items="TreeData"
        :search="search"
        :open.sync="nodes.openNodes"
        :active.sync="nodes.activeNode"
        dense
        hoverable
        @update:open="saveNodes"
        @update:active="saveNodes"
        :load-children="loadChildren"
      >
        <template v-slot:label="{ item }">
          <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ item.Name }}</span>
            </template>
            <span v-if="item.type == 'TestScenario'">
              <span v-if="item.Fields?.length > 0">
                <ul style="padding-left: 5px">
                  <li v-for="field in item.Fields" :key="field.id">
                    <em>{{ field.Field }}</em
                    >: <b>{{ field.FieldValue }}</b>
                  </li>
                </ul>
              </span>
              <span v-else>No Fields</span>
            </span>
            <span v-else> {{ item.Name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:prepend="{ item }">
          <v-icon
            v-if="!item.ProcessId && !item.VariantId && !item.TestScenarioId"
            small
            >mdi-domain</v-icon
          >
          <v-icon
            v-if="item.ProcessId && !item.VariantId && !item.TestScenarioId"
            small
            >mdi-sitemap</v-icon
          >
          <v-icon
            v-if="item.ProcessId && item.VariantId && !item.TestScenarioId"
            small
            >mdi-format-list-text</v-icon
          >
          <v-icon
            v-if="item.ProcessId && item.VariantId && item.TestScenarioId"
            small
            >mdi-test-tube</v-icon
          >
        </template>

        <template v-slot:append="{ item }">
          <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="item.group"
                v-bind="attrs"
                v-on="on"
                class="circle pointer"
                :color="item.group.Color"
                x-small
                >mdi-circle
              </v-icon></template
            >{{ item?.group?.Name }}</v-tooltip
          >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                v-if="
                  item.ProcessId &&
                  item.VariantId &&
                  item.TestScenarioId &&
                  allowScenarioAdd
                "
                @click="$emit('clickedTestScenario', item)"
                >mdi-content-copy</v-icon
              >
            </template>
            <span
              v-if="item.Description?.length > 0"
              v-html="item.Description"
            ></span>
          </v-tooltip>
          <v-icon
            v-if="
              item.ProcessId &&
              item.VariantId &&
              !item.TestScenarioId &&
              allowScenarioAdd
            "
            @click="$emit('clickedNewTestScenario', item)"
            >mdi-plus</v-icon
          ></template
        >
      </v-treeview>
    </div>
  </div>
</template>

<script>
import { applyDark } from '@/utils/Utilities';
import feathersClient from '@/feathers-client';

// import DrawIO from '@/components/Drawing/drawio.vue';

export default {
  props: {
    allowScenarioAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      search: null,
      TreeData: null,
      allOpened: false,
      lastOpen: null,
      open: null,
      showByE2E: false,
      nodes: { openNodes: [], activeNode: [] },
    };
  },
  components: {
    // DrawIO,
  },

  methods: {
    applyDark,
    async updateTree() {
      window.localStorage.setItem(
        'treeViewTestScenarioE2EView',
        this.showByE2E
      );
      if (this.showByE2E) {
        await this.getTreeDataE2E();
      } else {
        await this.getTreeData();
      }
    },
    saveNodes() {
      window.localStorage.setItem(
        'treeViewTestScenarioState',
        JSON.stringify(this.nodes)
      );
    },
    scrollToActiveNode(activeNode) {
      if (activeNode && activeNode.length > 0) {
        const nodeId = activeNode[0];
        const nodeElement = this.$refs.tree.$el.querySelector(
          `[data-id="${nodeId}"]`
        );
        if (nodeElement) {
          nodeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },
    handleSearch: function (val) {
      if (val) {
        if (!this.allOpened) {
          this.lastOpen = this.open;
          this.allOpened = true;
          this.$refs.tree.updateAll(true);
        }
      } else {
        this.$refs.tree.updateAll(false);
        this.allOpened = false;
        this.open = this.lastOpen;
      }
    },
    async loadChildren(item) {
      let lineFields = await feathersClient
        .service('export-queries')
        .find({ query: { type: 'lineFields', id: item.VariantId } });
      if (item.type == 'Department') {
        let process = await feathersClient
          .service('process')
          .find({ query: { DepartmentId: item.id, $sort: { Number: 1 } } });

        item.children = process.data?.map((m) => {
          return {
            id: `${item.id}.${m.id}`,
            ProcessId: m.id,
            name: m.Name,
            Name: m.Name,
            Number: m.Number,
            nrOfDrawings: m.nrOfDrawings,
            Order: m.Number,
            type: 'Process',
            children: [],
          };
        });
      }
      if (item.type == 'Process') {
        let variant = await feathersClient
          .service('variant')
          .find({ query: { ProcessId: item.ProcessId, $sort: { Number: 1 } } });
        item.children = variant.data?.map((m) => {
          return {
            id: `${item.id}.${m.id}`,
            ProcessId: item.ProcessId,
            VariantId: m.id,
            name: `${m.Number}. ${m.Name}`,
            Name: m.Name,
            Number: m.Number,
            Order: m.Number,
            nrOfDrawings: m.nrOfDrawings,
            type: 'Variant',
            children: [],
          };
        });
      }
      if (item.type == 'Variant') {
        let testScenario = await feathersClient
          .service('test-scenario-line')
          .find({ query: { VariantId: item.VariantId, $sort: { Order: 1 } } });
        item.children = testScenario.data
          ?.map((m) => {
            return {
              id: `${item.id}.${m.id}`,
              ProcessId: item.ProcessId,
              VariantId: item.VariantId,
              TestScenarioId: m.id,
              name: `${m.id}. ${m.Name}`,
              Name: m.Name,
              Description: m.Description,
              Fields: lineFields?.filter((f) => f.TestLineId == m.id),

              Number: m.id,
              Order: m.id,
              type: 'TestScenario',
            };
          })
          .sort((a, b) => (a.Order > b.Order ? 1 : -1));
      }
    },
    async getTreeDataE2E() {
      this.loading = true;
      let end2end = await feathersClient
        .service('end-2-end')
        .find({ query: { $sort: { Number: 1 } } });
      let process = await feathersClient.service('process').find({});
      let variant = await feathersClient.service('variant').find({});

      if (end2end.total > 0) {
        this.TreeData = end2end.data.map((i) => {
          return {
            id: i.id.toString(),
            name: `${i.Number}. ${i.Name}`,
            Name: i.Name,
            Number: i.Number,
            Order: i.Name,
            nrOfDrawings: 0,
            type: 'Department',
            // children: [],
            children: i.processes
              ?.map((i2) => {
                let p = process.data.find((f) => f.id == i2.id);
                return {
                  id: `${i.id}.${p.id}`,
                  ProcessId: p.id,
                  name: `${p.Number}. ${p.Name}`,
                  Name: p.Name,
                  Number: p.Number,
                  nrOfDrawings: p.nrOfDrawings,
                  Order: i2.end2End_process.Order,
                  type: 'Process',
                  children: variant.data
                    .filter((f) => f.ProcessId == i2.id)
                    ?.map((i3) => {
                      return {
                        id: `${i.id}.${i2.id}.${i3.id}`,
                        ProcessId: i2.id,
                        VariantId: i3.id,
                        name: `${i3.Number}. ${i3.Name}`,
                        Name: i3.Name,
                        Number: i3.Number,
                        Order: i3.Number,
                        type: 'Variant',
                        group: i3.group,
                        nrOfDrawings: i3.nrOfDrawings,
                        children: i3.nrOfTestScenarios == 0 ? null : [],
                        // children: testScenarios.data
                        //   .filter((f) => f.VariantId == i3.id)
                        //   .map((i4) => {
                        //     return {
                        //       id: `${i.id}.${i2.id}.${i3.id}.${i4.id}`,
                        //       ProcessId: i2.id,
                        //       VariantId: i3.id,
                        //       TestScenarioId: i4.id,
                        //       name: i4.Name,
                        //       Number: i4.Number,
                        //       Order: i4.Number,
                        //       // Tooltip: `<b>${
                        //       //   i4.Description
                        //       //     ? i4.Description.replace(
                        //       //         /\r?\n|\r/g,
                        //       //         ' <br>'
                        //       //       )
                        //       //     : ''
                        //       // }</b><br><ul>${steps}</ul>`,
                        //     };
                        //   })
                      };
                    })
                    .sort((a, b) => (a.Order > b.Order ? 1 : -1)),
                };
              })
              .sort((a, b) => (a.Order > b.Order ? 1 : -1)),
          };
        });
      } else return [];
      this.loading = false;
    },
    async getTreeData() {
      this.loading = true;
      let department = await feathersClient.service('department').find();
      let process = await feathersClient.service('process').find({});
      let variant = await feathersClient.service('variant').find({});
      if (department.total > 0) {
        this.TreeData = department.data
          .map((i) => {
            return {
              id: i.id.toString(),
              name: i.Name,
              Name: i.Name,
              Number: i.Number,
              Order: i.Name,
              nrOfDrawings: 0,
              type: 'Department',
              // children: [],
              children: process.data
                .filter((f) => f.DepartmentId == i.id)
                ?.map((i2) => {
                  let p = process.data.find((f) => f.id == i2.id);
                  return {
                    id: `${i.id}.${p.id}`,
                    ProcessId: p.id,
                    name: `${p.Number}. ${p.Name}`,
                    Name: p.Name,
                    Number: p.Number,
                    nrOfDrawings: p.nrOfDrawings,
                    Order: p.Number,
                    type: 'Process',
                    children: variant.data
                      .filter((f) => f.ProcessId == i2.id)
                      ?.map((i3) => {
                        return {
                          id: `${i.id}.${i2.id}.${i3.id}`,
                          ProcessId: i2.id,
                          VariantId: i3.id,
                          name: `${i3.Number}. ${i3.Name}`,
                          Name: i3.Name,
                          Number: i3.Number,
                          Order: i3.Number,
                          group: i3.group,
                          type: 'Variant',
                          nrOfDrawings: i3.nrOfDrawings,
                          children: i3.nrOfTestScenarios == 0 ? null : [],
                          // children: testScenarios.data
                          //   .filter((f) => f.VariantId == i3.id)
                          //   .map((i4) => {
                          //     return {
                          //       id: `${i.id}.${i2.id}.${i3.id}.${i4.id}`,
                          //       ProcessId: i2.id,
                          //       VariantId: i3.id,
                          //       TestScenarioId: i4.id,
                          //       name: i4.Name,
                          //       Number: i4.Number,
                          //       Order: i4.Number,
                          //       // Tooltip: `<b>${
                          //       //   i4.Description
                          //       //     ? i4.Description.replace(
                          //       //         /\r?\n|\r/g,
                          //       //         ' <br>'
                          //       //       )
                          //       //     : ''
                          //       // }</b><br><ul>${steps}</ul>`,
                          //     };
                          //   })
                        };
                      })
                      .sort((a, b) => (a.Order > b.Order ? 1 : -1)),
                  };
                })
                .sort((a, b) => (a.Order > b.Order ? 1 : -1)),
            };
          })
          .sort((a, b) => (a.Order > b.Order ? 1 : -1));
      } else return [];
      this.loading = false;
    },
  },

  async mounted() {
    this.nodes = JSON.parse(
      localStorage.getItem('treeViewTestScenarioState')
    ) || {
      openNodes: [],
      activeNode: [],
    };

    this.showByE2E =
      localStorage.getItem('treeViewTestScenarioE2EView') === 'true';

    if (this.showByE2E) {
      await this.getTreeDataE2E();
    } else {
      await this.getTreeData();
    }

    this.$nextTick(() => {
      this.scrollToActiveNode(this.nodes.activeNode);
    });
  },
};
</script>

<style lang="scss" scoped></style>
