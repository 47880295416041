<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card
        v-if="statusOverviewLatestQuery?.response && progressData"
        class="text-center"
        ><v-card-title class="text-center"
          >Global Progress {{ selectedPhase }}</v-card-title
        ><v-card-text align="justify-center">
          <v-progress-circular
            :rotate="-90"
            :size="100"
            :width="12"
            :value="statusOverviewLatestQuery.response[0].Progress * 100"
            color="black"
            ><h2>
              {{
                statusOverviewLatestQuery.response[0].Progress == 1
                  ? (
                      statusOverviewLatestQuery.response[0].Progress * 100
                    ).toFixed(0)
                  : (
                      statusOverviewLatestQuery.response[0].Progress * 100
                    ).toFixed(2)
              }}%
            </h2>
          </v-progress-circular>
          <v-icon
            v-if="getTrend(progressData) > 2"
            size="80"
            class="ml-5"
            color="green"
            >mdi-trending-up</v-icon
          >
          <v-icon
            v-else-if="getTrend(progressData) < -2"
            size="80"
            class="ml-5"
            color="red"
            >mdi-trending-down</v-icon
          >
          <v-icon v-else size="80" class="ml-5" color="primary"
            >mdi-trending-neutral</v-icon
          ></v-card-text
        ></v-card
      >
    </v-col>

    <v-col cols="12" md="6">
      <v-card v-if="end2endStatusLatestQuery" class="text-center"
        ><v-card-title class="text-center">End 2 End Progress</v-card-title
        ><v-card-text align="justify-center"
          ><v-row
            ><v-col cols="12" md="7"></v-col>
            <v-col cols="12" md="1">-7d</v-col>
            <v-col cols="12" md="1">-1d</v-col>
            <v-col cols="12" md="1">0d</v-col>
            <v-col cols="12" md="2">Week trend</v-col>
          </v-row>
          <v-row v-for="d in end2End" :key="d.id">
            <v-col align="left" cols="12" md="7">
              <span class="text-left text-body-2">{{ d.Name }}</span></v-col
            >
            <v-col cols="12" md="1">
              <v-progress-circular
                class="mr-5"
                :rotate="-90"
                :size="40"
                :width="2"
                :value="end2EndProcDays(d.id, 7)"
                color="black"
                ><span class="text-caption">
                  {{ Math.round(end2EndProcDays(d.id, 7), 2) }}%
                </span>
              </v-progress-circular></v-col
            ><v-col cols="12" md="1">
              <v-progress-circular
                class="mr-5"
                :rotate="-90"
                :size="40"
                :width="2"
                :value="end2EndProcDays(d.id, 1)"
                color="black"
                ><span class="text-caption">
                  {{ Math.round(end2EndProcDays(d.id, 1), 2) }}%
                </span>
              </v-progress-circular></v-col
            ><v-col cols="12" md="1">
              <v-progress-circular
                :rotate="-90"
                :size="40"
                :width="2"
                :value="end2endProc(d.id)"
                color="black"
                ><span class="text-caption">
                  {{ Math.round(end2endProc(d.id), 2) }}%
                </span>
              </v-progress-circular></v-col
            ><v-col cols="12" md="2"
              ><v-icon
                v-if="getTrendEnd2End(d.id) > 2"
                size="23"
                class="ml-5"
                color="green"
                >mdi-trending-up</v-icon
              >
              <v-icon
                v-else-if="getTrendEnd2End(d.id) < -2"
                size="23"
                class="ml-5"
                color="red"
                >mdi-trending-down</v-icon
              >
              <v-icon v-else size="23" class="ml-5" color="primary"
                >mdi-trending-neutral</v-icon
              ></v-col
            ></v-row
          ></v-card-text
        ></v-card
      >
    </v-col>
    <v-col cols="12" md="6">
      <v-card v-if="departmentStatusLatestQuery" class="text-center"
        ><v-card-title class="text-center">Department Progress</v-card-title
        ><v-card-text align="justify-center"
          ><v-row
            ><v-col cols="12" md="7"></v-col>
            <v-col cols="12" md="1">-7d</v-col>
            <v-col cols="12" md="1">-1d</v-col>
            <v-col cols="12" md="1">0d</v-col>
            <v-col cols="12" md="2">Week trend</v-col>
          </v-row>
          <v-row
            v-for="d in departmentLatestQuery.response.data.filter(
              (d) => d.processes?.length > 0
            )"
            :key="d.id"
          >
            <v-col align="left" cols="12" md="7">
              <span class="text-left text-body-2">{{ d.Name }}</span></v-col
            >
            <v-col cols="12" md="1">
              <v-progress-circular
                class="mr-5"
                :rotate="-90"
                :size="40"
                :width="2"
                :value="departmentProcDays(d.id, 7)"
                color="black"
                ><span class="text-caption">
                  {{ Math.round(departmentProcDays(d.id, 7), 2) }}%
                </span>
              </v-progress-circular></v-col
            ><v-col cols="12" md="1">
              <v-progress-circular
                class="mr-5"
                :rotate="-90"
                :size="40"
                :width="2"
                :value="departmentProcDays(d.id, 1)"
                color="black"
                ><span class="text-caption">
                  {{ Math.round(departmentProcDays(d.id, 1), 2) }}%
                </span>
              </v-progress-circular></v-col
            ><v-col cols="12" md="1">
              <v-progress-circular
                :rotate="-90"
                :size="40"
                :width="2"
                :value="departmentProc(d.id)"
                color="black"
                ><span class="text-caption">
                  {{ Math.round(departmentProc(d.id), 2) }}%
                </span>
              </v-progress-circular></v-col
            ><v-col cols="12" md="2"
              ><v-icon
                v-if="getTrendDepartment(d.id) > 2"
                size="23"
                class="ml-5"
                color="green"
                >mdi-trending-up</v-icon
              >
              <v-icon
                v-else-if="getTrendDepartment(d.id) < -2"
                size="23"
                class="ml-5"
                color="red"
                >mdi-trending-down</v-icon
              >
              <v-icon v-else size="23" class="ml-5" color="primary"
                >mdi-trending-neutral</v-icon
              ></v-col
            ></v-row
          ></v-card-text
        ></v-card
      >
    </v-col>
  </v-row>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist';
import { getTrend } from '@/utils/Utilities';

export default {
  mixins: [
    makeFindMixin({ service: 'status-overview', watch: 'selectedPhase' }),
    makeFindMixin({ service: 'end-2-end' }),
    makeFindMixin({ service: 'department' }),
    makeFindMixin({
      service: 'status-overview',
      name: 'end2endStatus',
      watch: 'selectedPhase',
    }),
    makeFindMixin({
      service: 'status-overview',
      name: 'departmentStatus',
      watch: 'selectedPhase',
    }),
    makeFindMixin({
      service: 'export-queries',
      name: 'progressHistory',
      watch: 'selectedPhase',
    }),
    makeFindMixin({
      service: 'export-queries',
      name: 'progressHistoryEnd2End',
      watch: 'selectedPhase',
    }),
    makeFindMixin({
      service: 'export-queries',
      name: 'progressHistoryDepartment',
      watch: 'selectedPhase',
    }),
  ],
  props: {
    selectedPhase: {
      type: String,
      Required: true,
    },
  },

  methods: {
    getTrend,
    end2endProc(id) {
      if (this.end2endStatusLatestQuery) {
        let data = this.end2endStatusLatestQuery?.response?.find(
          (item) => item.End2EndId == id
        );

        if (data) {
          return data.Progress * 100;
        } else return 0;
      } else return 0;
    },
    departmentProc(id) {
      if (this.departmentStatusLatestQuery) {
        let data = this.departmentStatusLatestQuery?.response?.find(
          (item) => item.DepartmentId == id
        );
        if (data) {
          return data.Progress * 100;
        } else;
        return 0;
      } else return 0;
    },
    departmentProcDays(id, days) {
      if (this.progressHistoryDepartmentLatestQuery) {
        let result = this.progressHistoryDepartmentLatestQuery?.response?.find(
          (item) => {
            const targetDate = new Date();
            targetDate.setDate(targetDate.getDate() - days);

            const itemDate = new Date(item.refreshdate);

            return (
              itemDate.toDateString() === targetDate.toDateString() &&
              item.DepartmentId === id
            );
          }
        );
        return result?.Progress ? result.Progress : 0;
      } else return 0;
    },
    end2EndProcDays(id, days) {
      if (this.progressHistoryEnd2EndLatestQuery) {
        let result = this.progressHistoryEnd2EndLatestQuery?.response?.find(
          (item) => {
            const targetDate = new Date();
            targetDate.setDate(targetDate.getDate() - days);

            const itemDate = new Date(item.refreshdate);

            return (
              itemDate.toDateString() === targetDate.toDateString() &&
              item.End2EndId === id
            );
          }
        );
        return result?.Progress ? result.Progress : 0;
      } else return 0;
    },

    dateDaysOld(date, days) {
      const dateObject = new Date(date);

      const oneWeekInMillis = days * 24 * 60 * 60 * 1000;
      const currentDate = new Date();
      const differenceInMillis = currentDate - dateObject;

      return differenceInMillis <= oneWeekInMillis;
    },

    getTrendDepartment(id) {
      if (this.progressHistoryDepartmentLatestQuery) {
        let trendData = [];
        let result =
          this.progressHistoryDepartmentLatestQuery?.response?.filter(
            (item) =>
              item.DepartmentId == id && this.dateDaysOld(item.refreshdate, 7)
          );
        for (const t of result) {
          trendData.push(parseFloat(t.Progress));
        }
        trendData.push(this.departmentProc(id));
        return trendData.length > 1 ? this.getTrend(trendData) : 0;
      } else return 0;
    },

    getTrendEnd2End(id) {
      if (this.progressHistoryEnd2EndLatestQuery) {
        let trendData = [];
        let result = this.progressHistoryEnd2EndLatestQuery?.response?.filter(
          (item) =>
            item.End2EndId == id && this.dateDaysOld(item.refreshdate, 7)
        );
        for (const t of result) {
          trendData.push(parseFloat(t.Progress));
        }
        trendData.push(this.end2endProc(id));
        return trendData.length > 1 ? this.getTrend(trendData) : 0;
      } else return 0;
    },
  },

  computed: {
    end2endStatusParams() {
      let query = Object.assign({}, this.phaseQuery);
      query.query.department = false;
      query.query.end2end = true;
      query.type = null;
      return query;
    },
    departmentStatusParams() {
      let query = Object.assign({}, this.phaseQuery);
      query.query.department = true;
      query.query.end2end = false;
      query.type = null;
      return query;
    },
    end2EndParams() {
      return {};
    },
    departmentParams() {
      return { query: { detail: true } };
    },

    phaseQuery() {
      if (this.selectedPhase && this.selectedPhase != 'All') {
        return {
          query: {
            phase: this.selectedPhase,
          },
        };
      } else {
        return {};
      }
    },
    statusOverviewParams() {
      return this.phaseQuery;
    },
    progressData() {
      const progress = [];
      if (
        this.progressHistoryLatestQuery &&
        this.progressHistoryLatestQuery?.response
      ) {
        for (const obj of this.progressHistoryLatestQuery?.response?.filter(
          (f) => this.dateDaysOld(f.refreshdate, 7)
        )) {
          progress.push(parseFloat(obj.Progress));
        }
        progress.push(
          this.statusOverviewLatestQuery?.response[0].Progress * 100
        );
      }
      return progress;
    },
    progressHistoryParams() {
      let query = Object.assign({}, this.phaseQuery);
      query.query.department = false;
      query.query.end2end = false;
      query.query.type = 'progressHistory';
      return query;
    },
    progressHistoryEnd2EndParams() {
      let query = Object.assign({}, this.phaseQuery);
      query.query.department = false;
      query.query.end2end = false;
      query.query.type = 'progressHistoryEnd2End';
      return query;
    },
    progressHistoryDepartmentParams() {
      let query = Object.assign({}, this.phaseQuery);
      query.query.department = false;
      query.query.end2end = false;
      query.query.type = 'progressHistoryDepartment';
      return query;
    },
  },
};
</script>

<style lang="scss" scoped></style>
