<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-card class="mt-5">
          <v-card-title>
            <v-spacer> </v-spacer>
            <v-btn
              v-if="$can('create', 'fieldvaluelist')"
              elevation="0"
              class="mx-2"
              x-small
              fab
              color="primary"
              @click="clickAdd()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="filteredHeaders"
            :items="fieldvaluelist"
            :sort-by="['Default', 'TestRelevant', 'Number']"
            :sort-desc="[true, true, false]"
            disable-pagination
            hide-default-footer
            dense
            class="text-pre-wrap"
            :loading="isFindFieldvaluelistPending"
            loading-text="Loading... Please wait"
          >
            <template
              v-if="$can('update', 'fieldvaluelist')"
              v-slot:[`item.edit`]="{ item }"
            >
              <v-icon
                small
                class="mr-2"
                @click="clickEdit(item)"
                color="primary"
              >
                mdi-pencil
              </v-icon>
            </template>

            <template v-slot:[`item.Default`]="{ item }">
              <v-icon
                v-if="item.Default"
                small
                class="mr-2"
                color="primary"
                @click="
                  item.Default = !item.Default;
                  save(item);
                "
              >
                mdi-check-bold
              </v-icon>
              <v-icon
                v-else
                small
                class="mr-2"
                @click="
                  item.Default = !item.Default;
                  save(item);
                "
              >
                mdi-checkbox-blank-outline
              </v-icon>
            </template>

            <template v-slot:[`item.TestRelevant`]="{ item }">
              <v-icon
                v-if="item.TestRelevant"
                small
                class="mr-2"
                color="primary"
                @click="
                  item.TestRelevant = !item.TestRelevant;
                  save(item);
                "
              >
                mdi-check-bold
              </v-icon>
              <v-icon
                v-else
                small
                class="mr-2"
                @click="
                  item.TestRelevant = !item.TestRelevant;
                  save(item);
                "
              >
                mdi-checkbox-blank-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <FieldValueEdit
      v-if="detailDialog"
      v-model="detailDialog"
      :currentItem="currentItem"
      :fieldvaluelist="fieldvaluelist"
      :fieldId="currentFieldId"
    />
  </v-container>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import FieldValueEdit from '@/components/FieldValues/FieldValueEdit';

import { handleErrorResponse } from '@/utils/MessageHandler';

export default {
  props: ['fieldId'],
  data() {
    return {
      search: '',
      currentFieldId: null,
      headers: [
        {
          text: 'Edit',
          value: 'edit',
          sortable: false,
          groupable: false,
        },
        { text: 'Nr', value: 'Number', groupable: false },
        { text: 'Code', value: 'Code', groupable: false },
        { text: 'Value', value: 'Value', groupable: false },
        { text: 'Description', value: 'Description', groupable: false },
        {
          text: 'Default',
          value: 'Default',
          groupable: false,
          align: 'center',
        },
        {
          text: 'Test Relevant',
          value: 'TestRelevant',
          groupable: false,
          align: 'center',
        },
      ],
      detailDialog: false,
      currentItem: [],
    };
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter((item) => {
        return this.$can('update', 'fieldvaluelist')
          ? true
          : item.text != 'Edit';
      });
    },
    fieldvaluelistParams() {
      return { query: { FieldId: this.fieldId } };
    },
  },
  mixins: [makeFindMixin({ service: 'fieldvaluelist', watch: 'fieldId' })],
  components: {
    FieldValueEdit,
  },
  methods: {
    save(item) {
      if (this.fieldvaluelist.filter((f) => f.Default == true)?.length > 1) {
        handleErrorResponse({ message: 'Only one default value allowed' });
        item.Default = false;
      }
      if (item.Default) {
        item.TestRelevant = true;
      }
      item
        .save()

        .catch((error) => handleErrorResponse(error));
    },
    addProjectRoles(item) {
      this.showDialogProjectRoles = true;
      this.currentItem = item;
    },
    open() {},
    cancel() {},
    close() {},
    clickEdit(item) {
      this.currentItem = item;
      this.detailDialog = true;
    },
    clickAdd() {
      this.currentItem = null; //{};
      this.detailDialog = true;
      this.currentFieldId = this.fieldId;
    },
    clickDetail(clickedProcess) {
      this.setCurrentProcess(clickedProcess);
      this.$router.push({
        name: 'Process',
        params: { id: clickedProcess.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
