import feathersClient from '@/feathers-client';

let variant = null;
let processSteps = [];
let specifications = [];

async function CopySubProcess(processId, options) {
  let { includeSpecifications, includeFields } = options;
  variant = null;
  processSteps = [];
  specifications = [];

  await GetBaseData(processId);
  await CreateBaseData();
  await UpdateVariantNumber();
  if (includeSpecifications) {
    await CreateSpecifications();
  }
  if (includeFields) {
    await CreateFields();
  }
}

async function GetBaseData(subProcessId) {
  variant = await feathersClient.service('variant').get(subProcessId);
  if (variant) {
    try {
      let steps = await feathersClient
        .service('process-step')
        .find({ query: { VariantId: variant.id } });

      processSteps.push(...steps.data);
    } catch (error) {
      console.log(error);
    }
  }
}

async function CreateBaseData() {
  // Create Variants
  if (variant) {
    let variantCreated = null;
    variant.originalId = variant.id;

    delete variant.createdAt;
    delete variant.updatedAt;
    delete variant.ModifiedById;
    delete variant.id;

    try {
      variantCreated = await feathersClient.service('variant').create(variant);
    } catch (error) {
      console.log(error);
    }
    if (variantCreated) {
      // Create Steps
      variant.id = variantCreated.id;
      if (processSteps) {
        await Promise.all(
          processSteps.map(async (s) => {
            let stepCreated = null;
            delete s.createdAt;
            delete s.updatedAt;
            delete s.ModifiedById;
            delete s.GroupId; //TODO also copy groups
            delete s.id;
            s.VariantId = variantCreated.id;
            try {
              stepCreated = await feathersClient
                .service('process-step')
                .create(s);
            } catch (error) {
              console.log(error);
            }
            s.id = stepCreated.id;
          })
        );
      }
    }
  }
}

async function UpdateVariantNumber() {
  await feathersClient
    .service('variant')
    .patch(variant.originalId, { Number: variant.Number + 100 });
}

async function CreateSpecifications() {
  // Get Specifications
  specifications = [];

  let allSpecs = null;
  allSpecs = await feathersClient.service('specification').find();

  let specs = null;
  try {
    specs = allSpecs.data.filter((s) => {
      return s.variants.some((v) => v.id == variant.originalId);
    });
  } catch (error) {
    console.log(error);
  }
  if (specs) {
    specs.forEach((s) => (s.VariantId = variant.id));
    specifications.push(...specs);
  }

  if (specifications.length > 0) {
    for (const s of specifications) {
      // Link Specifications to Variant
      try {
        await feathersClient.service('specification-variant').create({
          VariantId: s.VariantId,
          SpecificationId: s.id,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }
}

async function CreateFields() {
  processSteps.map(async (p) => {
    if (p.fields.length > 0) {
      await Promise.all(
        p.fields.map(async (f) => {
          // link to step
          try {
            await feathersClient.service('field-step').create({
              ProcessStepId: p.id,
              FieldId: f.id,
            });
          } catch (error) {
            console.log(error);
          }
        })
      );
    }
  });
}

export { CopySubProcess };
