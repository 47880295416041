<template>
  <div>
    {{ $store.state.activeProcessNode }}
    <!-- 
    p{{ process.Name }} v{{ variant.Name }} e2e{{ end2End }} th{{
      testScenarioHeader.Name
    }}
    tl{{ testScenarioLine.Name }} -->
  </div>
</template>

<script>
import { makeGetMixin } from 'feathers-vuex';
export default {
  mixins: [
    makeGetMixin({
      service: 'process',
      id: 'processId',
    }),
    makeGetMixin({
      service: 'end-2-end',
      id: 'end2EndId',
    }),
    makeGetMixin({
      service: 'variant',
      id: 'variantId',
    }),
    makeGetMixin({
      service: 'test-scenario-header',
      id: 'testScenarioHeaderId',
    }),
    makeGetMixin({
      service: 'test-scenario-line',
      id: 'testScenarioLineId',
    }),
  ],
  computed: {
    processId() {
      return this.$store.state.activeProcessNode.ProcessId;
    },
    variantId() {
      return this.$store.state.activeProcessNode.VariantId;
    },
    end2EndId() {
      return this.$store.state.activeProcessNode.End2EndId;
    },
    testScenarioHeaderId() {
      return this.$store.state.activeProcessNode.TestScenarioHeaderId;
    },
    testScenarioLineId() {
      return this.$store.state.activeProcessNode.TestScenarioLineId;
    },
  },
};
</script>

<style lang="scss" scoped></style>
