<template>
  <v-list
    dense
    class="list ma-0"
    v-if="fieldStep && !isFindFieldvaluelistPending"
  >
    <v-list-item v-for="(field, index) in fieldStep" :key="index">
      <v-list-item-content>
        <!-- <v-list-item-title> {{ field.field.Name }} </v-list-item-title> -->
        <v-list-item-subtitle>
          <v-select
            multiple
            chips
            small-chips
            outlined
            :label="field.field.Name"
            v-model="selectedFields"
            :items="
              fieldValueListExtended.filter((f) => f?.FieldId == field?.FieldId)
            "
            item-text="fullName"
            item-value="id"
            flat
            dense
            :disabled="disabled"
            class="my-1"
            hide-details
            return-object
            clearable
            width="200"
            @blur="saveFields()"
          ></v-select>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
export default {
  props: {
    testScenarioLineStep: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      selectedFields: null,
    };
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-line-step-field',
      watch: ['testScenarioLineStep.id'],
    }),
    makeFindMixin({
      service: 'field-step',
      watch: ['testScenarioLineStep.id'],
    }),
    makeFindMixin({
      service: 'fieldvaluelist',
      watch: ['fieldIds'],
    }),
  ],
  watch: {
    isFindTestScenarioLineStepFieldPending: function () {
      if (!this.isFindTestScenarioLineStepFieldPending) {
        this.selectedFields = this.testScenarioLineStepField.map(
          (m) => m.fieldvaluelist
        );
      }
    },
    isFindFieldvaluelistPending: function () {
      if (
        !this.isFindTestScenarioLineStepFieldPending &&
        !this.isFindFieldvaluelistPending
      ) {
        this.selectedFields = this.testScenarioLineStepField.map(
          (m) => m.fieldvaluelist
        );
      }
    },
    isPending: function () {
      if (!this.isPending) {
        this.selectedFields = this.testScenarioLineStepField.map(
          (m) => m.fieldvaluelist
        );
      }
    },
  },
  computed: {
    isPending() {
      return (
        this.isFindTestScenarioLineStepFieldPending ||
        this.isFindFieldvaluelistPending ||
        this.isFindFieldStepPending
      );
    },
    fieldValueListExtended() {
      return this.fieldvaluelist
        .map((m) => ({
          ...m,
          fullName: `${m.Code} ${m.Value ? '(' + m.Value + ')' : ''}`, //
        }))
        .sort((a, b) => a.Code.localeCompare(b.Code));
    },
    TestScenarioLineStepId() {
      return this.testScenarioLineStep.id;
    },
    testScenarioLineStepFieldParams() {
      return {
        query: {
          TestScenarioLineStepId: this.TestScenarioLineStepId,
        },
      };
    },

    fieldStepParams() {
      return {
        query: {
          ProcessStepId: this.testScenarioLineStep.ProcessStepId,
        },
      };
    },
    fieldIds() {
      return this.fieldStep.map((m) => m.field.id);
    },
    fieldvaluelistParams() {
      return {
        queryWhen: this.fieldIds?.length > 0,
        query: { FieldId: { $in: this.fieldIds } },
      };
    },
  },
  methods: {
    async saveFields() {
      const { TestScenarioLineStepField } = this.$FeathersVuex.api;

      // Remove al field values on testscenariolinestep
      for (const stepfieldvalue of this.testScenarioLineStepField) {
        try {
          await stepfieldvalue.remove();
        } catch (error) {
          handleErrorResponse(error);
        }
      }

      // // recreate all those selected
      for (const fieldvalue of this.selectedFields.flat()) {
        let newFieldValue = new TestScenarioLineStepField();
        newFieldValue.TestScenarioLineStepId = this.testScenarioLineStep.id;
        newFieldValue.FieldValueListId = fieldvalue.id;
        try {
          let saved = await newFieldValue.save();
          handleSaveResponse(
            saved.Value,
            'Test Scenario Line Step Field',
            'added'
          );
        } catch (error) {
          handleErrorResponse(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  background-color: transparent !important;
}
</style>
