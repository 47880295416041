<template>
  <div>
    <v-dialog :value="true" persistent max-width="1300px">
      <v-card class="pl-5 pr-5 pb-5">
        <v-card-title class="text-h5 mb-0 pb-0">
          Execute Test Run: {{ testScenarioRun.id }} - {{ testScenarioRun.Name
          }}<v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row
            ><v-col v-if="testScenarioRun.Description" class="text-pre-wrap"
              ><span v-html="testScenarioRun.Description"></span> </v-col
          ></v-row>
        </v-card-text>
        <v-card-subtitle>
          <div class="text-h5">
            {{ testScenarioRunDetail.Order }} - {{ testScenarioRunDetail.Name }}
            <v-tooltip
              bottom
              v-if="
                ($feature('cloudinaryChartIntegration') && processUrl) ||
                ($feature('lucidChartIntegration') && lucidUrl) ||
                ($feature('drawIOChartIntegration') && drawingUrl)
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2 mb-1"
                  @click="
                    openWindow(
                      $feature('drawIOChartIntegration') && drawingUrl
                        ? drawingUrl
                        : $feature('lucidChartIntegration') && lucidUrl
                        ? lucidUrl
                        : processUrl
                    )
                  "
                >
                  mdi-image-search-outline
                </v-icon>
              </template>
              <span>Process Flows</span>
            </v-tooltip>
          </div>

          <router-link
            :to="{
              name: 'processDetail',
              params: {
                id: testScenarioRunDetail.process.ProcessId,
                number: testScenarioRunDetail.process.ProcessNumber,
              },
            }"
            >{{ $appConfig.customer.prefix
            }}{{
              testScenarioRunDetail.process.ProcessNumber.toString().padStart(
                4,
                '0'
              )
            }}
            - {{ testScenarioRunDetail.process.ProcessName }}</router-link
          >

          <v-icon small>mdi-chevron-right</v-icon>
          <router-link
            :to="{
              name: 'processStep',
              params: { id: testScenarioRunDetail.process.VariantId },
            }"
            >{{ testScenarioRunDetail.process.VariantNumber }} -
            {{ testScenarioRunDetail.process.VariantName }}</router-link
          >
        </v-card-subtitle>
        <v-card-text class="my-0 py-0"> </v-card-text>

        <v-card-text class="my-0 py-0">
          <v-row
            ><v-col
              v-if="testScenarioRunDetail.process.VariantInput"
              class="text-pre-wrap"
              ><span
                v-html="testScenarioRunDetail.process.VariantInput"
              ></span> </v-col
          ></v-row>
          <v-row
            ><v-col v-if="testScenarioRunDetail.Description" class="mb-0 pb-0"
              ><h4 class="my-0 py-0">Test Remark</h4>
              <span v-html="testScenarioRunDetail.Description"></span> </v-col
          ></v-row>

          <v-row
            ><v-col>
              <div v-show="ErpApplications?.length > 0">
                <h4 class="my-0 py-0">Application</h4>
                <v-list dense class="list pl-0 ml-0 my-0 py-0"
                  ><v-list-item
                    v-for="app in ErpApplications"
                    :key="app.id"
                    class="ml-0 pl-0"
                    ><v-list-item-content
                      ><v-tooltip top open-delay="1000">
                        <template v-slot:activator="{ on, attrs }"
                          ><span v-bind="attrs" v-on="on">
                            <a v-if="app.Link" :href="app.Link" target="_blank">
                              <span v-if="app.Customized">*</span>
                              {{ app.Name }}
                            </a>
                            <span v-else>{{ app.Name }}</span>
                          </span></template
                        >{{ app.Name }}</v-tooltip
                      ><v-tooltip top open-delay="1000">
                        <template v-slot:activator="{ on, attrs }"
                          ><span v-bind="attrs" v-on="on"
                            >{{ app.Filter }}
                          </span></template
                        >{{ app.Filter }}</v-tooltip
                      ></v-list-item-content
                    ></v-list-item
                  >
                </v-list>
              </div></v-col
            ></v-row
          >

          <v-row>
            <v-col cols="12" md="4"
              ><span v-if="testScenarioRunDetail.TestInput" class="mb-5"
                >Test Input: {{ testScenarioRunDetail.TestInput }}</span
              >
            </v-col>
            <v-col cols="12" md="8">
              <div v-if="testHistory?.length > 0">
                <span class="mb-5"
                  >Test History ({{ testHistory?.length }})</span
                >
                <v-btn icon @click="toggleTable">
                  <v-icon>{{
                    isTableVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'
                  }}</v-icon>
                </v-btn>

                <!-- Conditional rendering of the v-data-table -->
                <v-expand-transition>
                  <div v-if="isTableVisible">
                    <v-data-table
                      dense
                      :headers="headers2"
                      :items="testHistory"
                    >
                    </v-data-table>
                  </div>
                </v-expand-transition>
              </div>
            </v-col>
          </v-row>

          <v-data-table
            v-if="testScenarioRunSteps"
            :headers="headers"
            :items="testScenarioRunSteps"
            sort-by="Number"
            disable-pagination
            hide-default-footer
            show-expand
            class="text-pre-wrap"
            :loading="isFindTestScenarioRunStepsPending"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.color`]="{ item }">
              <span
                v-for="color in group.find((f) => f.id == item.GroupId)
                  ?.allGroups"
                :key="color.id"
                ><span v-if="color.Loop">
                  <v-tooltip bottom open-delay="500">
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="circle"
                        :style="{
                          background: color.Color,
                          color: applyDark(color.Color) ? 'white' : 'black',
                        }"
                        >{{
                          item.Groups?.find((f) => f.GroupId == color.id)
                            ?.GroupIteration + 1
                        }}</span
                      ></template
                    >{{ color.Name }}</v-tooltip
                  ></span
                ><span v-else>
                  <v-tooltip bottom open-delay="500">
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="circle"
                        :style="{
                          background: color.Color,
                          color: applyDark(color.Color) ? 'white' : 'black',
                        }"
                      >
                        &nbsp;</span
                      ></template
                    >{{ color.Name }}</v-tooltip
                  >
                </span>
              </span>
            </template>
            <template v-slot:[`item.Fields`]="i = { item }"
              ><div>
                <v-list
                  dense
                  class="ma-0"
                  v-if="
                    testScenarioRunFieldValues.find(
                      (f) => f.TestScenarioRunStepsId == i.item.id
                    )
                  "
                >
                  <v-list-item
                    v-for="field in listField(i.item.id)"
                    :key="field.id"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ field }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-for="value in listFieldValues(i.item.id, field)"
                        :key="value.id"
                      >
                        {{ value.Code }}
                        <span v-if="value.Name"
                          >({{ value.Name }})</span
                        ></v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div></template
            >
            <template v-slot:[`item.TestRemark`]="{ item }">
              <div v-if="item.reference?.length > 0">
                <v-icon>mdi-menu-right</v-icon>
                <span style="font-weight: 500">{{ item.reference }}</span>
              </div>
              <span v-html="item.TestRemark"
            /></template>
            <template v-slot:[`item.TestResult`]="{ item }"
              ><div>
                <v-icon
                  @click="validateTest(item)"
                  v-if="item.Done && item.blockedBugs == 0"
                  color="green"
                  small
                  class="mr-1"
                  >mdi-check-bold</v-icon
                >
                <v-icon
                  v-if="item.blockedBugs > 0"
                  color="red"
                  small
                  class="mr-1"
                  >mdi-close-thick</v-icon
                ><v-icon
                  @click="validateTest(item)"
                  v-if="!item.Done && item.blockedBugs == 0"
                  color="primary"
                  small
                  class="mr-1"
                  >mdi-checkbox-blank-outline
                </v-icon>
              </div>
            </template>
            <template v-slot:[`item.Issue`]="{ item }">
              <v-icon
                class="ml-2"
                small
                @click="clickAddIssue(item)"
                :color="
                  item.blockedBugs > 0
                    ? 'red'
                    : item.openBugs > 0
                    ? 'orange'
                    : item.issueStatus?.length > 0
                    ? 'green'
                    : 'primary'
                "
              >
                mdi-login-variant
              </v-icon>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td
                :colspan="headers.length"
                v-if="item.process_step?.Description"
              >
                <v-row
                  ><v-col class="mb-0 pb-0"
                    ><span
                      class="ma-3 ql-editor"
                      v-html="item.process_step.Description"
                    ></span></v-col></v-row
                ><v-row
                  ><v-col align="right" class="mt-0 pt-0">
                    <v-btn icon @click="toggle(item.id)">
                      <v-icon small>{{ 'mdi-swap-horizontal' }}</v-icon>
                    </v-btn>
                    <Updated
                      v-if="show[item.id]"
                      class="mr-5 my-2"
                      :updateDate="item.updatedAt"
                      :userId="item.ModifiedById"
                      table="process_step"
                      :id="item.id" /></v-col
                ></v-row>
              </td>
            </template>
          </v-data-table>
          <span v-if="this.testScenarioRunDetail.ApplicationMatch">
            The next test uses the same application. Keep the current screen
            open.
          </span>
        </v-card-text>
        <v-card-actions class="mt-5">
          <v-text-field
            v-model="testScenarioRunDetail.TestOutput"
            :label="`Output ${testScenarioRunDetail.ReferenceName}`"
            v-if="testScenarioRunDetail.ReferenceName?.length > 0"
            outlined
            dense
            hide-details
            class="mr-5"
            :rules="[rules.required]"
            @blur="saveOutputReference"
          >
          </v-text-field>
          <v-btn
            :loading="validating"
            @click="validateAll"
            :disabled="
              !testScenarioRunSteps.filter((f) => f.Done == false)?.length > 0
            "
            >Validate All</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            :disabled="
              this.testScenarioRunDetail.ReferenceName?.length > 0 &&
              !this.testScenarioRunDetail.TestOutput &&
              StepValidated
            "
            color="primary"
            :loading="closing || validating"
            @click="close()"
          >
            Close
          </v-btn>
        </v-card-actions>
        <v-dialog
          v-if="issueDialog"
          v-model="issueDialog"
          hide-overlay
          width="90vw"
          height="auto"
          ><v-card height="90vh" class="pa-5">
            <IssueList
              v-if="issueDialog"
              v-model="issueDialog"
              :testScenarioRunStep="currentStep"
            />
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import Updated from '@/components/general/Updated.vue';
import IssueList from '@/components/Issues/IssueList.vue';
import { handleErrorResponse } from '@/utils/MessageHandler';
import feathersClient from '@/feathers-client';
import { applyDark } from '@/utils/Utilities';
import {
  getDocumentEmbed,
  getDocumentEmbedToken,
} from '@/utils/LucidChartFunctions';

export default {
  props: {
    testScenarioRun: {
      type: Object,
      required: true,
    },
    testScenarioRunDetail: {
      type: Object,
      required: true,
    },

    ro: { type: Boolean, default: false, required: false },
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-run-steps',
      watch: 'testScenarioRunDetail.id',
    }),
    makeFindMixin({
      service: 'test-scenario-run-field-values',
      watch: 'testScenarioRunDetail.id',
    }),
    makeFindMixin({
      service: 'group',
    }),
  ],

  components: { Updated, IssueList },
  data() {
    return {
      show: [],
      loading: false,
      currentItem: null,
      embedToken: null,
      currentStep: null,
      TestOutput: null,
      StepValidated: false,
      issueDialog: false,
      ErpApplications: [],
      closing: false,
      validating: false,
      isTableVisible: false,
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      headers: [
        {
          text: '',
          value: 'color',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Number',
          value: 'Number',
          sortable: false,
          groupable: false,
        },

        {
          text: 'Step',
          value: 'Name',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Fields',
          value: 'Fields',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Test Remark',
          value: 'TestRemark',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Test Result',
          value: 'TestResult',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: 'Issue',
          value: 'Issue',
          sortable: false,
          groupable: false,
          align: 'center',
        },

        { text: '', value: 'data-table-expand' },
      ],
      headers2: [
        { text: 'Test', value: 'test' },
        { text: 'Output Reference', value: 'ReferenceName' },
        { text: 'Output', value: 'output' },
      ],
    };
  },
  methods: {
    applyDark,
    toggleTable() {
      this.isTableVisible = !this.isTableVisible;
    },
    async close() {
      this.closing = true;
      await this.refresh();

      this.$emit('cancel');
      this.ro ? this.$router.go(-1) : '';
      this.closing = false;
    },
    clickAddIssue(item) {
      this.issueDialog = true;
      this.currentStep = item;
    },
    async validateAll() {
      this.validating = true;

      try {
        await feathersClient.service('test-scenario-run-steps').patch(
          null,
          { Done: true },
          {
            query: { TestScenarioRunDetailId: this.testScenarioRunDetail.id },
          }
        );
        this.StepValidated = true;
      } catch (error) {
        console.log(error);
      }

      await this.refresh();

      // for (const step of this.testScenarioRunSteps.sort(
      //   (a, b) => a.Number - b.Number
      // )) {
      //   await this.validateTest(step);
      // }
      this.validating = false;
    },
    toggle(i) {
      if (this.show[i] == undefined) {
        this.show[i] = true;
        this.show = [...this.show];
      } else {
        this.show[i] = !this.show[i];
        this.show = [...this.show];
      }
    },
    async saveOutputReference() {
      // this.testScenarioRunDetail.TestOutput = this.TestOutput;
      try {
        await this.testScenarioRunDetail.save();
      } catch (error) {
        handleErrorResponse(error);
      }

      this.StepValidated = true;
      await this.refresh();
    },
    async validateTest(item) {
      item.Done = !item.Done;
      try {
        await item.save();
      } catch (error) {
        handleErrorResponse(error);
      }

      this.StepValidated = true;
    },
    async refresh() {
      const { TestScenarioRun, TestScenarioRunDetail } = this.$FeathersVuex.api;

      await TestScenarioRunDetail.find({
        query: { TestScenarioRunId: this.testScenarioRun.id },
      });
      await TestScenarioRun.find({
        query: { id: this.testScenarioRun.id },
      });
    },
    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },
    async LucidEmbed() {
      if (this.testScenarioRunDetail?.process?.ExternalDocumentId) {
        if (!this.testScenarioRunDetail?.process?.EmbedId) {
          await getDocumentEmbed({
            id: this.testScenarioRunDetail?.process?.ProcessId,
            ExternalDocumentId:
              this.testScenarioRunDetail?.process?.ExternalDocumentId,
          });
          // console.log('No Embed');
        }
        this.embedToken = await getDocumentEmbedToken({
          id: this.testScenarioRunDetail?.process?.ProcessId,
          ExternalDocumentId:
            this.testScenarioRunDetail?.process?.ExternalDocumentId,
        });
        // console.log('Embed', this.embedToken);
      } else {
        // console.log('No document Linked');
      }
    },
    listField(step) {
      let fieldObject = [];

      const fields = new Set(); // Create a new Set to store unique values
      this.testScenarioRunFieldValues
        .filter((f) => f.TestScenarioRunStepsId == step)
        .forEach((f2) => (f2.Field ? fields.add(f2.Field) : null));
      fieldObject.push(fields);
      return Array.from(fields);
    },
    listFieldValues(step, field) {
      let values = this.testScenarioRunFieldValues.filter(
        (f) =>
          (f.Field ? f.Field == field : null) &&
          (f.TestScenarioRunStepsId ? f.TestScenarioRunStepsId == step : null)
      );

      return values;
    },
  },
  computed: {
    testScenarioRunStepsParams() {
      return {
        query: { TestScenarioRunDetailId: this.testScenarioRunDetail.id },
      };
    },
    testScenarioRunFieldValuesParams() {
      return {
        query: { TestScenarioRunDetailId: this.testScenarioRunDetail.id },
      };
    },
    groupParams() {
      return {};
    },
    testHistory() {
      if (this.testScenarioRunDetail.TestInputAll) {
        return this.testScenarioRunDetail.TestInputAll.split('///')
          .reverse()
          .map((entry, index) => {
            // Split each entry by '::' to separate text and output, with trimming for cleanliness
            const [test = '', output = '', ReferenceName = ''] = entry
              .split('::')
              .map((str) => str.trim());
            return {
              order:
                this.testScenarioRunDetail.TestInputAll.split('///').length -
                index,
              test,
              output,
              ReferenceName,
            };
          });
      } else return null;
    },

    processUrl() {
      let url = '';
      if (this.testScenarioRunDetail.process) {
        url =
          'https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v' +
          Date.now() +
          '/' +
          this.$appConfig.customer.prefix +
          '/' +
          this.testScenarioRunDetail.process.ProcessNumber.toString() +
          '.' +
          this.testScenarioRunDetail.process.VariantNumber.toString();
      }
      return url;
    },
    drawingUrl() {
      if (this.testScenarioRunDetail.process.NrOfDrawings > 0) {
        return `${this.$appConfig.clientBaseUrl}/#/drawing?variant=${this.testScenarioRunDetail.process.VariantId}`;
      } else {
        return null;
      }
    },

    lucidUrl() {
      if (this.testScenarioRunDetail.process && this.embedToken) {
        return `https://lucid.app/documents/${this.testScenarioRunDetail.process.ExternalDocumentId}/viewer?pages=1
        &token=${this.embedToken}`;
      }
      return null;
    },
  },
  async mounted() {
    this.loading = true;

    if (this.testScenarioRunDetail?.process?.VariantId) {
      const { erpapplication_variant, erp_application } =
        this.$FeathersVuex.api;
      let app_variant = await erpapplication_variant.find({
        query: {
          VariantId: this.testScenarioRunDetail?.process?.VariantId,
        },
      });
      if (app_variant.total > 0) {
        app_variant.data.forEach(async (a) => {
          let app = await erp_application.get(a.ErpApplicationId);
          this, this.ErpApplications.push(app);
        });
      }
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.list {
  background-color: transparent !important;
}
span.circle {
  // background: red;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 2px;
  text-align: center;
  width: 1.6em;
}

span.pointer {
  cursor: pointer;
}
</style>
