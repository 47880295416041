<template>
  <div>
    <ul>
      <li v-for="(line, index) in uniqueLines" :key="index">
        {{ line.Name }}
        <ul>
          <li
            v-for="(field, index) in exportQueriesLatestQuery?.response.filter(
              (f) => f.TestLineId == line.id
            )"
            :key="index"
          >
            <em> {{ field.Field }}</em
            >: <b> {{ field.FieldValue }}</b>
          </li>
        </ul>
      </li>
    </ul>

    <!-- <ul v-if="exportQueriesLatestQuery?.response?.length > 0">
      <li
        v-for="(field, index) in exportQueriesLatestQuery.response"
        :key="index"
      >
        {{ field.TestLine }}:
        <b
          >{{ field.Field }}: <em> {{ field.FieldValue }}</em></b
        >
      </li>
    </ul> -->
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
export default {
  mixins: [
    makeFindMixin({
      service: 'export-queries',
      watch: ['testScenarioHeader.id'],
    }),
  ],
  props: {
    testScenarioHeader: {
      type: Object,
      required: true,
    },
  },
  computed: {
    exportQueriesParams() {
      return {
        query: { type: 'headerFields', id: this.testScenarioHeader.id },
      };
    },
    uniqueLines() {
      const unique = new Map();
      this.exportQueriesLatestQuery?.response?.forEach((field) => {
        if (!unique.has(field.TestLineId)) {
          unique.set(field.TestLineId, {
            id: field.TestLineId,
            Name: field.TestLine,
          });
        }
      });
      return Array.from(unique.values());
    },
  },
};
</script>

<style lang="scss" scoped></style>
