<template>
  <v-container>
    <h1 v-if="end2End[0]">Process List - {{ end2End[0].Name }}</h1>
    <h1 v-else>Process List Full</h1>
    <div v-if="!end2End[0]">
      <StatusSummary @ChangePhase="ChangePhase" />
    </div>
    <div v-if="$feature('drawIOChartIntegration') && end2End[0]">
      <v-row>
        <v-col class="mt-5 mb-10"><DrawIO :end2EndId="end2End[0].id" /></v-col
      ></v-row>
    </div>
    <div v-if="$feature('lucidChartIntegration') && embedToken && LucidUrl">
      <iframe
        frameBorder="0"
        v-if="embedToken"
        width="1000px"
        height="500px"
        :src="LucidUrl"
      ></iframe>
    </div>
    <div v-if="$feature('cloudinaryChartIntegration')">
      <div @click="openWindow(End2EndUrl)">
        <v-img v-if="end2End[0]" :src="End2EndUrl" width="80%" />
      </div>
    </div>

    <v-row dense>
      <v-col cols="12">
        <v-card class="mt-5">
          <v-card-title>
            <v-icon class="mr-10 mt-7" @click="toggleAll()" color="primary">
              mdi-arrow-expand-vertical</v-icon
            ><v-icon
              v-if="$can('print', 'full')"
              class="mr-5 mt-7"
              @click="showPrint()"
              color="primary"
            >
              mdi-printer-outline
            </v-icon>
            <FullExport v-if="$can('export', 'full')" />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

            <v-spacer> </v-spacer>
            <v-btn
              v-if="$can('create', 'process') && !this.end2endId"
              elevation="0"
              fab
              small
              color="primary"
              @click="clickAdd()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
            <v-btn
              v-if="end2endId && $can('create', 'end2End_process')"
              elevation="0"
              class="ml-4"
              fab
              small
              color="primary"
              @click="clickAddProcessToE2E()"
            >
              <v-icon dark> mdi-playlist-plus </v-icon></v-btn
            >
          </v-card-title>
          <v-data-table
            :headers="filteredHeaders"
            :items="processList"
            :search="search"
            :sort-by="['Order', 'Number']"
            :group-by="group"
            show-group-by
            disable-pagination
            hide-default-footer
            :loading="isFindProcessPending"
            loading-text="Loading... Please wait"
            @update:group-by="groupBy"
          >
            <template
              v-slot:[`group.header`]="{
                group,
                headers,
                toggle,
                isOpen,
                remove,
              }"
            >
              <td :colspan="headers.length">
                <v-btn
                  @click="toggle"
                  small
                  icon
                  :ref="group"
                  :data-open="isOpen"
                >
                  <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
                {{ group }}
                <v-btn icon @click="unGroup(remove)">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </td>
            </template>
            <template v-slot:[`item.Name`]="{ item }"
              ><a class="normalColorLink" @click="clickDetail(item)">{{
                item.Name
              }}</a>
              <v-icon
                v-if="
                  $feature('drawIOChartIntegration') &&
                  item.nrOfDrawings == 0 &&
                  $can('create', 'process')
                "
                small
                class="ml-2"
                color="red"
              >
                mdi-link </v-icon
              ><v-icon
                v-if="
                  item.end_2_ends &&
                  item.end_2_ends.length == 0 &&
                  $can('create', 'end2End_process')
                "
                class="ml-2"
                color="red"
                small
              >
                mdi-file-tree
              </v-icon>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                large
                class="mr-2"
                @click="clickDetail(item)"
                color="primary"
              >
                mdi-chevron-right
              </v-icon>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <v-icon
                v-if="$can('update', 'process', 'Name')"
                small
                class="mr-2"
                @click="clickEdit(item)"
                color="primary"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                v-if="$can('print', 'process')"
                small
                class="mr-2"
                @click="showPrint(item)"
                color="primary"
              >
                mdi-printer-outline
              </v-icon>
            </template>
            <template v-slot:[`item.unlink`]="{ item }">
              <v-icon
                v-if="$can('delete', 'end2End_process')"
                small
                class="mr-2"
                @click="unlinkProcessRequest(item)"
                color="primary"
              >
                mdi-link-off
              </v-icon>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <div
                v-if="
                  item.StatusSummarySteps && item.StatusSummarySteps.length > 0
                "
              >
                <v-icon x-small>mdi-format-list-text</v-icon>
                <StatusListIndividual :statusList="item.StatusSummarySteps" />
              </div>
              <div
                v-if="
                  item.StatusSummarySpecs && item.StatusSummarySpecs.length > 0
                "
              >
                <v-icon x-small>mdi-pin</v-icon>
                <StatusListIndividual :statusList="item.StatusSummarySpecs" />
              </div>

              <div>
                <v-icon x-small @click="clickAddIssue(item)"
                  >mdi-login-variant</v-icon
                >
                <StatusListIndividual
                  v-if="
                    item.StatusSummaryIssues &&
                    item.StatusSummaryIssues.length > 0
                  "
                  :statusList="item.StatusSummaryIssues"
                />
              </div>
            </template>
          </v-data-table>
          <v-card-actions
            ><v-spacer></v-spacer>
            <v-icon
              v-if="$can('update', 'process') && end2endId"
              class="ml-5"
              @click="showReorder = true"
            >
              mdi-order-numeric-ascending
            </v-icon></v-card-actions
          >
        </v-card>
      </v-col>
    </v-row>
    <Modal
      v-if="showModal"
      :title="'Are you sure?'"
      :body="`Process '${unlinkProcess.Name}' will be removed from End 2 End '${end2End[0].Name}'`"
      @cancel="showModal = false"
      @confirm="deleteLink"
    />
    <PrintModal
      v-if="showPrintDialog"
      v-model="showPrintDialog"
      :itemtoPrint="itemtoPrint"
      printType="Process"
    />

    <ReOrder v-if="showReorder" v-model="showReorder" :end2endId="end2endId" />

    <ProcessEdit
      v-if="detailDialog"
      v-model="detailDialog"
      :currentItem="currentItem"
    />

    <E2EAddProcess
      v-if="addProcessDialog"
      v-model="addProcessDialog"
      :end2EndId="end2endId"
    ></E2EAddProcess>
    <v-dialog
      v-if="issueDialog"
      v-model="issueDialog"
      hide-overlay
      width="90vw"
      height="auto"
      ><v-card height="90vh" class="pa-5">
        <IssueList
          v-if="issueDialog"
          v-model="issueDialog"
          :process="currentProcess"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import ProcessEdit from '@/components/Process/ProcessEdit';
import StatusListIndividual from '@/components/StatusListIndividual';
import StatusSummary from '@/components/StatusSummary';
import E2EAddProcess from '@/components/End2End/E2EAddProcess';
import FullExport from '@/components/ExportData/FullExport';
import ReOrder from '@/components/Process/ReOrder';
import IssueList from '@/components/Issues/IssueList.vue';
import DrawIO from '@/components/Drawing/drawio.vue';
import PrintModal from '@/components/general/PrintModal.vue';

import Modal from '@/components/general/Modal.vue';

import { mapMutations } from 'vuex';

import {
  getDocumentEmbed,
  getDocumentEmbedToken,
} from '@/utils/LucidChartFunctions';

export default {
  props: ['end2endId'],
  data() {
    return {
      showReorder: false,
      issueDialog: false,
      currentProcess: null,
      search: '',
      group: 'department.Name',
      images: [],
      headers: [
        {
          text: '',
          value: 'edit',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Unlink',
          value: 'unlink',
          sortable: false,
          groupable: false,
        },
        { text: 'Nr', value: 'Number', groupable: false },
        {
          text: 'Order',
          value: 'Order',
          groupable: false,
        },
        { text: 'Process', value: 'Name', groupable: false },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Phase',
          value: 'Phase',
          sortable: true,
          groupable: true,
        },
        {
          text: 'Department',
          value: 'department.Name',
          sortable: true,
          groupable: true,
        },
        { text: 'ERP Module', value: 'department.ERPModule', groupable: true },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          groupable: false,
        },
      ],
      detailDialog: false,
      addProcessDialog: false,
      currentItem: [],
      showModal: false,
      unlinkProcess: null,
      embedToken: null,
      selectedPhase: null,
      showPrintDialog: false,
      itemtoPrint: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    LucidUrl() {
      return this.end2End[0].ExternalDocumentId
        ? `https://lucid.app/documents/${
            this.end2End[0].ExternalDocumentId
          }/viewer?pages=${
            this.end2End[0].PageIndexes
              ? this.end2End[0].PageIndexes
              : this.end2End[0].Number
          }&token=${this.embedToken}`
        : null;
    },
    filteredHeaders() {
      let filtered = this.headers.filter((item) => {
        return this.$can('update', 'process') || this.$can('print', 'process')
          ? true
          : item.value != 'edit';
      });
      filtered = filtered.filter((item) => {
        return this.$can('delete', 'end2End_process')
          ? true
          : item.text != 'Unlink';
      });

      filtered = filtered.filter((item) => {
        return this.end2endId ? true : item.text != 'Unlink';
      });

      filtered = filtered.filter((item) => {
        return this.$appConfig.externalIssues ? true : item.value != 'issue';
      });

      return filtered;
    },
    processParams() {
      return {};
    },
    end2EndParams() {
      return {
        query: {
          id: parseInt(this.$route.params.id),
          $sort: {
            Number: 1,
          },
        },
      };
    },
    End2EndUrl() {
      if (this.end2End[0]) {
        let url = this.end2End[0].Number.toString().padStart(2, '0');
        //this.images.push(`End2EndFlows/E2E${url}.svg`);
        //return `End2EndFlows/E2E${url}.svg`;
        return `https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v${Date.now()}/${
          this.$appConfig.customer.prefix
        }/E2E${url}.svg`;
      }
      return '';
    },
    processList() {
      if (this.end2endId) {
        let list = this.process.filter((item) =>
          item.end_2_ends.some((e) => e.id == this.end2endId)
        );
        list = list.map((m) => {
          return {
            ...m,
            Order: m.end_2_ends.find((e) => e.id == this.end2endId)
              .end2End_process.Order,
          };
        });

        return list;
      } else {
        if (this.selectedPhase == 'All') {
          return this.process;
        } else {
          return this.process.filter(
            (f) => f.Phase == parseInt(this.selectedPhase)
          );
        }
      }
    },
  },
  mixins: [
    makeFindMixin({ service: 'process' }),
    makeFindMixin({ service: 'end-2-end', watch: '$route.params.id' }),
  ],
  components: {
    ProcessEdit,
    StatusListIndividual,
    StatusSummary,
    E2EAddProcess,
    Modal,
    FullExport,
    ReOrder,
    IssueList,
    DrawIO,
    PrintModal,
  },
  watch: {
    '$route.params.id': {
      handler: async function () {
        if (this.id) {
          if (this.$feature('lucidChartIntegration')) {
            let result = await feathersClient.service('end-2-end').get(this.id);
            this.embedToken = await getDocumentEmbedToken(result);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    showPrint(item) {
      this.showPrintDialog = true;
      this.itemtoPrint = item;
    },

    clickAddIssue(item) {
      this.issueDialog = true;
      this.currentProcess = item;
      // console.log(item.id);
    },
    unGroup(remove) {
      this.group = null;
      if (this.end2End[0]) {
        window.localStorage.setItem('processGroupE2E', this.group);
      } else {
        window.localStorage.setItem('processGroup', this.group);
      }
      remove();
    },
    openTab(url) {
      window.open(url, '_blank');
    },
    groupBy(g) {
      this.group = g ? g : null;
      if (this.end2End[0]) {
        window.localStorage.setItem('processGroupE2E', this.group);
      } else {
        window.localStorage.setItem('processGroup', this.group);
      }
    },
    toggleAll() {
      Object.keys(this.$refs).forEach((k) => {
        //console.log(this.$refs[k])
        this.$refs[k].$el.click();
      });
    },
    clickEdit(item) {
      this.currentItem = this.process.find((f) => f.id == item.id);
      this.detailDialog = true;
    },

    clickAdd() {
      this.currentItem = null; //{};
      this.detailDialog = true;
    },
    clickAddProcessToE2E() {
      this.addProcessDialog = true;
    },

    unlinkProcessRequest(item) {
      this.unlinkProcess = item;
      this.showModal = true;
    },
    async deleteLink() {
      const { end2EndProcess } = this.$FeathersVuex.api;
      let result = await end2EndProcess.find({
        query: {
          end2EndId: this.end2endId,
          processId: this.unlinkProcess.id,
        },
      });
      if (result.data) {
        await this.$store.dispatch('end2End_process/remove', result.data[0].id);
        await end2EndProcess.find({ query: {} });
        // duplicat process name so I use this
        await this.$store.dispatch('process/find');
        // await process.find({ query: {} });
      }
      this.showModal = false;
      this.unlinkProcess = null;
    },
    clickDetail(clickedProcess) {
      this.$router.push({
        name: 'processDetail',
        params: { id: clickedProcess.id, number: clickedProcess.Number },
      });
      if (!this.end2End[0]) {
        this.setActiveEnd2End({});
      }
    },

    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },
    ...mapMutations(['setActiveEnd2End']),

    async LucidEmbed() {
      if (this.end2End[0] && this.end2End[0].ExternalDocumentId) {
        if (!this.end2End[0].EmbedId) {
          await getDocumentEmbed(this.end2End[0], true);
        }
        this.embedToken = await getDocumentEmbedToken(this.end2End[0]);
      }
    },
    ChangePhase(p) {
      this.selectedPhase = p;
    },
  },
  async mounted() {
    if (this.end2End[0]) {
      if (!window.localStorage.getItem('processGroupE2E')) {
        window.localStorage.setItem('processGroupE2E', null);
        this.group = null;
      } else {
        this.group =
          window.localStorage.getItem('processGroupE2E') == 'null'
            ? null
            : window.localStorage.getItem('processGroupE2E');
      }
    } else {
      if (!window.localStorage.getItem('processGroup')) {
        window.localStorage.setItem('processGroup', 'department.Name');
        this.group = 'department.Name';
      } else {
        this.group =
          window.localStorage.getItem('processGroup') == 'null'
            ? null
            : window.localStorage.getItem('processGroup');
      }
    }

    if (this.$feature('lucidChartIntegration')) {
      await this.LucidEmbed();
    }
    this.selectedPhase = window.localStorage.getItem('phase')
      ? parseInt(window.localStorage.getItem('phase'))
      : 'All';
  },
};
</script>

<style lang="scss" scoped>
.normalColorLink {
  color: #000000de !important;
}
</style>
