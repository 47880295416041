<template>
  <v-dialog v-model="importSteps" v-if="showMapper" max-width="1000px">
    <v-card flat :loading="isFindPriorityPending">
      <v-card-title>
        <span class="headline">Drawing Sync</span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <v-data-table
              :headers="headers"
              :items="mergedData"
              disable-pagination
              hide-default-footer
              :item-class="row_classes"
              v-model="importSelected"
              ><template v-slot:[`header.arrow`]="{ header }">
                <v-icon>mdi-arrow-right-bold</v-icon
                >{{ header.text }} </template
              ><template v-slot:[`item.Number`]="{ item, index }">
                {{ item.Number }} ({{ index + 1 }})
              </template>
              <template v-slot:[`item.idNew`]="{ item }">
                <v-icon v-if="item.idNew" color="green">mdi-check-bold</v-icon
                >{{ item.text }} </template
              ><template v-slot:[`item.idMatch`]="{ item }">
                <v-icon v-if="item.idMatch" color="green">mdi-check-bold</v-icon
                >{{ item.text }} </template
              ><template v-slot:[`item.nameMatch`]="{ item }">
                <v-icon v-if="item.nameMatch" color="green"
                  >mdi-check-bold</v-icon
                >{{ item.text }}
              </template>
              <template v-slot:[`item.Unlink`]="{ item }">
                <v-icon
                  v-if="item.nameMatch || item.idMatch"
                  color="primary"
                  small
                  @click="unLinkProcess(item)"
                  >mdi-link-off</v-icon
                >
              </template>
              <template v-slot:[`item.Name`]="{ item }">
                <div v-if="item.Name?.length > 0">{{ item.Name }}</div>
                <div v-else>
                  <v-select
                    v-model="item.processtoLink"
                    :items="process ? remainingVariants : remainingProcessSteps"
                    item-text="FullName"
                    item-value="id"
                    return-object
                    dense
                    hide-details
                    outlined
                    clearable
                    label="Select Variant"
                    @change="linkProcess(item)"
                  ></v-select>
                </div> </template
            ></v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="unLinkAll" :loading="unLinking"
          >Unlink all</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn :disabled="unLinking" outlined @click="cancelImport()"
          >Cancel</v-btn
        >
        <v-btn
          color="primary"
          :disabled="isFindPriorityPending || unLinking"
          :loading="saving"
          @click="saveImport()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import feathersClient from '@/feathers-client';
import { makeFindMixin } from 'feathers-vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

// import xml2js from 'xml2js';

export default {
  mixins: [makeFindMixin({ service: 'priority' })],
  props: {
    value: { type: Boolean },
    auto: { type: Boolean },
    drawingXML: {
      type: String,
      required: true,
    },
    drawingData: {
      type: Array,
      required: true,
    },
    process: {
      type: Object,
      required: false,
    },
    variant: {
      type: Object,
      required: false,
    },
  },

  computed: {
    importSteps: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    priorityParams() {
      return {};
    },
  },

  data() {
    return {
      mergedData: [],
      remainingVariants: [],
      remainingProcessSteps: [],
      showMapper: false,
      unLinking: false,
      saving: false,
      headers: [
        {
          text: 'Imported Drawing Text',
          value: 'label',
          sortable: true,
          groupable: false,
        },
        { value: 'arrow', sortable: false },
        {
          text: 'Loxi Text',
          value: 'Name',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Number',
          value: 'Number',
          sortable: true,
          groupable: false,
        },
        {
          text: 'New',
          value: 'idNew',
          align: 'center',
          sortable: true,
          groupable: false,
        },
        {
          text: 'ID Match',
          value: 'idMatch',
          align: 'center',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Name Match',
          value: 'nameMatch',
          align: 'center',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Unlink',
          value: 'Unlink',
          align: 'center',
          sortable: false,
          groupable: false,
        },
      ],
      importSelected: [],
    };
  },
  methods: {
    row_classes(item) {
      if (!item.idMatch || !item.nameMatch) {
        return 'highlight';
      }
    },
    cancelImport() {
      this.importSteps = false;
    },
    async saveImport() {
      this.saving = true;
      for (const [index, item] of this.mergedData.entries()) {
        if (this.process) {
          if (item.idNew) {
            try {
              await feathersClient.service('variant').create({
                Number: index + 1,
                Name: item.label,
                ExternalId: item.DrawingId,
                ApplicationId: 1,
                ProcessId: this.process.id,
              });
              handleSaveResponse(item.label, 'Variant', 'created');
            } catch (error) {
              handleErrorResponse(error);
            }
          } else if (!item.idMatch || !item.nameMatch) {
            try {
              await feathersClient.service('variant').patch(item.VariantId, {
                Name: item.label,
                ExternalId: item.DrawingId,
              });
              handleSaveResponse(item.label, 'Variant', 'updated');
            } catch (error) {
              handleErrorResponse(error);
            }
          }
        } else if (this.variant) {
          if (item.idNew) {
            try {
              await feathersClient.service('process-step').create({
                Number: index + 1,
                Name: item.label.trim().replace(/[\r\n]+/gm, ' '),
                ExternalId: item.DrawingId,
                VariantId: this.variant.id,
                StatusId: 1,
                PriorityId: item.PriorityId,
              });
              handleSaveResponse(item.label, 'Process Step', 'created');
            } catch (error) {
              handleErrorResponse(error);
            }
          } else if (!item.idMatch || !item.nameMatch) {
            try {
              await feathersClient.service('process-step').patch(item.StepId, {
                Name: item.label.trim().replace(/[\r\n]+/gm, ' '),
                ExternalId: item.DrawingId,
              });
              handleSaveResponse(item.label, 'Process Step', 'updated');
            } catch (error) {
              handleErrorResponse(error);
            }
          }
        }
      }
      this.importSteps = false;
      if (this.process) {
        const { Process } = this.$FeathersVuex.api;
        await Process.get(this.process.id);
      } else if (this.variant) {
        const { Variant } = this.$FeathersVuex.api;
        await Variant.get(this.variant.id);
      }
      this.saving = false;
    },
    async linkProcess(item) {
      if (item.processtoLink) {
        item.nameMatch = true;
        item.idNew = false;
        item.Name = item.FullName?.split(' ')[1];
        item.VariantId = item.processtoLink.id;
        item.StepId = item.processtoLink.id;
        item = { ...item, ...item.processtoLink };
      } else {
        item.idMatch = false;
        item.idNew = true;
      }
      // Remove from remaining

      if (this.variant) {
        this.remainingProcessSteps = this.remainingProcessSteps.filter(
          (f) => f.id !== item.processtoLink.id
        );
      } else if (this.process) {
        this.remainingVariants = this.remainingVariants.filter(
          (f) => f.id !== item.processtoLink.id
        );
      }
    },
    unLinkProcess(item) {
      item.nameMatch = false;
      item.idMatch = false;
      item.Name = '';
      // add back to remaining
      if (this.variant) {
        this.remainingProcessSteps.push(item);
      } else if (this.process) {
        this.remainingVariants.push(item);
      }
    },

    async unLinkAll() {
      this.unLinking = true;
      if (this.process) {
        for (const variant of this.process.variants) {
          await feathersClient.service('variant').patch(variant.id, {
            PageIndex: null,
            ExternalId: null,
          });
        }
        const { Process } = this.$FeathersVuex.api;
        await Process.get(this.process.id);
        await this.matcherVariants();
      } else if (this.variant) {
        for (const step of this.variant.process_steps) {
          await feathersClient.service('process-step').patch(step.id, {
            ExternalId: null,
          });
        }
        const { Variant } = this.$FeathersVuex.api;
        await Variant.get(this.variant.id);

        await this.matcherProcessSteps();
      }
      this.unLinking = false;
    },

    async matcherVariants() {
      const mergedData = [];
      this.remainingVariants = this.process.variants
        .sort((a, b) => a.Number - b.Number)
        .map((variant) => {
          return {
            ...variant, // Spread the existing properties of the variant
            FullName: `${variant.Number}. ${variant.Name}`, // Add the concatenated property
          };
        });

      // Add all drawData objects to mergedData
      this.drawingData.forEach((dd) => {
        if (dd.type == 'SubProcess') {
          // Try to find a matching loxiData object by comparing dd.id with loxiData.ExternalId
          const matchingLoxiId = this.remainingVariants.find(
            (ld) => ld.ExternalId === dd.id
          );
          const matchingLoxiName = this.remainingVariants.find(
            (ld) =>
              ld.Name.toLocaleLowerCase()
                .trim()
                .replace(/[\r\n]+/gm, ' ') ===
              dd.label
                .toLocaleLowerCase()
                .trim()
                .replace(/[\r\n]+/gm, ' ')
          );

          if (matchingLoxiId) {
            // Merge objects if match on Id is found
            mergedData.push({
              ...dd,
              ...matchingLoxiId,
              DrawingId: dd.id,
              VariantId: matchingLoxiId.id,
              idMatch: true,
              nameMatch: dd.label == matchingLoxiId.Name,
              FullName: `${matchingLoxiId?.Number}. ${matchingLoxiId?.Name}`,
            });
            this.remainingVariants = this.remainingVariants.filter(
              (f) => f.id != matchingLoxiId.id
            );
          } else if (matchingLoxiName) {
            // Merge objects if match on Name is found
            mergedData.push({
              ...dd,
              ...matchingLoxiName,
              DrawingId: dd.id,
              VariantId: matchingLoxiName.id,
              idMatch: false,
              nameMatch: dd.label == matchingLoxiName.Name,
              FullName: `${matchingLoxiName?.Number}. ${matchingLoxiName?.Name}`,
            });
            this.remainingVariants = this.remainingVariants.filter(
              (f) => f.id != matchingLoxiName.id
            );
          } else {
            // Add drawData object as is if no match is found
            mergedData.push({
              ...dd,
              DrawingId: dd.id,
              VariantId: null,
              idNew: true,
              idMatch: false,
              nameMatch: false,
            });
          }
          mergedData.sort((a, b) => {
            if (a.y !== b.y) {
              return a.y - b.y;
            }
            return a.x - b.x;
          });
        }
      });

      // Add remaining loxiData objects that do not have matches in drawData
      // this.processGet.variants.forEach((ld) => {
      //   const matchInMergedData = mergedData.find(
      //     (md) => md.ExternalId === ld.ExternalId && md.id === ld.ExternalId
      //   );
      //   if (!matchInMergedData) {
      //     mergedData.push({
      //       ...ld,
      //       idMatch: false,
      //       nameMatch: false,
      //       x: -99999,
      //       y: -99999,
      //     });
      //   }
      // });

      this.mergedData = mergedData.sort((a, b) => {
        if (a.y !== b.y) {
          return a.y - b.y;
        }
        return a.x - b.x;
      });

      // autosave if no new
      const hasNew = this.mergedData.some((item) => item.idNew === true);
      if (!hasNew && this.auto) {
        await this.saveImport();
        this.importSteps = false;
      } else this.showMapper = true;
    },
    async matcherProcessSteps() {
      const mergedData = [];

      this.remainingProcessSteps = this.variant.process_steps
        .sort((a, b) => a.Number - b.Number)
        .map((step) => {
          return {
            ...step, // Spread the existing properties of the variant
            FullName: `${step.Number}. ${step.Name}`, // Add the concatenated property
          };
        });

      // Add all drawData objects to mergedData
      this.drawingData.forEach((dd) => {
        if (dd.type == 'ProcessStep' || dd.type == 'ProcessManual') {
          // Try to find a matching loxiData object by comparing dd.id with loxiData.ExternalId
          const matchingLoxiId = this.remainingProcessSteps.find(
            (ld) => ld.ExternalId === dd.id
          );
          const matchingLoxiName = this.remainingProcessSteps.find(
            (ld) =>
              ld.Name.toLocaleLowerCase()
                .trim()
                .replace(/[\r\n]+/gm, ' ') ===
              dd.label
                .toLocaleLowerCase()
                .trim()
                .replace(/[\r\n]+/gm, ' ')
          );

          if (matchingLoxiId) {
            // Merge objects if match on Id is found
            mergedData.push({
              ...dd,
              ...matchingLoxiId,
              DrawingId: dd.id,
              StepId: matchingLoxiId.id,
              idMatch: true,
              nameMatch: dd.label == matchingLoxiId.Name,
              FullName: `${matchingLoxiId?.Number}. ${matchingLoxiId?.Name}`,
              // priorityId: dd.type == 'ProcessManual' ? 5 : 1,
            });
            this.remainingProcessSteps = this.remainingProcessSteps.filter(
              (f) => f.id != matchingLoxiId.id
            );
          } else if (matchingLoxiName) {
            // Merge objects if match on Name is found
            mergedData.push({
              ...dd,
              ...matchingLoxiName,
              DrawingId: dd.id,
              StepId: matchingLoxiName.id,
              idMatch: false,
              nameMatch: dd.label == matchingLoxiName.Name,
              FullName: `${matchingLoxiName?.Number}. ${matchingLoxiName?.Name}`,
              // priorityId: dd.type == 'ProcessManual' ? 5 : 1,
            });
            this.remainingProcessSteps = this.remainingProcessSteps.filter(
              (f) => f.id != matchingLoxiName.id
            );
          } else {
            // Add drawData object as is if no match is found
            mergedData.push({
              ...dd,
              DrawingId: dd.id,
              StepId: null,
              idNew: true,
              idMatch: false,
              nameMatch: false,
              PriorityId:
                dd.type == 'ProcessManual'
                  ? this.priority.find((f) => f.Name == 'Manual')?.id
                  : this.priority.find((f) => f.Name == 'Must')?.id,
            });
          }
          mergedData.sort((a, b) => {
            if (a.y !== b.y) {
              return a.y - b.y;
            }
            return a.x - b.x;
          });
        }
      });

      // Add remaining loxiData objects that do not have matches in drawData
      // this.processGet.variants.forEach((ld) => {
      //   const matchInMergedData = mergedData.find(
      //     (md) => md.ExternalId === ld.ExternalId && md.id === ld.ExternalId
      //   );
      //   if (!matchInMergedData) {
      //     mergedData.push({
      //       ...ld,
      //       idMatch: false,
      //       nameMatch: false,
      //       x: -99999,
      //       y: -99999,
      //     });
      //   }
      // });

      this.mergedData = mergedData.sort((a, b) => {
        if (a.y !== b.y) {
          return a.y - b.y;
        }
        return a.x - b.x;
      });

      // autosave if no new
      const hasNew = this.mergedData.some((item) => item.idNew === true);
      if (!hasNew && this.auto) {
        await this.saveImport();
        this.importSteps = false;
      } else this.showMapper = true;
    },
  },
  watch: {
    isFindPriorityPending: async function () {
      if (!this.isFindPriorityPending) {
        if (this.process) {
          await this.matcherVariants();
        } else if (this.variant) {
          await this.matcherProcessSteps();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.highlightFullMatch {
  background-color: red;
}
</style>
