<template>
  <div>
    <v-card class="pa-5">
      <v-card-title>
        <span v-if="variant">Groups on "{{ variant.Name }}"</span>
        <span v-if="process">Groups on "{{ process.Name }}"</span>
        <v-spacer></v-spacer
        ><v-icon
          v-if="$can('create', 'group')"
          class="ml-5"
          @click="clickAdd()"
          color="primary"
          large
        >
          mdi-plus-circle
        </v-icon></v-card-title
      >

      <v-row
        ><v-col cols="12" md="4"
          ><GroupTree
            :variant="variant"
            :process="process"
            :group="group"
            :key="key"
            @selectionChanged="NodeSelected" /></v-col
        ><v-col cols="12" md="8">
          <GroupStepsView v-if="variant" :key="key" :variant="variant.id" />
          <GroupVariantView
            v-if="process"
            :key="key"
            :process="process.id"
          /> </v-col
      ></v-row>
      <v-card-actions class="mt-5"
        ><v-spacer></v-spacer>
        <v-btn color="primary" @click="$emit('input', false)"
          >Close</v-btn
        ></v-card-actions
      >
    </v-card>
    <GroupEdit
      v-if="detailDialog"
      v-model="detailDialog"
      :currentItem="currentItem"
      :variant="variant?.id"
      :process="process?.id"
    />
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';

import GroupStepsView from './GroupStepsView.vue';
import GroupVariantView from './GroupVariantView.vue';
import GroupEdit from './GroupEdit.vue';

import GroupTree from './GroupTree.vue';

export default {
  mixins: [
    makeFindMixin({
      service: 'group',
    }),
  ],
  props: {
    variant: {
      type: Object,
      required: false,
    },
    process: {
      type: Object,
      required: false,
    },
    value: {},
  },
  components: { GroupStepsView, GroupVariantView, GroupTree, GroupEdit },
  data() {
    return {
      key: 0,
      currentItem: null,
      detailDialog: false,
      selectedGroup: null,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'Name',
        },
        {
          text: 'Color',
          align: 'start',
          sortable: false,
          value: 'Color',
        },
        {
          text: 'Loop',
          align: 'center',
          sortable: false,
          value: 'Loop',
        },
        { text: '', value: 'actions', sortable: false },
        { text: '', align: 'end', value: 'data-table-expand' },
      ],
    };
  },
  computed: {
    groupParams() {
      return {
        query: {
          $or: [
            { VariantId: this.variant?.id },
            { ProcessId: this.process?.id },
          ],
        },
      };
    },
  },
  methods: {
    clickAdd() {
      this.currentItem = null;
      this.detailDialog = true;
    },
    NodeSelected(value) {
      this.selectedGroup = value[0];
    },
  },
};
</script>

<style lang="scss" scoped></style>
