var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Modal',{attrs:{"title":"Link Process","busy":_vm.saving},on:{"confirm":_vm.saveLink,"cancel":function($event){_vm.showDialog = false}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"disabled":!(
              _vm.$can('update', _vm.issue, 'ProcessId') ||
              _vm.$can('create', 'external-issues')
            ),"items":_vm.processList,"item-text":"fullname","item-value":"id","label":"Process","outlined":"","clearable":"","dense":"","hide-details":"true"},on:{"change":function($event){_vm.selected.VariantId = null;
            _vm.selected.ProcessStepId = null;}},model:{value:(_vm.selected['ProcessId']),callback:function ($$v) {_vm.$set(_vm.selected, 'ProcessId', $$v)},expression:"selected['ProcessId']"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"disabled":!(
              _vm.$can('update', _vm.issue, 'VariantId') ||
              _vm.$can('create', 'external-issues')
            ),"items":_vm.variantList,"item-text":"fullname","item-value":"id","label":"Variant","outlined":"","clearable":"","dense":"","hide-details":"true"},on:{"change":function($event){_vm.selected.ProcessStepId = null}},model:{value:(_vm.selected['VariantId']),callback:function ($$v) {_vm.$set(_vm.selected, 'VariantId', $$v)},expression:"selected['VariantId']"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"disabled":!(
              _vm.$can('update', _vm.issue, 'ProcessStepId') ||
              _vm.$can('create', 'external-issues')
            ),"items":_vm.processStepList,"item-text":"fullname","item-value":"id","label":"Process Step","outlined":"","clearable":"","dense":"","hide-details":"true"},model:{value:(_vm.selected['ProcessStepId']),callback:function ($$v) {_vm.$set(_vm.selected, 'ProcessStepId', $$v)},expression:"selected['ProcessStepId']"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }