<template>
  <div>
    <v-card v-if="statusOverviewLatestQuery" class="text-center"
      ><v-card-title class="text-center"
        >Status {{ type }} - Phase {{ selectedPhase }}
      </v-card-title>

      <v-card-text align="justify-center">
        <DoughnutChart
          v-if="chartData?.labels"
          :data="chartData"
          :options="chartOptions"
          @click="onSegmentClick"
        />

        <span
          v-for="stat in statusOverviewLatestQuery?.response.filter(
            (f) => f.Type == type
          )"
          :key="stat.id"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                @click="showList(stat)"
                class="ma-2"
                x-small
                :color="stat.Color"
                :text-color="applyDark(stat.Color) ? 'white' : 'black'"
              >
                <b class="mr-3"> {{ stat.Count }}</b>
                {{ !current ? stat.Status : stat.NextStatusToPlan }}
                <b class="mr-3 ml-2">
                  - {{ (stat.Procent * 100).toFixed(0) }}%
                </b>
              </v-chip>
            </template>
            {{ stat.Status }}
            <v-icon color="white">mdi-arrow-right-bold</v-icon>
            {{ stat.NextStatusToPlan }}
          </v-tooltip>
        </span>
      </v-card-text></v-card
    >
    <v-dialog v-model="showDialog" width="1200">
      <ItemsPerStatus
        v-model="showDialog"
        :status="selectedStatus"
        :phase="selectedPhase"
        :type="type"
    /></v-dialog>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { applyDark } from '@/utils/Utilities';
import ItemsPerStatus from '@/components/ItemsPerStatus';

import { Doughnut } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement);

export default {
  mixins: [
    makeFindMixin({
      service: 'status-overview',
      watch: ['selectedPhase', 'type'],
    }),
  ],
  components: { ItemsPerStatus, DoughnutChart: Doughnut },
  props: {
    selectedPhase: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    current: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      selectedStatus: null,
    };
  },
  methods: {
    applyDark,
    showList(stat) {
      this.selectedStatus = stat;
      this.showDialog = true;
    },
    onSegmentClick(event, elements) {
      this.selectedStatus = this.statusOverviewLatestQuery?.response.filter(
        (f) => f.Type == this.type
      )[elements[0].index];
      this.showDialog = true;
    },
  },
  computed: {
    statusOverviewParams() {
      return this.phaseQuery;
    },
    chartData() {
      if (this.statusOverviewLatestQuery?.response?.length > 0) {
        const labels = this.statusOverviewLatestQuery?.response
          .filter((f) => f.Type == this.type)
          .map((item) => (!this.current ? item.Status : item.NextStatusToPlan));
        const counts = this.statusOverviewLatestQuery?.response
          .filter((f) => f.Type == this.type)
          .map((item) => Number(item.Count));
        const colors = this.statusOverviewLatestQuery?.response
          .filter((f) => f.Type == this.type)
          .map((item) => item.Color);

        return {
          labels,
          datasets: [
            {
              label: 'Counts by Status',
              data: counts,
              backgroundColor: colors,
              Color: '#FF0000',
            },
          ],
        };
      } else {
        return null;
      }
    },
    phaseQuery() {
      if (this.selectedPhase && this.selectedPhase != 'All') {
        return {
          query: {
            statPerType: true,
            phase: this.selectedPhase,
          },
        };
      } else {
        return {
          query: {
            statPerType: true,
          },
        };
      }
    },
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Status Counts',
          },
        },
        onClick: this.onSegmentClick,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
