<template>
  <div>
    <v-overlay :value="selectPending">
      <v-progress-circular
        indeterminate
        size="64"
        color="#000000"
      ></v-progress-circular>
    </v-overlay>
    <v-switch
      v-model="showAll"
      label="Show All"
      hide-details
      class="mb-5 ml-4"
    ></v-switch>
    <v-data-table
      v-if="filteredStepsOnSelection"
      :headers="headers"
      :items="filteredStepsOnSelection"
      :items-per-page="20"
      item-key="Number"
      class="elevation-1"
      disable-pagination
      show-select
      show-expand
      single-expand
      @item-selected="clickedRow"
      @toggle-select-all="clickedAll"
      v-model="selected"
      dense
      :hide-default-footer="true"
      :loading="isPending || selectPending"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`item.color`]="{ item }">
        <span
          v-for="color in group.find((f) => f.id == item.GroupId)?.allGroups"
          :key="color.id"
          ><span v-if="color.Loop">
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="circle pointer"
                  @click="addLoop(item, color)"
                  :style="{
                    background: color.Color,
                    color: applyDark(color.Color) ? 'white' : 'black',
                  }"
                >
                  {{
                    item.Groups.find((f) => f.GroupId == color.id)
                      ?.GroupIteration + 1
                  }}</span
                ></template
              >{{ color.Name }}</v-tooltip
            ></span
          ><span v-else>
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="circle"
                  :style="{
                    background: color.Color,
                    color: applyDark(color.Color) ? 'white' : 'black',
                  }"
                >
                  &nbsp;</span
                ></template
              >{{ color.Name }}</v-tooltip
            >
          </span>
        </span>
      </template>
      <template v-slot:[`item.Fields`]="{ item }">
        <TestScenarioStepFields
          v-if="item.id"
          :testScenarioLineStep="item"
          :disabled="!item.id"
        />
      </template>
      <template v-slot:[`item.TestRemark`]="{ item }">
        <div class="testRemark" @click="clickEditTestRemark(item)">
          <span v-html="truncateHTML(item.TestRemark, 40)" />
          <v-icon
            class="icon-top-right"
            small
            :disabled="!item.id"
            @click="clickEditTestRemark(item)"
            >mdi-pencil</v-icon
          >
        </div>
      </template>
      <template v-slot:[`item.Reference`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              :color="item.ReferenceLineId ? 'green' : ''"
              @click="clickAddReference(item)"
              >mdi-vector-point-plus</v-icon
            >
          </template>
          {{
            item.ReferenceLineId
              ? lineReferences.find((f) => f.id == item.ReferenceLineId)?.value
              : 'No Output Linked'
          }}
        </v-tooltip>
      </template>
      <template v-slot:[`item.status.Testable`]="{ item }">
        <v-icon v-if="item.Testable" color="green">mdi-check</v-icon>
        <v-icon v-else color="red">mdi-close</v-icon>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length" v-if="item?.Description">
          <v-row
            ><v-col class="mb-0 pb-0"
              ><span
                class="ma-3 ql-editor"
                v-html="item?.Description"
              ></span></v-col
          ></v-row>
        </td>
      </template>
    </v-data-table>
    <Modal
      title="Add Output Reference"
      v-if="ShowAddReference"
      @cancel="ShowAddReference = false"
      @confirm="saveLineStep(currentItem)"
    >
      <v-select
        v-model="currentItem.ReferenceLineId"
        clearable
        :items="lineReferences"
        item-text="value"
        item-value="id"
        label="Output Reference"
        outlined
        dense
        hide-details
      ></v-select>
    </Modal>
    <Modal
      title="Edit test Remark"
      width="1100px"
      v-if="showTestRemarkEditor"
      @cancel="showTestRemarkEditor = false"
      @confirm="
        saveLineStep(currentItem);
        showTestRemarkEditor = false;
      "
    >
      <RichEditor
        v-model="currentItem.TestRemark"
        :disabled="!$can('update', currentItem, 'TestRemark')"
      ></RichEditor>
    </Modal>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import { truncateHTML, applyDark } from '@/utils/Utilities';
import TestScenarioStepFields from '@/components/TestScenarios/TestScenario/TestScenarioStepFields.vue';
import Modal from '@/components/general/Modal.vue';
import RichEditor from '@/components/general/RichEditor.vue';
import { handleErrorResponse } from '@/utils/MessageHandler';

export default {
  props: {
    variant: {
      type: Object,
      required: true,
    },
    stepsToAdd: {
      type: Array,
      required: false,
    },
    testScenarioLine: {
      type: Object,
      required: true,
    },
    testLines: {
      type: Array,
      required: true,
    },
    // addModus: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  components: { TestScenarioStepFields, Modal, RichEditor },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-line-step',
    }),
    makeFindMixin({
      service: 'process-step',
      watch: ['variant.id'],
    }),
    makeFindMixin({
      service: 'group',
      watch: ['variant.id'],
    }),
  ],
  watch: {
    // processStep() {
    //   this.updateFilteredProcesses();
    //   // this.getLineSteps();
    // },
    // showAll() {
    //   this.updateFilteredProcesses();
    //   // this.getLineSteps();
    // },
    isPending: function () {
      // this.initialize();
      if (this.isPending == false) {
        this.getLineSteps();
      }
    },
    // 'testScenarioLine.id': function () {
    //   // this.initialize();
    //   this.getLineSteps();
    // },
  },
  data() {
    return {
      showAll: false,
      lineSteps: [],
      selected: [],
      currentItem: null,
      showTestRemarkEditor: false,
      selectPending: false,
      ShowAddReference: false,
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'color',
          width: '100px',
        },
        {
          text: 'Number',
          align: 'start',
          sortable: false,
          value: 'ProcessNumber',
          width: '50px',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'Name',
        },
        {
          text: 'Fields',
          align: 'start',
          sortable: false,
          value: 'Fields',
          width: '250px',
        },
        {
          text: 'Test Remark',
          align: 'start',
          sortable: false,
          value: 'TestRemark',
        },
        {
          text: 'Reference',
          align: 'start',
          sortable: false,
          value: 'Reference',
        },
        {
          text: 'Testable',
          align: 'center',
          sortable: false,
          value: 'status.Testable',
        },
        {
          text: '',
          align: 'end',
          value: 'data-table-expand',
          sortable: false,
          groupable: false,
        },
      ],
    };
  },
  computed: {
    isPending() {
      return (
        this.isFindProcessStepPending || this.isFindTestScenarioLineStepPending
      );
    },
    testScenarioLineStepParams() {
      if (this.testScenarioLine.id) {
        return { query: { TestScenarioLineId: this.testScenarioLine.id } };
      } else return { query: { id: -1 } };
    },
    processStepParams() {
      return { query: { VariantId: this.variant.id } };
    },
    groupParams() {
      return { query: { VariantId: this.variant.id } };
    },
    filteredStepsOnSelection() {
      if (this.showAll) {
        return this.lineSteps;
      } else {
        return this.lineSteps.filter((f) => f.Selected == true);
      }
    },
    lineReferences() {
      return this.testLines
        .filter(
          (f) =>
            f.ReferenceName?.length > 0 && f.Order < this.testScenarioLine.Order
        )
        ?.map((m) => {
          return { id: m.id, value: `${m.ReferenceName} (${m.Name})` };
        });
    },
    groupSummary() {
      const flattengroups = [];
      for (const step of this.testScenarioLineStep) {
        if (step.Groups?.length > 0) {
          flattengroups.push([...step.Groups]);
        }
      }
      let groupMaxIterations = {};

      // Step 4: Iterate over the combined data
      flattengroups.flat().forEach((group) => {
        let { GroupId, GroupIteration } = group;

        // If GroupId is already in the object, compare the iterations
        if (groupMaxIterations[GroupId] !== undefined) {
          groupMaxIterations[GroupId] = Math.max(
            groupMaxIterations[GroupId],
            GroupIteration
          );
        } else {
          // If GroupId is not in the object, set the current iteration
          groupMaxIterations[GroupId] = GroupIteration;
        }
      });

      let result = [];
      for (let [groupId, maxIteration] of Object.entries(groupMaxIterations)) {
        result.push({
          GroupId: groupId,
          FirstStep: this.testScenarioLineStep
            .map((item) => {
              return {
                ...item,
                Groups2: item.Groups.filter(
                  (group) => group.GroupId == groupId
                ),
              };
            })
            .sort((a, b) => a.process_step?.Number - b.process_step?.Number)
            .find((item) => item.Groups2?.length > 0)?.process_step?.Number,
          Iteration: maxIteration,
          GroupLevel: this.testScenarioLineStep
            .map((item) => {
              return {
                level: item.Groups.findIndex(
                  (group) => group.GroupId == groupId
                ),
              };
            })
            .sort((a, b) => b.level - a.level)[0]?.level,
        });
      }
      return result;
    },
    // filteredProcesses() {
    //   if (!this.isPending) {
    //     this.updateFilteredProcesses();
    //   }
    //   return this.lastFilteredProcesses;
    // },
  },
  methods: {
    truncateHTML,
    applyDark,
    copyStepsWithUniqueNumbers(steps) {
      // const result = [];
      // steps.forEach((step) => {
      //   const iterations = this.maxIterationperGroup(step.GroupId);

      //   // Create copies for each iteration
      //   for (let i = 0; i <= iterations; i++) {
      //     const copiedStep = {
      //       ...step,
      //       GroupIteration: i,
      //     };
      //     result.push(copiedStep);
      //   }
      // });

      let result = [];
      steps.forEach((step) => {
        const stepGroup = this.group.find((f) => f.id == step.GroupId);
        if (stepGroup) {
          for (const group of stepGroup.allGroups) {
            for (
              let iteration = 0;
              iteration <= this.maxIterationperGroup(group.id);
              iteration++
            ) {
              let stepGroups = [];
              for (const group of stepGroup.allGroups) {
                stepGroups.push({
                  GroupId: group.id,
                  GroupIteration: iteration,
                });
              }
              result.push({ ...step, Groups: stepGroups });
            }
          }
        } else {
          result.push({ ...step, Groups: [] });
        }
      });

      return result;
    },
    async getLineSteps() {
      const updatedTestScenarioLineSteps = this.testScenarioLineStep.map(
        (step) => {
          let processStep = this.processStep.find(
            (f) => f.id == step.ProcessStepId
          );
          return {
            ...step,
            Selected: true,
            ProcessNumber: processStep?.Number,
            Name: processStep?.Name,
            Description: processStep?.Description,
            // GroupId: processStep?.GroupId,
            // group: processStep?.group,
            Testable: processStep?.status?.Testable,
            Level: step.Groups?.length,
          };
        }
      );

      // // add Missing processSteps Explode steps that have  multiple iterations
      // const updatedProcessSteps = this.copyStepsWithUniqueNumbers(
      //   this.processStep
      // );

      const usedProcessStepKeys = this.testScenarioLineStep
        .map((step) => {
          if (step.Groups?.length > 0) {
            return `${step.ProcessStepId}-${step.Groups.slice(-1)[0]?.GroupId}`;
          } else return `${step.ProcessStepId}-0`;
        })
        .flat()
        .filter((value) => value !== null);

      // const usedProcessStep = this.testScenarioLineStep.map((step) => {
      //   return {
      //     ProcessStepId: step.ProcessStepId,
      //     GroupId:
      //       step.Groups?.length > 0 ? step.Groups?.slice(-1)[0]?.GroupId : null,
      //     GroupIteration:
      //       step.Groups?.length > 0
      //         ? step.Groups?.slice(-1)[0]?.GroupIteration
      //         : null,
      //   };
      // });

      // for (const step of usedProcessStep) {
      //   if (step.GroupId != null) {
      //     // lookup missing steps
      //     // wich processteps belonmg to the same group
      //     const stepsForGroup = this.processStep.filter(
      //       (f) => f.GroupId == step.GroupId
      //     );
      //     console.log(
      //       stepsForGroup.forEach((f) => {
      //         console.log(f.GroupId, f.id);
      //       })
      //     );
      //     // Do all the steps exist for that iteration?
      //     const missingSteps = stepsForGroup.forEach((f) => {
      //       return usedProcessStep
      //         .map((m) => `${m.ProcessStepId}-${m.GroupIteration}`)
      //         .includes(`${f.id}-${step.GroupIteration}`);
      //     });
      //     console.log('m', missingSteps);
      //   }
      // }

      // console.log('u2', usedProcessStepKeys, usedProcessStep);

      // filter out all steps not found in test scenario line step. Unique key is ProcessStepId / GroupId / GroupIteration
      const unusedProcessSteps = this.processStep.filter((processStep) => {
        const currentKey = `${processStep.id}-${
          processStep.GroupId ? processStep.GroupId : 0
        }`;
        return !usedProcessStepKeys.includes(currentKey);
      });

      for (const step of unusedProcessSteps) {
        const groups = [];

        const allGroups = this.group.find((f) => f.id == step.GroupId);
        if (allGroups?.allGroups?.length > 0) {
          for (const group of allGroups.allGroups) {
            groups.push({ GroupId: group.id, GroupIteration: 0 });
          }
        }
        const { TestScenarioLineStep } = this.$FeathersVuex.api;
        let newStep = new TestScenarioLineStep();
        newStep.TestScenarioLineId = this.testScenarioLine.id;
        newStep.ProcessStepId = step.id;
        newStep.Selected = false;
        newStep.Name = step.Name;
        newStep.Description = step.Description;
        newStep.GroupId = step.GroupId;
        newStep.group = step.group;
        newStep.Testable = step.status?.Testable;
        newStep.ProcessNumber = step?.Number;
        newStep.GroupIteration = step.GroupIteration;
        newStep.Groups = groups;
        newStep.Level = 1;
        updatedTestScenarioLineSteps.push(newStep);
      }

      // Add NewGroupIteration for sorting, it contains the processnumber if the groupid is null otherwise it will be the first processnumber of the group + the iteration
      // We multiply by 100 so at least 100 steps can be added by looping
      // This is needed for correct sorting, now we can order by the newgroupiteration and then processnumber

      for (const step of updatedTestScenarioLineSteps) {
        let count = 0;
        let base = 1000;
        for (let index = 0; index < this.groupSummary?.length; index++) {
          const curGroup = this.groupSummary[index];
          const stepGroupIndex = step.Groups.findIndex(
            (f) => f.GroupId == curGroup.GroupId
          );
          const stepGroup = step.Groups[stepGroupIndex];
          // is it the active group for this step

          if (stepGroupIndex == curGroup.GroupLevel) {
            count =
              count +
              ((stepGroup.GroupIteration *
                Math.pow(
                  base,
                  this.groupSummary?.length - curGroup.GroupLevel
                )) /
                100 +
                curGroup.FirstStep *
                  Math.pow(
                    base,
                    this.groupSummary?.length - curGroup.GroupLevel
                  ));
          } else {
            count =
              count +
              step.ProcessNumber *
                Math.pow(base, this.groupSummary?.length - curGroup.GroupLevel);
          }
        }

        step.NewGroupIteration = count;
      }

      const reorderedData = updatedTestScenarioLineSteps.sort((a, b) => {
        if (a.NewGroupIteration === b.NewGroupIteration) {
          return a.ProcessNumber - b.ProcessNumber;
        }
        return a.NewGroupIteration - b.NewGroupIteration;
      });

      // Update Number according to current order

      for (const [index, line] of reorderedData
        .filter((f) => f.Selected == true)
        .entries()) {
        line.Number = index + 1;
      }

      // Save new Numbers (only for selected)
      await this.renumber(reorderedData.filter((f) => f.Selected == true));

      this.lineSteps = reorderedData;
      const selectedSteps = this.lineSteps.filter((f) => f.Selected == true);

      this.selected = selectedSteps;
    },
    clickEditTestRemark(item) {
      if (item.id) {
        this.currentItem = item;
        this.showTestRemarkEditor = true;
      }
    },
    clickAddReference(item) {
      if (item.id) {
        this.currentItem = item;
        this.ShowAddReference = true;
      }
    },

    async addLoop(item, group) {
      const { TestScenarioLineStep } = this.$FeathersVuex.api;
      let maxIteration = this.maxIterationPerLine(item, group.id); //this.maxIterationperGroup(item.GroupId);

      let stepsToAdd = this.processStep
        .map((step) => {
          return {
            ...step,
            dummy: step.GroupId,
            AllGroups:
              this.group.find((f) => f.id == step.GroupId)?.allGroups || [],
            Groups: this.group
              .find((f) => f.id == step.GroupId)
              ?.allGroups.filter((f) => f.id == group.id),
          };
        })
        .filter((step) => step.Groups?.length > 0 && step.Mandatory == true);

      for (const loopStep of stepsToAdd) {
        let newStep = new TestScenarioLineStep();
        //Object.assign(newStep, loopStep);
        // newStep.GroupIteration = maxIteration + 1;
        newStep.TestScenarioLineId = item.TestScenarioLineId;
        newStep.ProcessStepId = loopStep.id;
        newStep.Selected = true;
        newStep.Name = loopStep.Name;
        newStep.Description = loopStep.Description;
        newStep.GroupId = loopStep.GroupId;
        newStep.group = loopStep.group;
        newStep.Testable = loopStep.status?.Testable;
        newStep.ProcessNumber = loopStep?.Number;
        newStep.Groups = loopStep.AllGroups.map((m) => {
          const currentIteration = item.Groups?.find(
            (f) => f.GroupId == m.id
          )?.GroupIteration;

          return {
            GroupId: m.id,
            GroupIteration:
              currentIteration != null
                ? m.id == item.GroupId
                  ? maxIteration + 1
                  : currentIteration
                : 0,
          };
        });

        try {
          await newStep.save();
          // await TestScenarioLineStep.find();
        } catch (error) {
          handleErrorResponse(error);
        }
      }

      // Refresh and recalculate
      await TestScenarioLineStep.find({
        query: { TestScenarioLineId: this.testScenarioLine.id },
      });
      this.getLineSteps();
    },
    maxIterationperGroup(groupId) {
      const allgroups = [];
      for (const step of this.testScenarioLineStep) {
        allgroups.push(step.Groups);
      }
      return allgroups
        .flat()
        .filter((f) => f.GroupId == groupId)
        .reduce((max, obj) => Math.max(max, obj.GroupIteration), 0);
    },
    maxIterationPerLine(line, groupId) {
      // Helper function to generate group keys for comparison
      const generateGroupKey = (groups, activeGroupId, groupId) => {
        const relevantGroups = [];

        for (const group of groups) {
          relevantGroups.push(group);
          if (group.GroupId === groupId) {
            break;
          }
        }

        console.log(groups, relevantGroups);
        return relevantGroups
          .map((group) =>
            group.GroupId == activeGroupId
              ? `${group.GroupId}-0` // Set iteration to 0 for the active group
              : `${group.GroupId}-${group.GroupIteration}`
          )
          .join('/');
      };

      console.log(generateGroupKey);

      // Identify the active group
      const activeLineGroupId = line.Groups[line.Groups.length - 1].GroupId;
      // Generate the group key for the current line
      const groupKeyLine = generateGroupKey(
        line.Groups,
        activeLineGroupId,
        groupId
      );
      // Create group keys for all test scenario line steps
      const allLinesWithSameGroupCombination = this.testScenarioLineStep.filter(
        (step) =>
          generateGroupKey(step.Groups, activeLineGroupId, groupId) ===
          groupKeyLine
      );
      // Extract group iterations from filtered lines
      const groupIterations = allLinesWithSameGroupCombination.map(
        (step) => step.Groups[line.Groups.length - 1].GroupIteration
      );
      // Return the maximum group iteration, handle the case where no iterations exist
      return groupIterations.length > 0 ? Math.max(...groupIterations) : 0; // Or return null or another default value if preferred
    },

    async saveLineStep(item) {
      try {
        await feathersClient.service('test-scenario-line-step').patch(item.id, {
          TestRemark: item.TestRemark,
          ReferenceLineId: item.ReferenceLineId,
        });
      } catch (error) {
        handleErrorResponse(error);
      }
      this.ShowAddReference = false;
    },
    async renumber(orderedList) {
      // Renumber
      for (const step of this.testScenarioLineStep
        // .sort((a, b) => a.process_step.Number - b.process_step.Number)
        .filter((f) => f.TestScenarioLineId == this.testScenarioLine.id)) {
        step.Number = orderedList?.find((f) => f.id == step.id)?.Number ?? 9999;

        try {
          await step.save();
        } catch (error) {
          handleErrorResponse(error);
        }
      }
    },
    async clickedRow(value, bulk = false) {
      this.saving = true;

      if (this.testScenarioLine.id) {
        if (value.value) {
          // Add line to group step
          try {
            const groups = [];

            const allGroups = this.group.find(
              (f) => f.id == value.item.GroupId
            );
            if (allGroups?.allGroups?.length > 0) {
              for (const group of allGroups.allGroups) {
                groups.push({ GroupId: group.id, GroupIteration: 0 });
              }
            }
            const saved = await feathersClient
              .service('test-scenario-line-step')
              .create({
                TestScenarioLineId: this.testScenarioLine.id,
                ProcessStepId: value.item.ProcessStepId,
                TestRemark: value.item.TestRemark,
                GroupId: value.item.GroupId,
                Groups: groups,
              });
            if (!bulk) {
              const { TestScenarioLineStep } = this.$FeathersVuex.api;
              await TestScenarioLineStep.find({ query: { id: saved.id } });
            }
          } catch (error) {
            handleErrorResponse(error);
          }
        } else {
          let Steps = this.testScenarioLineStep.find(
            (f) => f.id == value.item.id
          );
          value.item.TestScenarioLineStepLinked = false;
          value.item.TestScenarioLineStepId = null;
          if (Steps) {
            try {
              await feathersClient
                .service('test-scenario-line-step')
                .remove(Steps.id);
            } catch (error) {
              handleErrorResponse(error);
            }
          }
        }

        this.saving = false;
      }
      if (!bulk) {
        await this.getLineSteps();
      }
    },
    async clickedAll(value) {
      this.selectPending = true;
      for (const v of value.items) {
        let item = {};
        item.item = v;
        item.value = value.value;
        await this.clickedRow(item, true);
      }

      const { TestScenarioLineStep } = this.$FeathersVuex.api;
      await TestScenarioLineStep.find();

      await this.getLineSteps();
      this.selectPending = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.testRemark {
  width: 200px;
  border: 1px solid lightgrey;
  padding: 5px;
  margin: 5px;
  min-height: 40px;
  position: relative;
}
.testRemark .icon-top-right {
  position: absolute;
  right: 0px; /* Adjust the value to fine-tune the position */
  top: 50%; /* Start at the middle of the box */
  transform: translateY(-50%); /* Adjust to perfectly center vertically */
  border-radius: 50%; /* Optional: Makes the icon background circular */
  padding: 5px; /* Optional: Adds some space around the icon */
}
span.circle {
  // background: red;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 2px;
  text-align: center;
  width: 1.6em;
}

span.pointer {
  cursor: pointer;
}
</style>
