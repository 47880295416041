<template>
  <v-container>
    <div v-if="process" class="caption">
      <router-link
        v-if="$store.state.activeEnd2End.Name"
        :to="{
          name: 'Process',
          params: { id: $store.state.activeEnd2End.id },
        }"
        >E2E{{ $store.state.activeEnd2End.Number }} -
        {{ $store.state.activeEnd2End.Name }}</router-link
      ><v-icon v-if="$store.state.activeEnd2End.Name" small
        >mdi-chevron-right</v-icon
      >{{ process.department.Name
      }}<v-menu open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small
            >mdi-chevron-double-right</v-icon
          >
        </template>

        <v-list dense>
          <v-list-item
            v-for="process in fullProcessList.filter(
              (f) => f.DepartmentId == this.process.DepartmentId
            )"
            :key="process.id"
          >
            <v-list-item-title
              ><router-link
                :to="{
                  name: 'processDetail',
                  params: { id: process.id, number: process.Number },
                }"
                >{{ process.Number }}. {{ process.Name }}</router-link
              ></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <router-link
        :to="{
          name: 'processDetail',
          params: { id: process.id, number: process.Number },
        }"
        ><b
          >{{ $appConfig.customer.prefix
          }}{{ process.Number.toString().padStart(4, '0') }} -
          {{ process.Name }}</b
        ></router-link
      >

      <v-menu open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small
            >mdi-chevron-double-right</v-icon
          >
        </template>

        <v-list dense>
          <v-list-item v-for="variant in sortedVariants" :key="variant.id">
            <v-list-item-title
              ><router-link
                :to="{ name: 'processStep', params: { id: variant.id } }"
                >{{ variant.Number }}. {{ variant.Name }}</router-link
              ></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <FeathersVuexGet
      v-slot="{ item: process }"
      service="process"
      :id="id"
      :watch="['id']"
      ><div v-if="process">
        <h1>
          {{ process.Name }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="$can('update', 'process')"
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="clickEdit(item)"
                color="primary"
              >
                mdi-pencil
              </v-icon></template
            >Edit Process</v-tooltip
          >

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="$can('update', 'variant')"
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="updateStatus()"
                color="primary"
              >
                mdi-update
              </v-icon>
            </template>
            <span>Mass update status on all process variants</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                @click="showMoveDialog = true"
                >mdi-arrow-top-right-thin-circle-outline</v-icon
              >
            </template>
            <span>Move Variants</span>
          </v-tooltip>
        </h1>

        <v-row class="ma-0 pa-0"
          ><v-card flat v-if="process.end_2_ends?.length > 0" class="mx-5"
            ><v-card-title class="text-subtitle-1 pb-1 pl-0 pt-0"
              >End 2 Ends</v-card-title
            >
            <ul>
              <li
                class="text-body-2 ma-0 pa-0"
                v-for="(e, index) in process.end_2_ends
                  .slice()
                  .sort((a, b) => a.Number - b.Number)"
                :key="index"
              >
                {{ e.Number }}. <a :href="'/#/process/' + e.id">{{ e.Name }}</a>
              </li>
            </ul></v-card
          ></v-row
        >

        <v-row class="ma-0 pa-0"
          ><v-card flat v-if="process.Input" class="mx-5"
            ><v-card-title class="text-subtitle-1 pb-1 pl-0 pt-0"
              >Input</v-card-title
            >
            <span
              v-html="process.Input"
              class="text-body-2 ma-0 pa-0 ql-editor"
            ></span></v-card
        ></v-row>
        <v-row class="ma-0 pa-0"
          ><v-card flat v-if="process.Output" class="mx-5"
            ><v-card-title class="text-subtitle-1 pb-1 pl-0"
              >Output</v-card-title
            >
            <span
              v-html="process.Output"
              class="text-body-2 ma-0 pa-0 ql-editor"
            ></span></v-card
        ></v-row>
        <div v-if="$feature('drawIOChartIntegration')">
          <v-row>
            <v-col class="mt-5 mb-10"><DrawIO :processId="process.id" /></v-col
          ></v-row>
        </div>
        <v-row>
          <v-col class="ma-4">
            <v-row class="mb-5" justify="center">
              <v-skeleton-loader
                v-if="
                  process.ExternalDocumentId &&
                  !embedToken &&
                  $feature('lucidChartIntegration')
                "
                min-width="1000px"
                min-height="520px"
                type="image button@2"
                boilerplate
              ></v-skeleton-loader>
              <iframe
                frameBorder="0"
                v-if="embedToken"
                width="1000px"
                height="500px"
                :src="LucidUrl"
              ></iframe>
              <v-carousel
                v-if="
                  $feature('cloudinaryChartIntegration') &&
                  images.length > 0 &&
                  !embedToken &&
                  process.nrOfDrawings == 0
                "
                :height="images.length > 1 ? '600px' : '500px'"
                width="100%"
                :hide-delimiters="images.length > 1 ? false : true"
                :show-arrows="images.length > 1 ? true : false"
              >
                <v-row justify="center">
                  <v-col cols="12" md="6">
                    <v-carousel-item
                      class="mb-15"
                      v-for="(item, i) in images"
                      :key="i"
                      :src="item"
                      contain
                      max-height="500"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="openWindow(item)"
                    ></v-carousel-item>
                  </v-col>
                </v-row>
              </v-carousel> </v-row
            ><v-row
              ><v-spacer></v-spacer>
              <span v-if="embedToken">
                <v-icon @click="openWindow(LucidUrl)">
                  mdi-image-search-outline
                </v-icon>

                <v-icon
                  v-if="$can('update', 'variant')"
                  class="ml-5"
                  @click="getSteps(process)"
                >
                  mdi-table-sync
                </v-icon></span
              >
            </v-row></v-col
          >
        </v-row>
        <v-row
          ><v-card
            v-if="sharePointUrl || $appConfig.externalIssues"
            class="mx-auto"
            width="50%"
            tile
          >
            <v-list>
              <v-list-item-group color="primary">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon @click="issueDialog = true">
                      mdi-login-variant
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      ><StatusListIndividual
                        v-if="
                          process.StatusSummaryIssues &&
                          process.StatusSummaryIssues.length > 0
                        "
                        :statusList="process.StatusSummaryIssues"
                    /></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-file-multiple-outline </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      ><span
                        ><a
                          v-if="sharePointUrl"
                          target="_blank"
                          :href="sharePointUrl"
                          >Documents</a
                        ></span
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-row>
        <v-row>
          <v-btn
            v-if="$can('create', 'variant')"
            elevation="0"
            class="ma-8"
            fab
            small
            color="primary"
            @click="clickAdd()"
          >
            <v-icon dark> mdi-plus </v-icon> </v-btn
          ><v-spacer></v-spacer>
          <v-icon @click="clickAddGroup">mdi-format-list-group-plus</v-icon>
          <v-icon v-if="!listview">mdi-card-text</v-icon
          ><v-icon @click="listview = !listview" v-if="listview"
            >mdi-card-text-outline</v-icon
          ><v-icon v-if="listview">mdi-list-box</v-icon
          ><v-icon @click="listview = !listview" v-if="!listview"
            >mdi-list-box-outline</v-icon
          ></v-row
        >

        <v-row>
          <v-col
            v-for="item in process.variants
              .slice()
              .sort((a, b) => a.Number - b.Number)"
            :key="item.id"
            cols="12"
            sm="6"
            md="4"
          >
            <VariantCard
              v-if="!listview"
              :variant="item"
              :process="process"
              @show="showDetails(arguments, item)"
            />
          </v-col>
        </v-row>
        <VariantList
          v-if="listview"
          :variant="process.variants"
          :process="process"
          @show="showDetails(arguments, item)"
        />
      </div>
    </FeathersVuexGet>

    <VariantEdit
      v-if="detailDialog"
      v-model="detailDialog"
      :process="process.id"
    />

    <v-dialog v-model="importSteps" max-width="600px">
      <v-card flat>
        <v-card-title>
          <span class="headline">Import Variants</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers"
                :items="compareImportedVariants"
                sort-by="Number"
                disable-pagination
                hide-default-footer
                show-select
                :item-class="row_classes"
                v-model="importSelected"
                ><template v-slot:[`header.arrow`]="{ header }">
                  <v-icon>mdi-arrow-right-bold</v-icon>{{ header.text }}
                </template></v-data-table
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="cancelImport()">Cancel</v-btn>
          <v-btn color="primary" @click="saveImport()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <UpdateStatus
      v-if="process && updateDialog"
      v-model="updateDialog"
      :id="process.id"
      :type="'process'"
    />
    <ProcessEdit
      v-if="detailProcessDialog"
      v-model="detailProcessDialog"
      :currentItem="process"
    />
    <v-dialog
      v-if="issueDialog"
      v-model="issueDialog"
      width="90vw"
      height="auto"
      hide-overlay
      ><v-card height="90vh" class="pa-5">
        <IssueList
          v-if="issueDialog"
          v-model="issueDialog"
          :process="process"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="groupDialog"
      v-if="groupDialog"
      hide-overlay
      width="70vw"
    >
      <Group v-model="groupDialog" :process="process" />
    </v-dialog>
    <v-row
      ><v-col
        ><v-icon
          v-if="$can('update', 'variant', 'Number')"
          class="ml-5"
          @click="showReorder = true"
        >
          mdi-order-numeric-ascending
        </v-icon></v-col
      ></v-row
    >
    <Modal
      v-if="showMoveDialog"
      title="Move Process Variants"
      width="800px"
      :busy="isMovingVariants"
      @confirm="copyMoveVariants"
      @cancel="showMoveDialog = false"
    >
      <v-data-table
        :headers="headersVariants"
        :items="process.variants"
        sort-by="Number"
        v-model="selectedVariants"
        dense
        disable-pagination
        hide-default-footer
        show-select
      ></v-data-table>
      <v-checkbox
        v-model="copyVariant"
        label="Make a Copy"
        hide-details
      ></v-checkbox>
      <v-autocomplete
        class="mt-5"
        v-model="toProcess"
        :items="fullProcessList.filter((f) => f.id != process.id)"
        item-text="Name"
        item-value="id"
        :label="(copyVariant ? 'Copy' : 'Move') + ' to process'"
        outlined
        dense
        hide-details
      ></v-autocomplete>
      <v-checkbox
        v-model="includeProcessIssues"
        label="Include issues on process level"
        hide-details
        :disabled="copyVariant"
      ></v-checkbox>
    </Modal>
    <ReOrder v-if="showReorder" v-model="showReorder" :processId="process.id" />
  </v-container>
</template>

<script>
import { makeGetMixin, makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import VariantCard from '@/components/Variant/VariantCard';
import VariantList from '@/components/Variant/VariantList';
import UpdateStatus from '@/components/Variant/UpdateStatus.vue';
import ProcessEdit from '@/components/Process/ProcessEdit.vue';
import StatusListIndividual from '@/components/StatusListIndividual';
import IssueList from '@/components/Issues/IssueList.vue';
import DrawIO from '@/components/Drawing/drawio.vue';
import ReOrder from '@/components/Variant/ReOrder.vue';
import Modal from '@/components/general/Modal.vue';
import { handleErrorResponse } from '@/utils/MessageHandler';

import Group from '@/components/ProcessStep/Group/Grouplist.vue';

import VariantEdit from '@/components/Variant/VariantEdit';
import axios from 'axios';
import {
  getDocumentEmbed,
  getDocumentEmbedToken,
  getDocumentInfo,
  parseDocumentInfo,
} from '@/utils/LucidChartFunctions';

async function imageExists(url) {
  var result = false;
  result = await axios
    .head(url)
    .then((res) => res.status == 200)
    .catch(() => false);
  return result;
}

export default {
  name: 'ProcessDetail',
  data() {
    return {
      updateDialog: false,
      detailDialog: false,
      groupDialog: false,
      detailProcessDialog: false,
      companyAddDialog: false,
      issueDialog: false,
      importSteps: false,
      images: [],
      embedToken: null,
      compareImportedVariants: [],
      importSelected: [],
      listview: false,
      showReorder: false,
      showMoveDialog: false,
      toProcess: null,
      includeProcessIssues: false,
      selectedVariants: [],
      isMovingVariants: false,
      copyVariant: false,
      headers: [
        {
          text: 'Imported Drawing Text',
          value: 'text',
          sortable: true,
          groupable: false,
        },
        { value: 'arrow', sortable: false },
        {
          text: 'Loxi Text',
          value: 'loxiText',
          sortable: true,
          groupable: false,
        },
      ],
      headersVariants: [
        { text: 'Number', value: 'Number' },
        { text: 'Name', value: 'Name' },
      ],
    };
  },
  mixins: [
    makeGetMixin({
      service: 'process',
      id: 'id',
      watch: 'id',
    }),
    makeFindMixin({
      service: 'process',
      name: 'fullProcessList',
    }),
    makeFindMixin({
      service: 'tokens',
    }),
  ],
  components: {
    VariantCard,
    VariantList,
    VariantEdit,
    UpdateStatus,
    ProcessEdit,
    StatusListIndividual,
    IssueList,
    DrawIO,
    Group,
    ReOrder,
    Modal,
  },
  watch: {
    '$route.params.id': {
      handler: async function () {
        await feathersClient.service('process').get(this.id);
        if (
          this.$feature('lucidChartIntegration') &&
          this.process &&
          this.process.ExternalDocumentId
        ) {
          await this.LucidEmbed();
        } else if (this.$feature('cloudinaryChartIntegration')) {
          await this.getImages();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    clickEdit() {
      this.detailProcessDialog = true;
    },
    async copyMoveVariants() {
      this.isMovingVariants = true;
      if (this.copyVariant) {
        // : Copy variants
        for (const variant of this.selectedVariants) {
          const { Variant, ProcessStep, Drawings, Group } =
            this.$FeathersVuex.api;

          // Create New Header
          let newVariant = new Variant();
          Object.assign(newVariant, variant);
          newVariant.ProcessId = this.toProcess;

          delete newVariant.id;
          delete newVariant.CreatedById;
          delete newVariant.ModifiedById;
          delete newVariant.createdAt;
          delete newVariant.updatedAt;
          let savedVariant = null;

          try {
            savedVariant = await newVariant.save();
          } catch (error) {
            handleErrorResponse(error);
          }

          if (savedVariant) {
            // Copy drawing
            const drawing = await Drawings.find({
              query: { VariantId: variant.id },
            });
            if (drawing.total > 0) {
              const newDrawing = new Drawings();
              Object.assign(newDrawing, drawing.data[0]);
              newDrawing.VariantId = savedVariant.id;
              delete newDrawing.id;
              delete newDrawing.CreatedById;
              delete newDrawing.ModifiedById;
              delete newDrawing.createdAt;
              delete newDrawing.updatedAt;
              try {
                await newDrawing.save();
              } catch (error) {
                handleErrorResponse(error);
              }
            }
            // Get all process Steps to copy
            const steps = await ProcessStep.find({
              query: { VariantId: variant.id },
            });

            const groups = await Group.find({
              query: { VariantId: variant.id },
            });

            const groupMapper = [];
            const savedGroups = [];

            for (const group of groups.data) {
              let newGroup = new Group();
              Object.assign(newGroup, group);
              newGroup.VariantId = savedVariant.id;
              // map parentIds
              const newMap = { oldId: group.id, newGroupId: 0 };
              delete newGroup.id;
              delete newGroup.CreatedById;
              delete newGroup.ModifiedById;
              delete newGroup.createdAt;
              delete newGroup.updatedAt;
              try {
                const savedGroup = await newGroup.save();
                newMap.newGroupId = savedGroup.id;
                groupMapper.push(newMap);
                savedGroups.push(savedGroup);
              } catch (error) {
                handleErrorResponse(error);
              }

              for (const savedGroup of savedGroups) {
                if (savedGroup.ParentGroupId) {
                  savedGroup.ParentGroupId = groupMapper.find(
                    (g) => g.oldId == savedGroup.ParentGroupId
                  )?.newGroupId;
                  savedGroup.save();
                }
              }
            }

            for (const step of steps.data) {
              let newStep = new ProcessStep();
              Object.assign(newStep, step);
              newStep.VariantId = savedVariant.id;
              newStep.GroupId = groupMapper.find(
                (g) => g.oldId == step.GroupId
              )?.newGroupId;
              delete newStep.id;
              delete newStep.CreatedById;
              delete newStep.ModifiedById;
              delete newStep.createdAt;
              delete newStep.updatedAt;
              try {
                await newStep.save();
              } catch (error) {
                handleErrorResponse(error);
              }
            }
          }
        }
      } else {
        for (const variant of this.selectedVariants) {
          feathersClient
            .service('variant')
            .patch(variant.id, { ProcessId: this.toProcess })
            .catch((error) => handleErrorResponse(error));
          // Get all issues to move per variant
          const issues = await feathersClient
            .service('external-issues')
            .find({ query: { VariantId: variant.id } });
          for (const issue of issues.data) {
            await feathersClient
              .service('external-issues')
              .patch(issue.id, { ProcessId: this.toProcess })
              .catch((error) => handleErrorResponse(error));
          }
        }

        // If selected, move process issues
        if (this.includeProcessIssues) {
          const processIssues = await feathersClient
            .service('external-issues')
            .find({ query: { ProcessId: this.process.id, VariantId: null } });
          for (const issue of processIssues.data) {
            await feathersClient
              .service('external-issues')
              .patch(issue.id, { ProcessId: this.toProcess })
              .catch((error) => handleErrorResponse(error));
          }
        }
      }
      this.isMovingVariants = false;
      this.showMoveDialog = false;
    },
    updateStatus() {
      this.updateDialog = true;
    },
    row_classes(item) {
      if (item.text != item.loxiText) {
        return 'highlight';
      }
    },
    clickAddGroup() {
      this.groupDialog = true;
    },
    openTab(url) {
      window.open(url, '_blank');
    },
    showDetails: function (type, item) {
      if (type[0] == 'steps') {
        this.$router.push({
          name: 'processStep',
          params: { id: item.id },
        });
      } else if (type[0] == 'reqs') {
        this.$router.push({
          name: 'processRequirement',
          params: { id: item.id, type: type[1] },
        });
      } else if (type[0] == 'specs') {
        this.$router.push({
          name: 'specification',
          params: { id: item.id, type: type[1] },
        });
      } else if (type[0] == 'test') {
        this.$router.push({
          name: 'testscenarios',
          params: { id: item.id, type: type[1] },
        });
      }
    },
    async getImages() {
      let url = '';
      this.images = [];

      url =
        'https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v' +
        Date.now() +
        '/' +
        this.$appConfig.customer.prefix +
        '/' +
        this.number;

      for (let i = 0; i <= 3; i++) {
        let imageCheck = `${url}${i == 0 ? '' : '-' + i?.toString()}.svg`;
        if (await imageExists(imageCheck)) {
          this.images.push(imageCheck);
        }
      }

      url =
        'https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v' +
        Date.now() +
        '/' +
        this.$appConfig.customer.prefix +
        '/' +
        this.$appConfig.customer.prefix +
        this.number.toString().padStart(4, '0');

      for (let i = 0; i <= 3; i++) {
        let imageCheck = `${url}${i == 0 ? '' : '-' + i?.toString()}.svg`;
        if (await imageExists(imageCheck)) {
          this.images.push(imageCheck);
        }
      }
    },
    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },
    clickAdd() {
      this.detailDialog = true;
    },
    async LucidEmbed() {
      if (this.process && this.process.ExternalDocumentId) {
        if (!this.process.EmbedId) {
          await getDocumentEmbed(this.process);
        }
        this.embedToken = await getDocumentEmbedToken(this.process);
      }
    },
    async getSteps(process) {
      let result = await getDocumentInfo(process);

      result = parseDocumentInfo(result)[0];

      let variant = this.process.variants.map((item) => {
        let newitem = {};
        newitem.id = item.id;
        newitem.ExternalId = item.ExternalId;
        newitem.text = item.Name;
        return newitem;
      });
      this.compareImportedVariants = result.blocks
        .filter((p) => p.type == 'SubProcess')
        .map((item) => {
          let newitem = {};
          newitem.id = item.id;
          newitem.text = item.text;
          let loxi = variant.filter((item2) => item2.ExternalId == item.id);
          if (loxi.length == 1) {
            newitem.loxiId = loxi[0].id;
            newitem.loxiText = loxi[0].text;
          }

          return newitem;
        });

      this.importSteps = true;
    },
    cancelImport() {
      this.importSteps = false;
    },
    async saveImport() {
      if (this.importSelected.length > 0) {
        this.importSelected.forEach(async (item, index) => {
          const { Variant, Process } = this.$FeathersVuex.api;
          if (!item.loxiId) {
            let newVariant = new Variant();
            newVariant.Number = index + 1;
            newVariant.ExternalId = item.id;
            newVariant.ApplicationId = 1;
            newVariant.Name = item.text;
            newVariant.ProcessId = this.process.id;
            await newVariant.save();
          } else {
            let newVariant = new Variant();
            newVariant.Name = item.text;
            await feathersClient.service('variant').patch(item.loxiId, {
              Name: item.text,
            });
          }
          await Process.find({ query: { id: this.process.id } });
        });
      }
      this.importSteps = false;
    },
    filterStatusList(item) {
      if (this.statusOverviewLatestQuery) {
        return this.statusOverviewLatestQuery.response.filter(
          (s) => s.VariantId == item.id
        );
      } else return [];
    },
  },
  computed: {
    fullProcessListParams() {
      return {
        query: {
          // DepartmentId: this.process ? this.process.DepartmentId : 0,
          $sort: { Number: 1 },
        },
      };
    },
    id() {
      return this.$route.params.id;
    },
    number() {
      return this.$route.params.number;
    },
    LucidUrl() {
      return this.process
        ? `https://lucid.app/documents/${
            this.process.ExternalDocumentId
          }/viewer?pages=${
            this.process.PageIndexes ? this.process.PageIndexes : 1
          }&token=${this.embedToken}`
        : null;
    },

    sortedVariants() {
      let variants = [...this.process.variants];
      return variants.sort((a, b) => (a.Number > b.Number ? 1 : -1));
    },
    tokensParams() {
      return {};
    },

    sharePointUrl() {
      if (this.tokens) {
        let url = this.tokens.filter((t) => t.Application == 'SharePoint');
        if (url && url.length == 1) {
          return `${url[0].FrontEndUrl}${
            this.$appConfig.customer.prefix
          }${this.process.Number.toString().padStart(4, '0')}`;
        }
      }
      return '';
    },
  },
  async mounted() {
    if (this.$feature('lucidChartIntegration')) {
      await this.LucidEmbed();
    } else {
      // await this.getImages();
    }
  },
};
</script>

<style lang="scss">
.pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}
.highlight {
  background-color: #ef9a9a;
}
</style>
