<template>
  <v-container
    ><FeathersVuexFind
      v-slot="{ items: status }"
      service="status"
      :params="{}"
      watch="params"
    >
      <section>
        <v-data-table
          :headers="filteredHeaders"
          :items="status"
          :items-per-page="100"
          :sort-by="['Number']"
          :sort-desc="[false]"
          item-key="id"
          class="elevation-1"
          hide-default-footer
        >
          <template v-if="$can('create', 'status')" v-slot:footer>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    class="mx-2"
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <v-form ref="form" v-model="validInput">
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add Status</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="2">
                            <v-text-field
                              v-model="editedItem.Number"
                              label="Number"
                              :rules="[rules.required, rules.number]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.Status"
                              label="Status"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.StatusToPlan"
                              label="Plan Status"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-color-picker
                              dot-size="25"
                              swatches-max-height="200"
                              mode="hexa"
                              v-model="editedItem.Color"
                              :rules="[rules.required]"
                            ></v-color-picker>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-slider
                              v-model="editedItem.Progress"
                              step="0.05"
                              min="0"
                              max="1"
                              thumb-color="primary"
                              thumb-label="always"
                              ticks
                              label="Progress"
                              class="mt-10"
                              :rules="[rules.number]"
                            ></v-slider>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="editedItem.role"
                              :items="roles"
                              label="Role"
                              :rules="[rules.required]"
                              dense
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-checkbox
                              v-model="editedItem.Active"
                              label="Active"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-checkbox
                              v-model="editedItem.ActiveComment"
                              label="Active Comment"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="Close()">Cancel</v-btn>
                      <v-btn
                        :disabled="!validInput"
                        color="primary"
                        @click="Add()"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card></v-form
                >
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-if="$can('update', 'status')"
            v-slot:[`item.Number`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Number"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Number }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Number"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'status')"
            v-slot:[`item.Status`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Status"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Status }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Status"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'status')"
            v-slot:[`item.StatusToPlan`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.StatusToPlan"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.StatusToPlan }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.StatusToPlan"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.Color`]="props">
            <v-edit-dialog
              v-if="$can('update', 'status')"
              :return-value.sync="props.item.Color"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              <span v-if="props.item.Color">
                <v-icon large class="mr-2" :color="props.item.Color">
                  mdi-circle
                </v-icon>
              </span>
              <template v-slot:input>
                <v-color-picker
                  dot-size="25"
                  swatches-max-height="200"
                  mode="hexa"
                  v-model="props.item.Color"
                ></v-color-picker>
              </template>
            </v-edit-dialog>
            <span v-if="props.item.Color && !$can('update', 'status')">
              <v-icon large class="mr-2" :color="props.item.Color">
                mdi-circle
              </v-icon>
            </span>
          </template>
          <template
            v-if="$can('update', 'status')"
            v-slot:[`item.Progress`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Progress"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              <v-progress-circular
                :rotate="270"
                :size="40"
                :width="4"
                :value="props.item.Progress * 100"
                color="primary"
              >
                <span class="caption"> {{ props.item.Progress * 100 }}</span>
              </v-progress-circular>
              <template v-slot:input>
                <v-slider
                  v-model="props.item.Progress"
                  step="0.05"
                  min="0"
                  max="1"
                  thumb-color="primary"
                  thumb-label="always"
                  ticks
                  class="mt-10"
                ></v-slider>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.Closed`]="props">
            <v-edit-dialog
              v-if="$can('update', 'status')"
              :return-value.sync="props.item.Closed"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              <span v-if="props.item.Closed">
                <v-icon small class="mr-2" color="primary">
                  mdi-check-bold
                </v-icon>
              </span>
              <template v-slot:input>
                <v-simple-checkbox
                  v-model="props.item.Closed"
                ></v-simple-checkbox>
              </template>
            </v-edit-dialog>
            <span v-if="props.item.Closed && !$can('update', 'status')">
              <v-icon small class="mr-2" color="primary">
                mdi-check-bold
              </v-icon>
            </span>
          </template>
          <template v-slot:[`item.Testable`]="props">
            <v-edit-dialog
              v-if="$can('update', 'status')"
              :return-value.sync="props.item.Testable"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              <span v-if="props.item.Testable">
                <v-icon small class="mr-2" color="primary">
                  mdi-check-bold
                </v-icon>
              </span>
              <template v-slot:input>
                <v-simple-checkbox
                  v-model="props.item.Testable"
                ></v-simple-checkbox>
              </template>
            </v-edit-dialog>
            <span v-if="props.item.Testable && !$can('update', 'status')">
              <v-icon small class="mr-2" color="primary">
                mdi-check-bold
              </v-icon>
            </span>
          </template>
          <template
            v-if="$can('delete', 'status')"
            v-slot:[`item.actions`]="{ item }"
          >
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon></template
          >
          <template v-slot:[`item.Active`]="props">
            <v-edit-dialog
              v-if="$can('update', 'status')"
              :return-value.sync="props.item.Active"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              <span v-if="props.item.Active">
                <v-icon small class="mr-2" color="primary">
                  mdi-check-bold
                </v-icon>
              </span>
              <template v-slot:input>
                <v-simple-checkbox
                  v-model="props.item.Active"
                ></v-simple-checkbox>
              </template>
            </v-edit-dialog>
            <span v-if="props.item.Active && !$can('update', 'status')">
              <v-icon small class="mr-2" color="primary">
                mdi-check-bold
              </v-icon>
            </span>
          </template>
          <template v-slot:[`item.ActiveComment`]="props">
            <v-edit-dialog
              v-if="$can('update', 'status')"
              :return-value.sync="props.item.ActiveComment"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              <span v-if="props.item.ActiveComment">
                <v-icon small class="mr-2" color="primary">
                  mdi-check-bold
                </v-icon>
              </span>
              <template v-slot:input>
                <v-simple-checkbox
                  v-model="props.item.ActiveComment"
                ></v-simple-checkbox>
              </template>
            </v-edit-dialog>
            <span v-if="props.item.ActiveComment && !$can('update', 'status')">
              <v-icon small class="mr-2" color="primary">
                mdi-check-bold
              </v-icon>
            </span>
          </template>
          <template
            v-if="$can('create', 'status-project-role')"
            v-slot:[`item.ProjectRoles`]="{ item }"
          >
            <v-icon @click="addProjectRoles(item)"> mdi-chevron-right </v-icon>
          </template>
          <template v-if="$can('update', 'users')" v-slot:[`item.Role`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.Role"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Role }}
              <template v-slot:input>
                <v-select
                  class="mt-7"
                  v-model="props.item.Role"
                  :items="roles"
                  label="Edit"
                  dense
                ></v-select>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'status')"
            v-slot:[`item.StatusCategory`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.StatusCategory"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.StatusCategory }}
              <template v-slot:input>
                <v-select
                  class="mt-7"
                  v-model="props.item.StatusCategory"
                  :items="StatusCategory"
                  label="Edit"
                  clearable
                  dense
                ></v-select>
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table></section
    ></FeathersVuexFind>
    <StatusProjectRoles
      v-model="showDialogProjectRoles"
      v-if="showDialogProjectRoles"
      :item="currentItem"
  /></v-container>
</template>

<script>
import feathersClient from '../../feathers-client';
import StatusProjectRoles from './StatusProjectRoles.vue';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      validInput: false,
      StatusCategory: ['Open', 'In Progress', 'Done', 'Not in Scope'],
      headers: [
        {
          text: 'Number',
          align: 'start',
          sortable: true,
          value: 'Number',
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'Status',
        },
        {
          text: 'Plan Status',
          align: 'start',
          sortable: true,
          value: 'StatusToPlan',
        },
        {
          text: 'Color',
          align: 'start',
          sortable: true,
          value: 'Color',
        },
        {
          text: 'Progress',
          align: 'start',
          sortable: true,
          value: 'Progress',
        },
        {
          text: 'Role',
          align: 'start',
          sortable: true,
          value: 'Role',
        },
        {
          text: 'Status Category',
          align: 'start',
          sortable: true,
          value: 'StatusCategory',
        },
        {
          text: 'Closed',
          align: 'center',
          sortable: true,
          value: 'Closed',
        },
        {
          text: 'Testable',
          align: 'center',
          sortable: true,
          value: 'Testable',
        },
        {
          text: 'Active Process',
          align: 'center',
          sortable: true,
          value: 'Active',
        },
        {
          text: 'Project Roles',
          align: 'center',
          sortable: false,
          value: 'ProjectRoles',
        },

        { text: 'Delete', value: 'actions', sortable: false },
      ],
      dialog: false,
      showDialogProjectRoles: false,
      currentItem: null,
      roles: ['admin', 'customer', 'vendor'],
      editedItem: {
        Number: '',
        Status: '',
        Color: '',
        Role: 'admin',
        Progress: 0,
        Active: true,
        ActiveComment: false,
      },
      defaultItem: {
        Number: '',
        Status: '',
        Color: '',
        Role: 'admin',
        Progress: 0,
        Active: true,
        ActiveComment: false,
      },
    };
  },
  components: { StatusProjectRoles },
  computed: {
    filteredHeaders() {
      return this.headers.filter((item) => {
        return this.$can('delete', 'status') ? true : item.text != 'Delete';
      });
    },
  },
  methods: {
    save(item) {
      item
        .save()
        .then((saved) => handleSaveResponse(saved.Status, 'Status', 'updated'))
        .catch((error) => handleErrorResponse(error));
    },
    addProjectRoles(item) {
      this.showDialogProjectRoles = true;
      this.currentItem = item;
    },
    open() {},
    cancel() {},
    close() {},
    Add() {
      feathersClient
        .service('status')
        .create(this.editedItem)
        .then((saved) => handleSaveResponse(saved.Status, 'Status', 'added'))
        .catch((error) => handleErrorResponse(error));

      this.Close();
    },
    Close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    deleteItem(item) {
      item
        .remove()
        .then((saved) => handleSaveResponse(saved.Status, 'Status', 'removed'))
        .catch((error) => handleErrorResponse(error));
    },
  },
};
</script>

<style></style>
