var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"width":"100vw"}},[_c('div',[_c('ejs-splitter',{attrs:{"id":"default-splitter"},on:{"resizeStop":_vm.onResize}},[_c('e-panes',[_c('e-pane',{attrs:{"content":"#menu","size":"400px"}},[_vm._v("Koekoek")]),_c('e-pane',{attrs:{"content":"#drawing","size":"400px"}},[_vm._v("Koekoek")]),_c('e-pane',{attrs:{"content":"#nested"}})],1)],1)],1),_c('div',{staticClass:"content-pane",attrs:{"id":"menu"}},[_c('ProcessListTree')],1),_c('div',{staticClass:"mx-5",attrs:{"id":"drawing"}},[(
        _vm.$store.state.activeProcessNode.TestScenarioHeaderId &&
        !_vm.$store.state.activeProcessNode.TestScenarioLineId
      )?_c('DrawIO',{key:_vm.drawIoKey,attrs:{"testScenarioHeaderId":parseInt(_vm.$store.state.activeProcessNode.TestScenarioHeaderId),"height":"85vh"}}):_vm._e(),(_vm.$store.state.activeProcessNode.VariantId)?_c('DrawIO',{key:_vm.drawIoKey,attrs:{"variantId":parseInt(_vm.$store.state.activeProcessNode.VariantId),"height":"85vh"}}):_vm._e(),(
        !_vm.$store.state.activeProcessNode.VariantId &&
        !_vm.$store.state.activeProcessNode.ProcessId
      )?_c('DrawIO',{key:_vm.drawIoKey,attrs:{"processId":parseInt(_vm.$store.state.activeProcessNode.ProcessId),"height":"85vh"}}):_vm._e()],1),_c('div',{staticClass:"sticky",attrs:{"id":"nested"}},[_c('ejs-splitter',{attrs:{"orientation":"Vertical"},on:{"resizeStop":_vm.onResize}},[_c('e-panes',[_c('e-pane',{attrs:{"content":"#breadcrumb","size":"40px","resizable":false}}),_c('e-pane',{attrs:{"content":"#content"}})],1)],1)],1),_c('div',{attrs:{"id":"breadcrumb"}},[_c('Breadcrumb')],1),_c('div',{staticClass:"content-pane",attrs:{"id":"content"}},[(_vm.$store.state.activeProcessNode.TestScenarioLineId)?_c('TestLineBrowser'):_vm._e(),(
        !_vm.$store.state.activeProcessNode.VariantId &&
        !_vm.$store.state.activeProcessNode.TestScenarioLineId
      )?_c('VariantBrowser'):_vm._e(),(
        _vm.$store.state.activeProcessNode.VariantId &&
        !_vm.$store.state.activeProcessNode.TestScenarioLineId
      )?_c('ProcessStepBrowser'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }