<template>
  <div>
    <v-card flat>
      <v-row>
        <v-col cols="12" md="4" class="my-2">
          Filters
          <v-btn icon @click="show = !show">
            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
          <v-tooltip bottom v-if="filterupdated">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                class="ml-5"
                @click="saveFilter"
                v-bind="attrs"
                v-on="on"
                >mdi-filter-plus</v-icon
              ></template
            >Save Filter
          </v-tooltip>
          <v-tooltip bottom v-if="filterExists">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-5"
                color="primary"
                @click="clearFilter"
                v-bind="attrs"
                v-on="on"
                >mdi-filter-remove</v-icon
              ></template
            >Clear Filter</v-tooltip
          ></v-col
        >
        <v-col cols="12" md="8" align="right">
          <v-icon
            v-if="$can('create', 'external-issues')"
            class="ml-5"
            @click="clickEdit(null)"
            color="primary"
            large
          >
            mdi-plus-circle
          </v-icon></v-col
        >
      </v-row>

      <v-expand-transition>
        <v-card v-if="show" class="mb-5">
          <v-card-title v-if="$route.name != 'Issues' && !this.statusActive">
            <span class="headline">Issues</span><v-spacer></v-spacer>
            <v-icon class="ml-5" @click="showDialog = false" color="primary">
              mdi-close-box
            </v-icon>
          </v-card-title>
          <v-card-title v-if="$route.name == 'Issues'">
            <v-row
              ><v-col cols="12" md="4" class="my-0 py-2"
                ><v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  clearable
                ></v-text-field></v-col
              ><v-col cols="12" md="4" class="my-0 py-2"></v-col
              ><v-col cols="12" md="4" class="my-0 py-2"></v-col
              ><v-col cols="12" md="4" class="my-0 py-2">
                <v-autocomplete
                  v-model="selectionFilter.issueType"
                  :items="issueType"
                  item-text="Type"
                  item-value="id"
                  label="IssueType"
                  clearable
                  multiple
                  hide-details
                  dense
                  small-chips
                  @change="filterupdated = true"
                  ><template v-slot:item="{ item }">
                    <v-icon small class="mr-2"> {{ item.Icon }} </v-icon>
                    {{ item?.Type
                    }}<v-icon v-if="item.ExternalType" class="mb-3 ml-1" x-small
                      >mdi-connection</v-icon
                    >
                  </template>
                  <template #selection="{ item }">
                    <v-icon small class="mr-2"> {{ item.Icon }} </v-icon>
                    {{ item?.Type
                    }}<v-icon v-if="item.ExternalType" class="mb-3 ml-1" x-small
                      >mdi-connection</v-icon
                    >
                  </template></v-autocomplete
                ></v-col
              ><v-col cols="12" md="4" class="my-0 py-2">
                <v-autocomplete
                  v-model="selectionFilter.status"
                  :items="filteredStatus"
                  item-text="Status"
                  item-value="id"
                  label="Status"
                  clearable
                  multiple
                  hide-details
                  dense
                  small-chips
                  @change="filterupdated = true"
                >
                  <template v-slot:item="{ item }">
                    <v-icon small class="mr-2" :color="item.Color">
                      mdi-circle
                    </v-icon>
                    {{ item.Status }} </template
                  ><template #selection="{ item }">
                    <v-icon small class="mr-2" :color="item.Color">
                      mdi-circle
                    </v-icon>
                    {{ item.Status }}
                  </template></v-autocomplete
                ></v-col
              ><v-col cols="12" md="4" class="my-0 py-2">
                <v-autocomplete
                  v-model="selectionFilter.blocking"
                  :items="[
                    { blocking: false, text: 'No' },
                    { blocking: true, text: 'Yes' },
                  ]"
                  label="Blocking"
                  item-text="text"
                  item-value="blocking"
                  clearable
                  hide-details
                  dense
                  @change="filterupdated = true"
                ></v-autocomplete></v-col
              ><v-col cols="12" md="4" class="my-0 py-2">
                <v-autocomplete
                  v-model="selectionFilter.user"
                  :items="users"
                  item-text="fullname"
                  item-value="id"
                  label="Assigned User"
                  clearable
                  hide-details
                  dense
                  @change="filterupdated = true"
                  ><template v-slot:item="{ item }">
                    <UserAvatar
                      :Name="item.fullname"
                      :Image="item.gravatarUrl"
                      :Color="item.color"
                    />
                    {{ item.fullname }}</template
                  ><template #selection="{ item }">
                    <UserAvatar
                      :Name="item.fullname"
                      :Image="item.gravatarUrl"
                      :Color="item.color"
                    />
                    {{ item.fullname }}</template
                  ></v-autocomplete
                ></v-col
              ><v-col cols="12" md="4" class="my-0 py-2">
                <v-autocomplete
                  v-model="selectionFilter.createdUser"
                  :items="users"
                  item-text="fullname"
                  item-value="id"
                  label="Issue Creator"
                  clearable
                  hide-details
                  dense
                  @change="filterupdated = true"
                  ><template v-slot:item="{ item }">
                    <UserAvatar
                      :Name="item.fullname"
                      :Image="item.gravatarUrl"
                      :Color="item.color"
                    />
                    {{ item.fullname }}</template
                  ><template #selection="{ item }">
                    <UserAvatar
                      :Name="item.fullname"
                      :Image="item.gravatarUrl"
                      :Color="item.color"
                    />
                    {{ item.fullname }}</template
                  ></v-autocomplete
                ></v-col
              ><v-col cols="12" md="4" class="my-0 py-2"></v-col>
              <v-col cols="12" md="4" class="my-0 py-2">
                <v-autocomplete
                  v-model="selectionFilter.department"
                  :items="department"
                  item-text="Name"
                  item-value="id"
                  label="Department"
                  clearable
                  hide-details
                  dense
                  small-chips
                  @change="filterupdated = true"
                ></v-autocomplete></v-col
              ><v-col cols="12" md="4" class="my-0 py-2">
                <v-autocomplete
                  v-model="selectionFilter.linked"
                  :items="[
                    { linked: false, text: 'Unlinked' },
                    { linked: true, text: 'Linked' },
                  ]"
                  label="Linked to Process"
                  item-text="text"
                  item-value="linked"
                  clearable
                  hide-details
                  dense
                  @change="filterupdated = true"
                ></v-autocomplete></v-col
            ></v-row>
          </v-card-title>
          <v-card-title v-else>
            <v-row
              ><v-col cols="12" md="4"
                ><v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  clearable
                ></v-text-field></v-col
              ><v-col cols="12" md="4">
                <v-autocomplete
                  v-model="selectionFilter.blocking"
                  :items="[
                    { blocking: false, text: 'No' },
                    { blocking: true, text: 'Yes' },
                  ]"
                  label="Blocking"
                  item-text="text"
                  item-value="blocking"
                  clearable
                  hide-details
                  dense
                  @change="filterupdated = true"
                ></v-autocomplete></v-col
              ><v-col cols="12" md="4">
                <v-autocomplete
                  v-model="selectionFilter.user"
                  :items="users"
                  item-text="fullname"
                  item-value="id"
                  label="Assigned User"
                  clearable
                  hide-details
                  dense
                  @change="filterupdated = true"
                  ><template v-slot:item="{ item }">
                    <UserAvatar
                      :Name="item.fullname"
                      :Image="item.gravatarUrl"
                      :Color="item.color"
                    />
                    {{ item.fullname }}</template
                  ><template #selection="{ item }">
                    <UserAvatar
                      :Name="item.fullname"
                      :Image="item.gravatarUrl"
                      :Color="item.color"
                    />
                    {{ item.fullname }}</template
                  ></v-autocomplete
                ></v-col
              ><v-col cols="12" md="4">
                <v-autocomplete
                  v-model="selectionFilter.status"
                  :items="filteredStatus"
                  item-text="Status"
                  item-value="id"
                  label="Status"
                  clearable
                  multiple
                  hide-details
                  dense
                  small-chips
                  @change="filterupdated = true"
                >
                  <template v-slot:item="{ item }">
                    <v-icon small class="mr-2" :color="item.Color">
                      mdi-circle
                    </v-icon>
                    {{ item.Status }} </template
                  ><template #selection="{ item }">
                    <v-icon small class="mr-2" :color="item.Color">
                      mdi-circle
                    </v-icon>
                    {{ item.Status }}
                  </template></v-autocomplete
                ></v-col
              ><v-col cols="12" md="4"></v-col
              ><v-col cols="12" md="4" class="pa-0"
                ><v-switch
                  v-model="selectionFilter.Closed"
                  class="d-inline-flex"
                  dense
                  hide-details
                  label="Show Only Open"
                  @change="filterupdated = true"
                ></v-switch></v-col
            ></v-row>
          </v-card-title>

          <v-card-text class="my-2">
            <v-row><v-spacer></v-spacer> </v-row
          ></v-card-text>
        </v-card>
      </v-expand-transition>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="filteredIssues"
      :items-per-page="15"
      :sort-by="['Status', 'createdAt']"
      :sort-desc="[true, true]"
      item-key="id"
      class="elevation-1"
      item-class="topalign"
      show-expand
      fixed-header
      height="calc(100vh - 250px)"
      :loading="isFindExternalIssuesPending"
      :hide-default-footer="$route.name == 'Issues' ? false : true"
      :disable-pagination="$route.name == 'Issues' ? false : true"
    >
      <template v-slot:[`item.IssueType`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small>{{
              item.IssueType?.Icon
            }}</v-icon></template
          >{{ item.IssueType?.Type }}</v-tooltip
        >
      </template>
      <template v-slot:[`item.Extern`]="{ item }">
        <a @click="openWindow(item.Url)"
          ><u>{{ item.ExternalId }}</u></a
        ></template
      >
      <template v-slot:[`item.Title`]="{ item }">
        {{ item.Title
        }}<span class="ml-2" v-if="item.external_issue_replies?.length > 0">
          <v-icon class="mb-1" color="primary" small>mdi-reply</v-icon>
          <span class="text-caption"
            >({{ item.external_issue_replies?.length }})</span
          ></span
        ></template
      >
      <template v-slot:[`item.Link`]="{ item }">
        <v-tooltip
          color="white"
          content-class="custom-tooltip"
          bottom
          v-if="
            item.process_step ||
            item.variant ||
            item.process ||
            item.test_scenario_run_step
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-if="
                item.process &&
                !item.variant &&
                !item.process_step &&
                !item.test_scenario_run_step
              "
              class="caption"
              @click="
                openWindow(
                  '/#/processdetail/' +
                    item.process?.id +
                    '&' +
                    item.process?.Number
                )
              "
              >mdi-sitemap</v-icon
            >
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="caption"
              v-if="
                item.variant &&
                !item.process_step &&
                !item.test_scenario_run_step
              "
              @click="
                openWindow(
                  '/#/processdetail/' +
                    item.process?.id +
                    '&' +
                    item.process?.Number
                )
              "
              >mdi-format-list-text</v-icon
            >
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="caption"
              v-if="item.process_step && !item.test_scenario_run_step"
              @click="
                openWindow('/#/processStep/' + item.process_step.variant?.id)
              "
              >mdi-arrow-decision</v-icon
            >
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="caption"
              v-if="item.test_scenario_run_step"
              @click="
                $router.push({
                  name: 'testrun',
                  params: {
                    id: item.test_scenario_run_step.TestScenarioRunDetailId,
                  },
                })
              "
              >mdi-test-tube</v-icon
            >
          </template>

          <v-list dense>
            <v-subheader
              >Linked to
              {{
                item.test_scenario_run_step
                  ? 'Test Scenario'
                  : item.process_step
                  ? 'Process Step'
                  : item.variant
                  ? 'Variant'
                  : item.process
                  ? 'Process'
                  : ''
              }}</v-subheader
            >
            <v-list-item-group color="primary">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon small>mdi-sitemap</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    item.process_step
                      ? item.process_step.variant.process.Name
                      : item.variant
                      ? item.variant.process.Name
                      : item.process
                      ? item.process.Name
                      : ''
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="ml-2">
                <v-list-item-icon>
                  <v-icon small>mdi-format-list-text</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      item.process_step
                        ? item.process_step.variant.Name
                        : item.variant
                        ? item.variant.Name
                        : ''
                    }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="ml-4">
                <v-list-item-icon>
                  <v-icon small>mdi-arrow-decision</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      item.process_step ? item.process_step.Name : ''
                    }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="ml-6">
                <v-list-item-icon>
                  <v-icon small>mdi-test-tube</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      item.test_scenario_run_step
                        ? `${item.test_scenario_run_step.test_scenario_run_detail.test_scenario_run.Number}.${item.test_scenario_run_step.test_scenario_run_detail.Order} ${item.test_scenario_run_step.Name}`
                        : ''
                    }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="caption"
              @click="linkProcess(item)"
              v-if="
                !item?.process &&
                !item?.variant &&
                !item?.process_step &&
                !item?.test_scenario_run_step
              "
              >mdi-cancel</v-icon
            ></template
          >No Link</v-tooltip
        >
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
      </template>
      <template v-slot:[`item.Assigned`]="{ item }">
        <v-menu
          :disabled="
            item.ExternalId?.length > 0 &&
            updateFields.filter(
              (f) => f.IssueTypeId == item.IssueTypeId && f.Field == 'Assignee'
            ).length == 0
          "
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <div v-bind="attrs" v-on="{ ...menu }">
              <v-icon class="mt-1" v-if="!item.Assigned"
                >mdi-account-plus</v-icon
              >
              <UserAvatar
                v-if="item.Assigned"
                class="ml-3 mt-1"
                :Name="item.Assigned.fullname"
                :Image="item.Assigned.gravatarUrl"
                :Color="item.Assigned.color"
              />
            </div>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item
              v-for="(user, index) in userList"
              :key="user?.id"
              :value="index"
              @click="assignUser(item, user.id)"
            >
              <v-list-item-title
                ><v-avatar
                  :class="
                    applyDark(user.color)
                      ? 'white--text caption mr-2'
                      : 'black--text caption mr-2'
                  "
                  text--color="white"
                  :color="user.color"
                  size="25"
                  ><img
                    v-if="user.gravatarUrl"
                    :src="user.gravatarUrl"
                    :alt="user.fullname"
                  />
                  <span v-else>{{
                    user.fullname
                      .match(/(\b\S)?/g)
                      .join('')
                      .match(/(^\S|\S$)?/g)
                      .join('')
                      .toUpperCase()
                  }}</span></v-avatar
                >{{ user.fullname }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`item.CreatedBy`]="{ item }">
        <UserAvatar
          class="ml-3 mt-1"
          v-if="item.CreatedBy"
          :Name="item.CreatedBy.fullname"
          :Image="item.CreatedBy.gravatarUrl"
          :Color="item.CreatedBy.color"
        />
      </template>
      <template v-slot:[`item.Status`]="{ item }">
        <v-menu
          :disabled="
            item.ExternalId?.length > 0 &&
            updateFields.filter(
              (f) => f.IssueTypeId == item.IssueTypeId && f.Field == 'Status'
            ).length == 0
          "
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <div v-bind="attrs" v-on="{ ...menu }">
              <v-chip
                v-if="item.Status"
                :text-color="applyDark(item.Status.Color) ? 'white' : 'black'"
                class="ma-2"
                :color="item.Status.Color"
                x-small
              >
                {{ item.Status.Status }}
              </v-chip>
            </div>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item
              v-for="(stat, index) in issueTypeStatusFull.filter(
                (f) => f.IssueTypeId == item.IssueTypeId
              )"
              :key="stat.id"
              :value="index"
              @click="assignStatus(item, stat.Status.id)"
            >
              <v-list-item-title>
                <v-icon small class="mr-2" :color="stat.Status.Color">
                  mdi-circle </v-icon
                >{{ stat.Status.Status }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu> </template
      ><template v-slot:[`item.Blocking`]="{ item }">
        <span v-if="item.Blocking">
          <v-icon small class="mr-2" color="red">
            mdi-checkbox-marked-circle
          </v-icon>
        </span>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-icon
          v-if="$can('update', subject('external-issues', item))"
          small
          class="mr-2"
          @click="clickEdit(item)"
        >
          mdi-pencil
        </v-icon></template
      >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card class="pa-2">
            <v-row
              ><v-col cols="12" md="12">
                <v-card elevation-0 outlined height="100%"
                  ><v-card-title>Description</v-card-title
                  ><v-card-text
                    ><span
                      v-html="item.Content1"
                      class="text-body-2 ma-0 ql-editor"
                    ></span></v-card-text></v-card></v-col
              ><v-col cols="12" md="6" v-if="false"
                ><v-card elevation-0 outlined height="100%"
                  ><v-card-title>Extra Info</v-card-title
                  ><v-card-text
                    ><span
                      v-html="item.Content2"
                      class="text-body-2 ma-0 ql-editor"
                    ></span></v-card-text></v-card></v-col></v-row
            ><v-row
              ><v-col cols="12" md="4"
                ><v-text-field
                  readonly
                  outlined
                  hide-details
                  dense
                  label="Due Date"
                  :value="
                    item.DueDate
                      ? new Date(item.DueDate).toLocaleDateString()
                      : ''
                  "
                ></v-text-field></v-col
              ><v-col cols="12" md="4"
                ><v-text-field
                  readonly
                  outlined
                  hide-details
                  dense
                  label="Planning Status"
                  :value="item.DueStatus?.Status"
                ></v-text-field></v-col></v-row
          ></v-card>
          <v-card class="pa-2"
            ><v-card-title>Comments</v-card-title>
            <IssueReplies class="mt-1" :item="item"
          /></v-card></td></template
    ></v-data-table>

    <IssueEdit
      v-model="showAdd"
      v-if="showAdd"
      :currentItem="currentItem"
      :process="process"
      :variant="variant"
      :processStep="processStep"
      :testScenarioRunStep="testScenarioRunStep"
    />
    <LinkProcess
      v-if="showLinkProcessDialog"
      v-model="showLinkProcessDialog"
      :issue="itemToLink"
    />
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist';
import { mapGetters } from 'vuex';
import { applyDark } from '@/utils/Utilities';
import { subject } from '@casl/ability';
import IssueEdit from './IssueEdit.vue';
import IssueReplies from './IssueReplies.vue';
import UserAvatar from '@/components/general/UserAvatar.vue';
import feathersClient from '@/feathers-client';
import { CreateIssue } from '@/utils/issues/CreateExternalIssue';
import LinkProcess from './LinkProcess.vue';

import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

export default {
  mixins: [
    makeFindMixin({ service: 'status' }),
    makeFindMixin({ service: 'external-issues', watch: ['statusActive'] }),
    makeFindMixin({ service: 'issue-type' }),
    makeFindMixin({ service: 'issue-type-status' }),
    makeFindMixin({ service: 'issue-type-fields' }),
    makeFindMixin({ service: 'department' }),
    makeFindMixin({ service: 'users' }),
  ],
  data() {
    return {
      showAdd: false,
      showTask: false,
      currentItem: null,
      showAll: this.$route.name == 'Items' ? false : true,
      addReply: false,
      reply: '',
      replyId: null,
      TestRunNumbers: [],
      selectionFilter: {},
      search: null,
      filterExists: null,
      filterupdated: false,
      show: false,
      showLinkProcessDialog: false,
      itemToLink: null,
    };
  },
  components: { IssueEdit, UserAvatar, IssueReplies, LinkProcess },
  props: {
    value: { type: Boolean },
    process: { type: Object, required: false },
    variant: { type: Object, required: false },
    processStep: { type: Object, required: false },
    testScenarioRunStep: { type: Object, required: false },
    statusActive: { type: Object, required: false },
    phase: { type: String, required: false },
  },
  methods: {
    applyDark,
    subject,
    handleSaveResponse,
    handleErrorResponse,
    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },
    clickEdit(item) {
      this.currentItem = item;
      this.showAdd = true;
    },
    saveFilter() {
      window.localStorage.setItem(
        'issueFilter',
        JSON.stringify(this.selectionFilter)
      );
      this.filterExists = true;
      this.filterupdated = false;
    },
    clearFilter() {
      window.localStorage.removeItem('issueFilter');
      this.selectionFilter = {};
      this.filterExists = false;
      this.filterupdated = false;
    },
    linkProcess(item) {
      this.itemToLink = item;
      this.showLinkProcessDialog = true;
    },
    async assignUser(item, id) {
      const { ExternalIssues } = this.$FeathersVuex.api;
      // console.log(item, id);

      try {
        await feathersClient.service('external-issues').patch(item.id, {
          AssignedId: id,
        });
        if (this.$appConfig.externalIssues?.length > 0) {
          item.ExternalId != null
            ? await CreateIssue(item, 'patch')
            : await CreateIssue(item, 'post');
        }
      } catch (error) {
        handleErrorResponse(error);
      }
      // handleSaveResponse('', 'Test Scenario', 'assigned');
      await ExternalIssues.find({ query: { id: item?.id } });
    },
    async assignStatus(item, id) {
      const { ExternalIssues } = this.$FeathersVuex.api;
      // console.log(item, id);

      try {
        await feathersClient.service('external-issues').patch(item.id, {
          StatusId: id,
        });
        if (this.$appConfig.externalIssues?.length > 0) {
          item.ExternalId != null
            ? await CreateIssue(item, 'patch')
            : await CreateIssue(item, 'post');
        }
      } catch (error) {
        handleErrorResponse(error);
      }
      // handleSaveResponse('', 'Test Scenario', 'assigned');
      await ExternalIssues.find({ query: { id: item?.id } });
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated',
    }),

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    statusParams() {
      return { query: { $sort: { Number: 1 } } };
    },
    externalIssuesParams() {
      return this.filter;
    },
    issueTypeParams() {
      return {};
    },
    issueTypeStatusParams() {
      return {};
    },
    issueTypeFieldsParams() {
      return {};
    },
    issueTypeStatusFull() {
      let fullStatus = [...this.issueTypeStatus];

      for (const st of fullStatus) {
        st.Status = this.status.find((f) => f.id == st.StatusId);
      }

      return fullStatus;
    },

    userList() {
      let users = [...this.users];
      users.push({ id: null, fullname: '----' });
      return users;
    },
    uniqueStatusId() {
      const uniqueStatusIDs = new Set();
      this.issueTypeStatus.forEach((issueStatus) => {
        uniqueStatusIDs.add(issueStatus.StatusId);
      });

      return Array.from(uniqueStatusIDs);
    },
    updateFields() {
      return this.issueTypeFields.filter(
        (f) =>
          (f.Update == 'No' && f.Create == 'No') ||
          f.Update == 'Both' ||
          f.Update == 'Loxi'
      );
    },
    filteredStatus() {
      return this.status.filter((f) => this.uniqueStatusId.includes(f.id));
    },
    departmentParams() {
      return { query: { $sort: { Name: 1 } } };
    },
    usersParams() {
      return { query: { Active: true, $sort: { fullname: 1 } } };
    },

    filter() {
      if (this.testScenarioRunStep) {
        return {
          query: {
            $or: [
              { TestScenarioRunStepId: this.testScenarioRunStep?.id },
              {
                ProcessStepId: this.testScenarioRunStep?.process_step?.id,
              },
            ],
          },
        };
      } else if (this.processStep) {
        return {
          query: {
            ProcessStepId: this.processStep?.id,
          },
        };
      } else if (this.variant) {
        return {
          query: {
            VariantId: this.variant?.id,
          },
        };
      } else if (this.process) {
        return {
          query: {
            ProcessId: this.process?.id,
          },
        };
      } else if (this.statusActive && this.statusActive.StatusId) {
        return {
          query: {
            StatusId: this.statusActive.StatusId,
          },
        };
      } else {
        return {
          // query: { StatusId: { $lt: this.showAll ? 99 : 6 } },
          query: {},
        };
      }
    },
    filteredIssues() {
      if (this.externalIssues) {
        let filtered = this.externalIssues;
        if (this.phase && this.phase != 'All') {
          filtered = filtered.filter(
            (f) =>
              (parseInt(this.phase) == f.process?.Phase
                ? f.process.Phase
                : null) || f.process == null
          );
        }

        if (this.$route.name == 'Issues') {
          if (this.selectionFilter.department) {
            filtered = filtered.filter(
              (c) =>
                c.process_step?.variant?.process?.DepartmentId ===
                  this.selectionFilter.department ||
                c.variant?.process?.DepartmentId ===
                  this.selectionFilter.department ||
                c.process?.DepartmentId === this.selectionFilter.department
            );
          }
          if (
            this.selectionFilter.status &&
            this.selectionFilter.status.length > 0
          ) {
            filtered = filtered.filter((f) => {
              return this.selectionFilter.status.includes(f.StatusId);
            });
          }
          if (
            this.selectionFilter.issueType &&
            this.selectionFilter.issueType.length > 0
          ) {
            filtered = filtered.filter((f) => {
              return this.selectionFilter.issueType.includes(f.IssueTypeId);
            });
          }
          if (this.selectionFilter.user) {
            filtered = filtered.filter((item) =>
              item.AssignedId
                ? item.AssignedId == this.selectionFilter.user
                : null
            );
          }
          if (this.selectionFilter.createdUser) {
            filtered = filtered.filter((item) =>
              item.CreatedById
                ? item.CreatedById == this.selectionFilter.createdUser
                : null
            );
          }
          if (this.selectionFilter.blocking != null) {
            filtered = filtered.filter(
              (item) => item.Blocking == this.selectionFilter.blocking
            );
          }
          if (this.selectionFilter.linked != null) {
            if (this.selectionFilter.linked) {
              filtered = filtered.filter((item) => item.ProcessId != null);
            } else {
              filtered = filtered.filter((item) => item.ProcessId == null);
            }
          }
          if (this.search)
            return filtered.filter(
              (item) =>
                item.Content1?.toLowerCase().includes(
                  this.search.toLowerCase()
                ) ||
                item.Content2?.toLowerCase().includes(
                  this.search.toLowerCase()
                ) ||
                item.Title?.toLowerCase().includes(this.search.toLowerCase()) ||
                item.ExternalId?.toLowerCase().includes(
                  this.search.toLowerCase()
                )
            );
          return filtered;
        } else {
          if (this.selectionFilter.blocking != null) {
            filtered = filtered.filter(
              (item) => item.Blocking == this.selectionFilter.blocking
            );
          }
          if (this.selectionFilter?.Closed) {
            filtered = filtered.filter((f) => f.Status?.Closed != true);
          }
          if (this.selectionFilter.user) {
            filtered = filtered.filter((item) =>
              item.AssignedId
                ? item.AssignedId == this.selectionFilter.user
                : null
            );
          }
          if (
            this.selectionFilter.status &&
            this.selectionFilter.status.length > 0
          ) {
            filtered = filtered.filter((f) => {
              return this.selectionFilter.status.includes(f.StatusId);
            });
          }
          if (this.search)
            return filtered.filter(
              (item) =>
                item.Content1?.toLowerCase().includes(
                  this.search.toLowerCase()
                ) ||
                item.Content2?.toLowerCase().includes(
                  this.search.toLowerCase()
                ) ||
                item.Title?.toLowerCase().includes(this.search.toLowerCase()) ||
                item.ExternalId?.toLowerCase().includes(
                  this.search.toLowerCase()
                )
            );
          return filtered;
        }
      } else return [];
    },
    headers() {
      let headers = [
        {
          text: 'Type',
          align: 'center',
          sortable: true,
          value: 'IssueType',
          cellClass: 'topalign',
        },
        {
          text: 'Link',
          align: 'start',
          sortable: false,
          value: 'Link',
          cellClass: 'topalign',
        },

        {
          text: 'Title',
          align: 'start',
          sortable: true,
          value: 'Title',

          cellClass: 'topalign',
        },

        {
          text: 'CreatedAt',
          align: 'start',
          sortable: true,
          value: 'createdAt',
          cellClass: 'topalign',
        },
        {
          text: 'Created',
          align: 'center',
          sortable: true,
          value: 'CreatedBy',
          cellClass: 'topalign',
        },
        {
          text: 'Assigned',
          align: 'center',
          sortable: true,
          value: 'Assigned',
          cellClass: 'topalign',
        },
        {
          text: 'Status',
          value: 'Status',
          align: 'center',
          sortable: true,
          groupable: false,
          cellClass: 'topalign',
        },
        {
          text: 'Blocking',
          value: 'Blocking',
          align: 'center',
          sortable: true,
          groupable: false,
          cellClass: 'topalign',
        },
        {
          text: 'Edit',
          value: 'edit',
          sortable: false,
          groupable: false,
          cellClass: 'topalign',
        },
        { text: '', value: 'data-table-expand', cellClass: 'topalign' },
      ];
      if (this.$appConfig.externalIssues) {
        headers.splice(2, 0, {
          text: this.$appConfig.externalIssues,
          align: 'start',
          sortable: false,
          value: 'Extern',
          cellClass: 'topalign',
        });
      }
      return headers;
    },
  },
  mounted() {
    if (window.localStorage.getItem('issueFilter') === null) {
      this.selectionFilter = {};
    } else {
      this.selectionFilter = JSON.parse(
        window.localStorage.getItem('issueFilter')
      );
      this.filterExists = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-tooltip {
  opacity: 1 !important;
  border: 1px solid #777777;
}
.clickable {
  cursor: pointer;
}
</style>
