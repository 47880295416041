<template>
  <Modal
    :title="
      itemtoPrint
        ? `Print Process '${itemtoPrint.Name}'`
        : 'Print All Processes'
    "
    :busy="generatingDocument"
    @cancel="showDialog = false"
    @confirm="startPrint(itemtoPrint)"
    ><v-select
      label="output"
      item-value="value"
      item-text="label"
      outlined
      dense
      hide-details
      v-model="selectedOptions.Output"
      :items="outputOptions"
      @change="changeOption"
    >
    </v-select>
    <v-switch
      v-model="selectedOptions.includeSpecifications"
      label="Include Specifications"
      color="primary"
      dense
      hide-details
      disabled
      @change="changeOption"
    ></v-switch>
    <v-switch
      v-model="selectedOptions.includeApplicationForms"
      label="Include Application Forms"
      color="primary"
      dense
      hide-details
      disabled
      @change="changeOption"
    ></v-switch>
    <v-switch
      v-model="selectedOptions.includeApplications"
      label="Include Applications"
      color="primary"
      dense
      hide-details
      disabled
      @change="changeOption"
    ></v-switch>
    <v-switch
      v-model="selectedOptions.includeStatus"
      label="Include Status"
      color="primary"
      dense
      hide-details
      disabled
      @change="changeOption"
    ></v-switch>
    <v-switch
      v-model="selectedOptions.includeSites"
      label="Include Sites"
      color="primary"
      dense
      hide-details
      disabled
      @change="changeOption"
    ></v-switch>
    <v-switch
      v-model="selectedOptions.includeRoles"
      label="Include Roles"
      color="primary"
      dense
      hide-details
      disabled
      @change="changeOption"
    ></v-switch>
  </Modal>
</template>

<script>
import Modal from './Modal.vue';
import {
  printingProcess,
  printingEnd2End,
  printingTestScenarioHeader,
} from '@/utils/DocExport';
export default {
  props: {
    itemtoPrint: {
      type: Object,
      required: false,
    },
    printType: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      outputOptions: [
        { label: 'PDF', value: 'pdf' },
        { label: 'Word', value: 'docx' },
      ],
      generatingDocument: false,
      selectedOptions: {
        Output: 'pdf',
        includeSpecifications: true,
        includeApplicationForms: true,
        includeApplications: true,
        includeStatus: true,
        includeSites: true,
        includeRoles: true,
      },
    };
  },
  methods: {
    async startPrint(item) {
      this.generatingDocument = true;
      if (this.printType == 'Process') {
        await printingProcess(item, this.selectedOptions);
      } else if (this.printType == 'End2End') {
        await printingEnd2End(item, this.selectedOptions);
      } else if (this.printType == 'TestScenario') {
        await printingTestScenarioHeader(item, this.selectedOptions);
      }
      this.generatingDocument = false;
      this.showDialog = false;
    },
    changeOption() {
      window.localStorage.setItem(
        'printOptions',
        JSON.stringify(this.selectedOptions)
      );
    },
  },

  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  mounted() {
    if (window.localStorage.getItem('printOptions') === null) {
      this.selectedOptions = {
        Output: 'pdf',
        includeSpecifications: true,
        includeApplicationForms: true,
        includeApplications: true,
        includeStatus: true,
        includeSites: true,
        includeRoles: true,
      };
    } else {
      this.selectedOptions = JSON.parse(
        window.localStorage.getItem('printOptions')
      );
    }
  },
};
</script>

<style lang="scss" scoped></style>
