<template>
  <div>
    E2E process list

    <v-data-table
      :headers="headers"
      :items="processes"
      item-key="id"
      :sort-by="['end2End_process.Order']"
      hide-default-footer
      disable-pagination
      class="elevation-1"
    >
      <template v-slot:[`item.Variants`]="{ item }">
        <v-select
          :items="variant.filter((f) => f.ProcessId == item.id)"
          item-text="Name"
          item-value="id"
          outlined
          dense
          selectable
          hide-details=""
          v-model="selectedVariant"
          return-object
          clearable
          multiple
          small-chips
        >
          <template v-slot:item="{ item }">
            <v-icon
              :color="item.GroupId ? item.group?.Color : 'white'"
              small
              class="mr-2"
            >
              mdi-circle
            </v-icon>
            {{ item.Name }}
          </template>
        </v-select>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
export default {
  props: {
    processes: {
      type: Array,
      required: true,
    },
  },
  mixins: [
    makeFindMixin({
      service: 'variant',
    }),
  ],
  data() {
    return {
      selectedVariant: null,
      headers: [
        {
          text: 'Number',
          align: 'start',
          sortable: false,
          value: 'Number',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'Name',
        },
        {
          text: 'Variants',
          align: 'start',
          sortable: false,
          value: 'Variants',
        },
      ],
    };
  },
  computed: {
    variantParams() {
      {
        return {};
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
