<template>
  <v-container>
    <h3>TestBuilderWizard</h3>
    <h4>Select End 2 End</h4>
    <v-select
      :items="end2End"
      item-text="Name"
      item-value="id"
      outlined
      dense
      hide-details=""
      v-model="selectedEnd2End"
      return-object
    ></v-select>

    <E2EProcessList
      v-if="selectedEnd2End?.processes"
      :processes="selectedEnd2End.processes"
    />
  </v-container>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import E2EProcessList from '@/components/TestScenarios/Wizard.vue/E2EProcessList.vue';
export default {
  mixins: [
    makeFindMixin({
      service: 'end-2-end',
    }),
  ],
  components: { E2EProcessList },
  computed: {
    end2EndParams() {
      return { query: { $sort: { Number: 1 } } };
    },
  },
  data() {
    return {
      selectedEnd2End: null,
    };
  },
};
</script>

<style lang="scss" scoped></style>
