<template>
  <div class="mx-5" v-if="testScenarioLine">
    <h2>{{ testScenarioLine.Order }}. {{ testScenarioLine.Name }}</h2>
    <ApplicationList :variant="testScenarioLine.variant" />
    <span v-html="testScenarioLine.Description"></span>
    <div class="mb-4" v-for="step of testScenarioLineStep" :key="step.id">
      <h3>{{ step.Number }}. {{ step.process_step.Name }}</h3>
      <v-alert class="text-caption" dense color="secondary">
        <b>Test Remark</b>: <span v-html="step.TestRemark"></span>
        <TestLineFieldBrowser :testScenarioLineStep="step" />
      </v-alert>

      <span v-html="step.process_step.Description"></span>
      <hr class="my-5" />
    </div>
  </div>
</template>

<script>
import { makeFindMixin, makeGetMixin } from 'feathers-vuex';
import TestLineFieldBrowser from './TestLineFieldBrowser.vue';
import ApplicationList from '@/components//Variant/ApplicationList.vue';

export default {
  mixins: [
    makeGetMixin({
      service: 'test-scenario-line',
      id: 'testScenarioLineId',
      watch: 'testScenarioLineId',
    }),
    makeFindMixin({
      service: 'test-scenario-line-step',
      watch: 'testScenarioLineId',
    }),
  ],
  components: {
    TestLineFieldBrowser,
    ApplicationList,
  },
  computed: {
    testScenarioLineId() {
      return this.$store.state.activeProcessNode.TestScenarioLineId;
    },
    testScenarioLineStepParams() {
      return {
        query: {
          TestScenarioLineId: this.testScenarioLineId,
          $sort: { Number: 1 },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
