<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-card class="mt-5">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer> </v-spacer>
            <v-btn
              v-if="$can('create', 'field')"
              elevation="0"
              class="mx-2"
              fab
              small
              color="primary"
              @click="clickAdd()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="filteredHeaders"
            :items="field"
            :search="search"
            :sort-by="['TestRelevant', 'Name']"
            :sort-desc="[true, false]"
            class="text-pre-wrap"
            disable-pagination
            hide-default-footer
            :loading="isFindFieldPending"
            loading-text="Loading... Please wait"
            show-expand
          >
            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length">
                <FieldValueList :fieldId="item.id" />
              </td>
            </template>
            <template v-slot:[`item.TestRelevant`]="{ item }">
              <v-icon
                v-if="item.TestRelevant"
                small
                class="mr-2"
                color="primary"
              >
                mdi-check-bold
              </v-icon>
            </template>
            <template
              v-if="$can('update', 'field')"
              v-slot:[`item.edit`]="{ item }"
            >
              <v-icon
                small
                class="mr-2"
                @click="clickEdit(item)"
                color="primary"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <FieldEdit
      v-if="detailDialog"
      v-model="detailDialog"
      :currentItem="currentItem"
    />
  </v-container>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import FieldEdit from '@/components/Fields/FieldEdit';
import FieldValueList from '@/components/FieldValues/FieldValueList';

export default {
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Edit',
          value: 'edit',
          sortable: false,
          groupable: false,
        },
        { text: 'Nr', value: 'Number', groupable: false },
        { text: 'Name', value: 'Name', groupable: false },
        { text: 'Description', value: 'Description', groupable: false },
        { text: 'Test Relevant', value: 'TestRelevant', groupable: false },

        { text: '', value: 'data-table-expand' },
      ],
      detailDialog: false,
      currentItem: [],
    };
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter((item) => {
        return this.$can('update', 'field') ? true : item.text != 'Edit';
      });
    },
    fieldParams() {
      return {};
    },
  },
  mixins: [makeFindMixin({ service: 'field' })],
  components: {
    FieldEdit,
    FieldValueList,
  },
  methods: {
    clickEdit(item) {
      this.currentItem = item;
      this.detailDialog = true;
    },
    clickAdd() {
      this.currentItem = null; //{};
      this.detailDialog = true;
    },
    clickDetail(clickedProcess) {
      this.setCurrentProcess(clickedProcess);
      this.$router.push({
        name: 'Process',
        params: { id: clickedProcess.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
